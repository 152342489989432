import React, { FC } from 'react';
import { OnVisibilityTrigger } from 'src/common/infinityScroll';
import { Button } from 'src/common';
import { useTranslation } from 'react-i18next';
import { ButtonType } from 'src/common/Button';
import { useIsMobile } from 'src/reactiveVars';

declare const LoadEvents: ['click', 'hover', 'scroll'];
export declare type ILoadEvent = (typeof LoadEvents)[number];
interface IProps {
  onLoadMore: () => Promise<unknown>;
  isLoading: boolean;
  hasMore: boolean;
  scrollContainer: HTMLElement | null;
  type?: ButtonType;
  event?: ILoadEvent;
}
export const LoadMoreButton: FC<IProps> = ({
  onLoadMore,
  isLoading,
  hasMore,
  scrollContainer,
  type = 'default',
  event = 'scroll'
}) => {
  const { t } = useTranslation();
  const [isMobile] = useIsMobile();

  if (!hasMore || (!scrollContainer && event === 'scroll')) {
    return null;
  }

  return event === 'scroll' && !isMobile ? (
    <OnVisibilityTrigger
      style={{ display: 'flex', justifyContent: 'center' }}
      isLoading={isLoading}
      onVisible={onLoadMore}
      container={scrollContainer}
    >
      <Button loading={isLoading} type={type} onClick={onLoadMore}>
        {t(isLoading ? 'Common.loadingMore' : 'Common.loadMore')}
      </Button>
    </OnVisibilityTrigger>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        loading={isLoading}
        type={type}
        onClick={onLoadMore}
        onMouseOver={event === 'hover' ? onLoadMore : undefined}
      >
        {t(isLoading ? 'Common.loadingMore' : 'Common.loadMore')}
      </Button>
    </div>
  );
};

export default LoadMoreButton;
