import React, { FC } from 'react';
import FullscreenOverlay from 'src/common/FullscreenOverlay';
import UserGroups from '../UserGroups';
import Assignee from 'src/shared/Assignee';
import styles from '../index.module.scss';
import classNames from 'classnames';
import { AssignedTo } from 'src/models/users/types';
import Tooltip from 'src/common/Tooltip';
import UserIcon from 'src/common/UserIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dropDownOverlayStyles from 'src/shared/DropDownWithSearch/index.module.scss';
import UserButton from '../UserButton';
import { faQuestion, faTimes } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

interface Props {
  isMenuVisible: boolean;
  toggleMenu: () => void;
  onSelect: (user: AssignedTo, checked?: boolean) => void;
  users: AssignedTo[];
  label: string;
  usersLoading?: boolean;
  onUnSelect?: () => void;
  size?: (typeof Sizes)[number];
  tooltip?: string;
  isUpdating?: boolean;
  mode?: 'full' | 'short';
  disabled?: boolean;
  className?: string;
  defaultPopupContainer?: boolean;
  overlayClassName?: string;
  selectedUser?: AssignedTo | null;
}

export const UserPickerMobile: FC<Props> = ({
  toggleMenu,
  className,
  disabled,
  selectedUser,
  size = 'm',
  mode = 'short',
  isUpdating,
  tooltip,
  label,
  isMenuVisible,
  users,
  onSelect,
  onUnSelect
}) => {
  const { t } = useTranslation();

  return (
    <>
      <button
        className={classNames(styles.button, styles.assignButton, styles.mobile, className, {
          [styles.isDisabled]: disabled
        })}
        onClick={toggleMenu}
      >
        {selectedUser ? (
          <Assignee
            assignedToData={selectedUser}
            size={size}
            loading={isUpdating}
            {...(mode === 'short'
              ? {
                  mode: 'onlyAvatar',
                  className: styles.avatar,
                  showTooltip: true
                }
              : {})}
          />
        ) : (
          <Tooltip title={tooltip ?? label}>
            <UserIcon isActive={isMenuVisible} size={size} loading={isUpdating} />
            {mode === 'full' && <span className={styles.assignButtonLabel}>{label}</span>}
          </Tooltip>
        )}
      </button>
      <FullscreenOverlay
        visible={isMenuVisible}
        onVisibleChange={toggleMenu}
        overlayContent={
          <>
            {selectedUser && !!onUnSelect && (
              <div className={styles.currentUser}>
                <Assignee assignedToData={selectedUser} className={styles.currentUserName} size={'s'} />
                <button className={classNames(styles.button, styles.unAssignButton)} onClick={onUnSelect}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            )}
            {!!users.length ? (
              <UserGroups users={users} wrapper={user => <UserButton key={user.id} user={user} onClick={onSelect} />} />
            ) : (
              <div className={dropDownOverlayStyles.searchEmpty}>
                <span className={dropDownOverlayStyles.searchEmptyPic}>
                  <FontAwesomeIcon icon={faQuestion} className={dropDownOverlayStyles.searchEmptyIcon} />
                </span>
                <span className={dropDownOverlayStyles.searchEmptyLabel}>{t('Common.noMatchesFound')}</span>
              </div>
            )}
          </>
        }
        overlayTitle={tooltip}
        overlayContentClassname={styles.userPickerOverlayContainer}
      />
    </>
  );
};
