import React, { FC, MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare } from '@fortawesome/pro-light-svg-icons';
import { faMinusSquare as faMinusSquareSolid } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import styles from './index.module.scss';
import { useIsMobile } from 'src/reactiveVars';

interface IProps {
  filteredIds: string[];
  onReject: () => Promise<void>;
  mode?: 'full' | 'medium' | 'short';
  isRejected?: boolean;
  disabled?: boolean;
}

export const RejectButton: FC<IProps> = ({ mode = 'short', ...restProps }) => {
  const { filteredIds, onReject: reject, isRejected, disabled } = restProps;
  const { t } = useTranslation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isMobile] = useIsMobile();
  const onReject = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!!filteredIds.length) {
        setIsButtonDisabled(true);
        await reject();
        setIsButtonDisabled(false);
      }
    },
    [filteredIds.length, reject]
  );

  return (
    <button
      onClick={onReject}
      disabled={isButtonDisabled || disabled}
      className={classNames(styles.rejectButton, {
        [styles.isDisabled]: !filteredIds.length || isButtonDisabled || disabled,
        [styles.isRejected]: isRejected,
        [styles.isFullModeButton]: mode === 'full',
        [styles.isMediumModeButton]: mode === 'medium',
        [styles.mobile]: isMobile
      })}
    >
      {mode === 'full' || mode === 'medium' ? (
        <>
          <FontAwesomeIcon
            icon={faMinusSquareSolid}
            className={classNames(styles.rejectIcon, {
              [styles.mobile]: isMobile
            })}
          />
          <span className={styles.buttonLabel}>{t(isRejected ? 'Common.unremove' : 'Common.remove')}</span>
        </>
      ) : (
        <FontAwesomeIcon icon={faMinusSquare} className={styles.rejectIcon} />
      )}
    </button>
  );
};
export default RejectButton;
