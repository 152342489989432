import React, { FC } from 'react';
import styles from '../index.module.scss';
import NotificationMenu from '../NotificationMenu';
import FaqMenu from '../FaqMenu';
import UserMenu from '../UserMenu';
import { FeatureFlag, useFeatureFlag, PRODUCT_VOICE_BUTTON_ID } from 'src/helpers';
import HeaderAction from '../HeaderAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/pro-solid-svg-icons';
import { useMe } from 'src/common';
import { Ownerships, ownership } from 'src/models/ownership';
import { useIsMobile } from 'src/reactiveVars';
import classNames from 'classnames';

export const HeaderActions: FC = () => {
  const isKnowledgeBankFeature = useFeatureFlag(FeatureFlag.KnowledgeBank);
  const isProductVoiceFeature = useFeatureFlag(FeatureFlag.ProductVoice);
  const { data: user } = useMe();
  const [isMobile] = useIsMobile();

  return (
    <div
      className={classNames(styles.actions, {
        [styles.mobileActions]: isMobile
      })}
    >
      {user && ownership.name !== Ownerships.Clira && !isMobile && (
        <div className={styles.action}>
          <NotificationMenu />
        </div>
      )}
      {isKnowledgeBankFeature && !isMobile && (
        <div className={styles.action}>
          <FaqMenu />
        </div>
      )}
      {isProductVoiceFeature && user?.email && !isMobile && (
        <div className={styles.action}>
          <HeaderAction>
            <FontAwesomeIcon icon={faLightbulb} id={PRODUCT_VOICE_BUTTON_ID} />
          </HeaderAction>
        </div>
      )}
      <div
        className={classNames(styles.action, {
          [styles.mobileAction]: isMobile
        })}
      >
        <UserMenu />
      </div>
    </div>
  );
};

export default HeaderActions;
