import React, { FC, PropsWithChildren, Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import i18nService from './lib/I18nService';
import { useTranslation } from 'react-i18next';
import { DndProvider as DndProviderBase, DndProviderProps } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Paths } from 'src/pages/paths';
import { BrokenMobilePagesBanner, notification, Skeleton } from 'src/common';
import './styles/App.scss';
import { usePageWatcher } from 'src/segment';
import ScrollToTop from 'src/common/ScrollToTop';
import { useInitHook } from './hooks';
import { useNewCommentSubscriptions } from './models/comments/hooks';
import { FeatureFlag, useFeatureFlag, useProductVoice, useSupportWidget, useSyncUsersLanguage } from './helpers';
import { useClientVersionUpdate } from './reactiveVars';
import useRumPageWatcher from './rum/usePageWatcher';
import { useApp, UserType } from './models/auth';
import { useExportWorkspacesSubscription } from './models/workspace/hooks';
import styles from './index.module.scss';
import { AuthLayout, BasicSkeleton, MainLayout, MainSkeleton, PageSkeleton, PrivateRoutes } from './layouts';
import { TenderRedirect, OverviewRedirect } from './redirects';
import HomePage from './pages/HomePage';
import SignInPage from './pages/AuthPage/SignIn';
import MonitoringProfilesNavPage from './pages/MonitoringProfilesNavPage';
import CallOffsNavPage from './pages/CallOffsNavPage';
import BidspacesNavPage from './pages/BidspacesNavPage';
import MaintenancePage from './pages/MaintenancePage';
import { useImportFromCsvSubscription, useUploadDocumentSub } from './models/contentLibrary/ContentLibrary/hooks';

const DndProviderComponent = DndProviderBase;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DndProvider: FC<PropsWithChildren & DndProviderProps<any, any>> = ({ children, ...rest }) => {
  return <DndProviderComponent {...rest}>{children}</DndProviderComponent>;
};

const SignInSSOPage = lazy(async () => await import('src/pages/AuthPage/SignInSSO'));
const SignUpPage = lazy(async () => await import('src/pages/AuthPage/SignUp'));
const ConfirmSignUpPage = lazy(async () => await import('src/pages/AuthPage/ConfirmSignUp'));
const ForgotPasswordPage = lazy(async () => await import('src/pages/AuthPage/ForgotPassword'));
const ResetPasswordPage = lazy(async () => await import('src/pages/AuthPage/ResetPassword'));
const ChangePasswordPage = lazy(async () => await import('src/pages/AuthPage/ChangePassword'));
const Confirm2FAPage = lazy(async () => await import('src/pages/AuthPage/Confirm2FA'));
const SettingsPage = lazy(async () => await import('src/pages/SettingsPage'));
const AdminPage = lazy(async () => await import('src/pages/AdminPage'));
const BidsPage = lazy(async () => await import('./pages/BidsPage'));
const ArchivedWorkspaces = lazy(async () => await import('./pages/BidsPage/ArchivedWorkspaces'));
const CreateWorkspace = lazy(async () => await import('./pages/BidsPage/CreateWorkspace'));
const CallOffsPage = lazy(async () => await import('./pages/CallOffsPage'));
const TendersStarred = lazy(async () => await import('src/pages/TendersPage/TendersStarred'));
const TendersAll = lazy(async () => await import('src/pages/TendersPage/TendersAll'));
const TendersScreening = lazy(async () => await import('src/pages/TendersPage/TendersScreening'));
const TenderRouts = lazy(async () => await import('src/pages/TenderRouts'));
const AnalyticsRouts = lazy(async () => await import('src/pages/AnalyticsPage'));
const BiddingGPTPage = lazy(async () => await import('src/pages/BiddingGPTPage'));
const TaskPage = lazy(async () => await import('src/pages/TaskPage'));
const BidDraftPage = lazy(async () => await import('src/pages/BidDraftPage'));
const ContentLibraryRoomPage = lazy(async () => await import('src/pages/ContentLibraryRoomPage'));
const ContentLibraryPage = lazy(async () => await import('src/pages/ContentLibraryPage'));
const DocumentsLibraryPage = lazy(async () => await import('src/pages/DocsLibraryPage'));
const NotificationsPage = lazy(async () => await import('src/pages/NotificationsPage'));
const BidReportPage = lazy(async () => await import('src/pages/BidReportPage'));

const App: FC = () => {
  const [externalTranslationsLoaded, setExternalTranslationsLoaded] = useState(false);
  const isReleaseUpdate = useFeatureFlag(FeatureFlag.CustomerPortal_Release);
  const { loading, permission, user } = useApp();
  const isPaid = useMemo(() => !!permission && permission === UserType.Paid, [permission]);

  useNewCommentSubscriptions(!isPaid);
  useSyncUsersLanguage();

  const { t } = useTranslation();

  useSupportWidget();
  useInitHook();

  useEffect(() => {
    // Since we are using same translation data for all languages,
    // no need to load on every language change (t function change)
    if (externalTranslationsLoaded) return;

    i18nService
      .loadExternalResources()
      .catch(err => {
        notification.warn({
          description: err.message || String(err),
          message: t('Common.unknownError')
        });
      })
      .finally(() => {
        setExternalTranslationsLoaded(true);
      });
  }, [externalTranslationsLoaded, t]);
  /* FIXME: split up hooks for analytic handling, for alerts handling, for partners handling
  move out to one init hook together with useSupportWidget, useInitHook. useLogUsersLanguage */
  usePageWatcher();
  useRumPageWatcher();
  useProductVoice();
  useUploadDocumentSub(!user);
  useImportFromCsvSubscription(!user);

  useExportWorkspacesSubscription(!user);

  const [clientVersionUpdate] = useClientVersionUpdate();

  const bannerClassName = useMemo(() => {
    if (clientVersionUpdate) {
      return 'hasBanner';
    }
    return '';
  }, [clientVersionUpdate]);

  return (
    <div className={bannerClassName}>
      <DndProvider backend={HTML5Backend}>
        {isReleaseUpdate ? (
          <Routes>
            <Route
              path={`${Paths.ADMIN_PAGE}*`}
              element={
                <Suspense fallback={<BasicSkeleton />}>
                  <AdminPage />
                </Suspense>
              }
            />
            <Route path={Paths.AUTH}>
              <Route element={<AuthLayout />}>
                <Route path={Paths.SIGN_IN} element={<SignInPage />} />
                <Route path={Paths.SIGN_IN_SSO} element={<SignInSSOPage />} />
                <Route path={Paths.CONFIRM_2FA} element={<Confirm2FAPage />} />
                <Route path={Paths.AUTH} element={<Navigate to={Paths.SIGN_IN} replace />} />
              </Route>
            </Route>
            <Route path={'*'} element={<MaintenancePage />} />
          </Routes>
        ) : (
          <>
            {!externalTranslationsLoaded && (
              <Skeleton className={styles.App} active={loading || externalTranslationsLoaded} />
            )}
            {externalTranslationsLoaded && !loading && (
              <ScrollToTop>
                <Routes>
                  <Route element={<PrivateRoutes />}>
                    <Route element={<MainLayout />}>
                      <Route path={Paths.MONITORING} element={<MonitoringProfilesNavPage />} />
                      <Route path={Paths.MONITORING_PAGE} element={<TendersScreening />} />
                      <Route path={Paths.ALL_TENDERS} element={<TendersAll />} />
                      <Route path={Paths.STARRED_TENDERS} element={<TendersStarred />} />
                      <Route path={Paths.CALL_OFFS} element={<CallOffsNavPage />} />
                      <Route path={Paths.INBOX_PAGE} element={<CallOffsPage />} />
                      <Route path={Paths.BIDSPACES} element={<BidspacesNavPage />} />
                      <Route path={Paths.BIDSPACE_PAGE} element={<BidsPage />} />
                      <Route path={Paths.BIDSPACES_CREATE} element={<CreateWorkspace />} />
                      <Route path={Paths.BIDSPACES_ARCHIVED} element={<ArchivedWorkspaces />} />
                      <Route path={Paths.DOCS_LIB} element={<DocumentsLibraryPage />} />
                      <Route path={Paths.NOTIFICATIONS_PAGE} element={<NotificationsPage />} />
                      <Route path={Paths.BIDDING_GPT} element={<BiddingGPTPage />} />
                      <Route path={`${Paths.ANALYTICS_PAGE}*`} element={<AnalyticsRouts />} />
                      <Route path={Paths.CONTENT_LIBRARY} element={<ContentLibraryPage />} />
                      <Route path={Paths.CONTENT_LIBRARY_ROOM} element={<ContentLibraryRoomPage />} />
                      <Route path={`${Paths.PROCUREMENT_DETAILS_PAGE}*`} element={<TenderRedirect />} />
                      <Route path={`${Paths.PROCUREMENT_TABLE_PAGE}/*`} element={<OverviewRedirect />} />
                    </Route>
                    <Route
                      path={`${Paths.ADMIN_PAGE}*`}
                      element={
                        <Suspense fallback={<BasicSkeleton />}>
                          <>
                            <BrokenMobilePagesBanner useTopMargin />
                            <AdminPage />
                          </>
                        </Suspense>
                      }
                    />
                    <Route
                      path={`${Paths.TASK_ROUTE}*`}
                      element={
                        <Suspense fallback={<PageSkeleton />}>
                          <>
                            <BrokenMobilePagesBanner useTopMargin />
                            <TaskPage />
                          </>
                        </Suspense>
                      }
                    />
                    <Route
                      path={`${Paths.BIDDING_DRAFT_PAGE}`}
                      element={
                        <Suspense fallback={<PageSkeleton />}>
                          <>
                            <BrokenMobilePagesBanner useTopMargin />
                            <BidDraftPage />
                          </>
                        </Suspense>
                      }
                    />
                  </Route>

                  <Route element={<PrivateRoutes permissions={[UserType.Free]} />}>
                    <Route path={Paths.ROOT} element={<HomePage />} />
                    <Route
                      path={`${Paths.TENDER_ROUTE}*`}
                      element={
                        <Suspense fallback={<PageSkeleton />}>
                          <>
                            <BrokenMobilePagesBanner useTopMargin />
                            <TenderRouts />
                          </>
                        </Suspense>
                      }
                    />
                    <Route
                      path={Paths.BID_REPORT_PAGE}
                      element={
                        <Suspense fallback={<PageSkeleton />}>
                          <>
                            <BrokenMobilePagesBanner useTopMargin />
                            <BidReportPage />
                          </>
                        </Suspense>
                      }
                    />
                    <Route
                      path={`${Paths.SETTINGS_PAGE}*`}
                      element={
                        <Suspense fallback={<MainSkeleton />}>
                          <>
                            <BrokenMobilePagesBanner useTopMargin />
                            <SettingsPage />
                          </>
                        </Suspense>
                      }
                    />
                    <Route path={'*'} element={<Navigate to={Paths.ROOT} replace />} />
                  </Route>
                  <Route path={Paths.AUTH}>
                    <Route element={<AuthLayout />}>
                      <Route path={Paths.SIGN_IN} element={<SignInPage />} />
                      <Route path={Paths.SIGN_IN_SSO} element={<SignInSSOPage />} />
                      <Route path={Paths.CONFIRM_2FA} element={<Confirm2FAPage />} />
                      <Route path={Paths.SIGN_UP} element={<SignUpPage />} />
                      <Route path={Paths.CONFIRM_SIGN_UP} element={<ConfirmSignUpPage />} />
                      <Route path={Paths.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
                      <Route path={Paths.RESET_PASSWORD} element={<ResetPasswordPage />} />
                      <Route path={Paths.CHANGE_PASSWORD} element={<ChangePasswordPage />} />
                      <Route path={Paths.AUTH} element={<Navigate to={Paths.SIGN_IN} replace />} />
                    </Route>
                  </Route>
                </Routes>
              </ScrollToTop>
            )}
          </>
        )}
      </DndProvider>
    </div>
  );
};

export default App;
