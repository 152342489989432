import React, { FC, ReactElement, ReactNode, useCallback, useState } from 'react';
import { Skeleton, Select } from 'src/common';
import { OnVisibilityTrigger, useFetchMore } from 'src/common/infinityScroll';
import { useGetOrgs } from 'src/models/organisations';
import styles from './index.module.scss';
import { SelectProps } from 'antd';
import { CustomTagProps } from 'rc-select/es/BaseSelect';

export interface LabeledValue {
  key: string;
  value: string;
  label: ReactNode;
}

interface Props extends SelectProps {
  className?: string;
  isBulkMode?: boolean;
  freeTextSearch?: JSX.Element | null;
  specificBuyers?: JSX.Element | null;
  debouncedSearchPhrase: string;
  isMore?: boolean;
  optionClassName?: string;
  renderTag?: (props: Omit<CustomTagProps, 'isMaxTag'>) => React.ReactElement;
  searchPhrase?: string;
  selectOption: React.JSX.Element[];
  selectedValue?: LabeledValue;
  dropdownClassName?: string;
}

export const OrganisationSelector: FC<Props> = ({
  className,
  isBulkMode,
  disabled,
  placeholder,
  freeTextSearch,
  specificBuyers,
  debouncedSearchPhrase,
  isMore,
  selectOption,
  optionClassName,
  renderTag,
  searchPhrase,
  dropdownClassName,
  ...restProps
}) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  const { data: orgs, loading, fetchMore, fetchingMore } = useGetOrgs(debouncedSearchPhrase);

  const loadMore = useCallback(
    (variables: { resultOffset: number }) =>
      fetchMore({
        variables
      }),
    [fetchMore]
  );

  const onLoadMore = useFetchMore({ resultOffset: orgs.length }, loadMore);
  const renderDropdown = useCallback(
    (menu: ReactElement) => (
      <div className={styles.dropdown} ref={setContainer}>
        {menu}
      </div>
    ),
    []
  );

  return (
    <Select
      className={className}
      disabled={disabled}
      placeholder={placeholder}
      tagRender={renderTag}
      loading={loading}
      searchValue={searchPhrase}
      getPopupContainer={trigger => trigger.parentNode}
      notFoundContent={loading ? <Skeleton loading active /> : null}
      dropdownRender={renderDropdown}
      dropdownClassName={dropdownClassName}
      {...restProps}
    >
      {!isBulkMode ? (
        <>
          {freeTextSearch}
          {specificBuyers}
          {selectOption}
          {isMore && (
            <Select.Option value={''} className={optionClassName}>
              <OnVisibilityTrigger
                isLoading={fetchingMore}
                onVisible={onLoadMore}
                container={container}
                style={{ display: 'block' }}
              >
                <Skeleton active loading paragraph />
              </OnVisibilityTrigger>
            </Select.Option>
          )}
        </>
      ) : null}
    </Select>
  );
};

export default OrganisationSelector;
