import { AnalyticsFeatureFlags, FeatureFlag, UserOwnership } from '@tendium/prom-types';
import { useMemo } from 'react';
import { useApp, isPaidUser } from 'src/models/auth';
import { ownership } from 'src/models/ownership';

export { FeatureFlag } from '@tendium/prom-types';

export function useFeatureFlag(featureName: FeatureFlag): boolean | null {
  const { user } = useApp();
  const myFeatures = isPaidUser(user) ? user.featureFlags : null;
  return useMemo(() => !!myFeatures && myFeatures.includes(featureName), [featureName, myFeatures]);
}

export function useMyFeaturesAndOwnership(): { myFeatureFlags: string[]; myOwnership: UserOwnership } {
  const { user } = useApp();

  const myFeatureFlags = useMemo(() => (isPaidUser(user) ? user.featureFlags : []), [user]);
  const myOwnership = ownership.name;

  return useMemo(() => ({ myFeatureFlags, myOwnership }), [myFeatureFlags, myOwnership]);
}

export function useAnalyticsFeatureFlags(featureName: AnalyticsFeatureFlags): boolean | null {
  const hasFeatureAccess = useFeatureFlag(featureName);
  const hasDemoAccess = useFeatureFlag(FeatureFlag.Analytics_Pages_Demo);

  return hasFeatureAccess || hasDemoAccess;
}
