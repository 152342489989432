import React, { FC, useMemo } from 'react';
import styles from '../index.module.scss';
import { Tooltip, NavSidebarLink, NavMenu, NavMenuTitle, NavMenuLinks, Tag, useLanguage } from 'src/common';
import WorkspacesList from '../WorkspacesList';
import { Paths } from 'src/pages/paths';
import {
  faSparkles,
  faThList,
  faStar,
  faArchive,
  faEnvelope,
  faBuilding,
  faCalendarClock,
  faCommentsQuestionCheck,
  faBooks,
  faSquareKanban,
  faMagnifyingGlassChart,
  faParachuteBox
} from '@fortawesome/pro-light-svg-icons';
import {
  faSparkles as faSparklesSolid,
  faThList as faThListSolid,
  faStar as faStarSolid,
  faArchive as faArchiveSolid,
  faEnvelope as faEnvelopeSolid,
  faBuilding as faBuildingSolid,
  faCalendarClock as faCalendarClockSolid,
  faCommentsQuestionCheck as faCommentsQuestionCheckSolid,
  faBooks as faBooksSolid,
  faSquareKanban as faSquareKanbanSolid,
  faMagnifyingGlassChart as faMagnifyingGlassChartSolid,
  faParachuteBox as faParachuteBoxSolid
} from '@fortawesome/pro-solid-svg-icons';
import InboxesList from '../InboxesList';
import { TagType } from 'src/common/Tag';
import { Language } from '@tendium/prom-types';
import MpsList from '../MpsList';
import Accordion from 'src/common/Accordion';
import { PageName, PageView } from 'src/models/users/Preferences/types';
import ContentLibraryRoomList from '../ContentLibraryRoomList';
import SavedSearchList from '../SavedSearchList';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useNavigationState } from 'src/helpers';
import { useTendersNavViewState } from 'src/models/users/Preferences/hooks';
import { useNavSidebar } from 'src/shared/NavSidebar';
import { useIsMobile } from 'src/reactiveVars';
import useHandleMobileSidebarState from 'src/helpers/mobile/useHandleMobileSidebar';
import { useAnalyticsFeatureAccess, useProjectsAndBidsFeatureAccess } from 'src/helpers/productFeatures';
import { useMonitoringFeatureAccess } from 'src/helpers/productFeatures';

const TendersNavContent: FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { language } = useLanguage();
  const fromPath = useNavigationState();
  const [sidebarIsOpen] = useNavSidebar();
  const [isMobile] = useIsMobile();
  const { mobileSidebarState, toggleMobileSidebarVisible } = useHandleMobileSidebarState();
  const [monitoringNavView, projectBidNavView, analyticsNavView, setTenderNavView] = useTendersNavViewState();

  const isOpen = useMemo(
    () => (isMobile ? mobileSidebarState : sidebarIsOpen),
    [isMobile, sidebarIsOpen, mobileSidebarState]
  );

  const onToggle = (page: PageName, view: PageView): void => {
    if (view === PageView.expanded) {
      setTenderNavView(page, PageView.collapsed);
    } else if (view === PageView.collapsed) {
      setTenderNavView(page, PageView.expanded);
    }
  };

  const closeMobileSidebar = (): void => {
    toggleMobileSidebarVisible(false);
  };

  const {
    isMonitoringProfilesFeature,
    isCallOffFeature,
    isAllTendersFeature,
    isStarredTendersFeature,
    isMonitoringAvailable
  } = useMonitoringFeatureAccess();
  const {
    isBidspacesFeature,
    isBiddingGPTContentLibrary,
    isDocLibFeature,
    isBiddingGPTCSVFeature,
    isProjectsAndBidsAvailable
  } = useProjectsAndBidsFeatureAccess();
  const {
    isBuyerPageAvailable,
    isSupplierPageAvailable,
    isMarketDashboardPageAvailable,
    isExpiringContractsPageAvailable,
    isAnalyticsExpiringContractsLight,
    isAnalyticsPagesDemo,
    isAnalyticsAvailable
  } = useAnalyticsFeatureAccess();

  return (
    <>
      {isMonitoringAvailable && (
        <NavMenu>
          <Accordion
            label={<NavMenuTitle isOpen={isOpen}>{t('Navigation.monitoring')} </NavMenuTitle>}
            initialValue={monitoringNavView === PageView.expanded}
            onToggleComplete={() => onToggle(PageName.tenderNavMonitoring, monitoringNavView)}
            className={!isOpen ? styles.accordion : undefined}
            labelVisible={isOpen}
          >
            <NavMenuLinks>
              {isMonitoringProfilesFeature && (
                <>
                  <NavSidebarLink
                    to={{ pathname: Paths.MONITORING, state: { from: fromPath } }}
                    title={t('Tenders.monitoringProfiles')}
                    icon={{ defaultIcon: faSparkles, activeIcon: faSparklesSolid }}
                    isActive={pathname === Paths.MONITORING}
                    isExpanded={isOpen}
                    onClick={closeMobileSidebar}
                  />
                  <MpsList sidebarIsOpen={isOpen} />
                </>
              )}
              {isCallOffFeature && (
                <div className={styles.menuItems}>
                  <NavSidebarLink
                    to={{ pathname: Paths.CALL_OFFS, state: { from: fromPath } }}
                    title={t('Navigation.callOffs')}
                    icon={{ defaultIcon: faEnvelope, activeIcon: faEnvelopeSolid }}
                    isActive={pathname === Paths.CALL_OFFS}
                    isExpanded={isOpen}
                    onClick={closeMobileSidebar}
                  />
                  <InboxesList sidebarIsOpen={isOpen} />
                </div>
              )}
              {isAllTendersFeature && (
                <NavSidebarLink
                  to={{ pathname: Paths.ALL_TENDERS, state: { from: fromPath } }}
                  title={t('Navigation.allTenders')}
                  icon={{ defaultIcon: faThList, activeIcon: faThListSolid }}
                  isActive={pathname === Paths.ALL_TENDERS}
                  isExpanded={isOpen}
                  onClick={closeMobileSidebar}
                />
              )}
              {isStarredTendersFeature && (
                <NavSidebarLink
                  to={{ pathname: Paths.STARRED_TENDERS, state: { from: fromPath } }}
                  title={t('Navigation.starred')}
                  icon={{ defaultIcon: faStar, activeIcon: faStarSolid }}
                  isActive={pathname === Paths.STARRED_TENDERS}
                  isExpanded={isOpen}
                  onClick={closeMobileSidebar}
                />
              )}
            </NavMenuLinks>
          </Accordion>
        </NavMenu>
      )}
      {!!isProjectsAndBidsAvailable && (
        <NavMenu className={styles.workspaceMenu}>
          <Accordion
            label={<NavMenuTitle isOpen={isOpen}>{t('Navigation.projectAndBids')} </NavMenuTitle>}
            initialValue={projectBidNavView === PageView.expanded}
            onToggleComplete={() => onToggle(PageName.tenderNavProjectBid, projectBidNavView)}
            className={!isOpen ? styles.accordion : undefined}
            labelVisible={isOpen}
          >
            <NavMenuLinks>
              {!!isBidspacesFeature && (
                <>
                  <NavSidebarLink
                    to={{ pathname: Paths.BIDSPACES, state: { from: fromPath } }}
                    title={t('Navigation.bidSpaces')}
                    icon={{ defaultIcon: faSquareKanban, activeIcon: faSquareKanbanSolid }}
                    isActive={pathname === Paths.BIDSPACES}
                    isExpanded={isOpen}
                    onClick={closeMobileSidebar}
                  />
                  <WorkspacesList sidebarIsOpen={isOpen} />
                </>
              )}
              {isBiddingGPTContentLibrary && (
                <>
                  <NavSidebarLink
                    to={{ pathname: Paths.CONTENT_LIBRARY, state: { from: fromPath } }}
                    title={t('ContentLibrary.title')}
                    icon={{ defaultIcon: faBooks, activeIcon: faBooksSolid }}
                    isActive={pathname === Paths.CONTENT_LIBRARY}
                    isExpanded={isOpen}
                    onClick={closeMobileSidebar}
                  />
                  <ContentLibraryRoomList sidebarIsOpen={isOpen} />
                </>
              )}
              {isBiddingGPTCSVFeature && (
                <NavSidebarLink
                  to={{ pathname: Paths.BIDDING_GPT, state: { from: fromPath } }}
                  title={t('Navigation.biddingGPT')}
                  icon={{ defaultIcon: faCommentsQuestionCheck, activeIcon: faCommentsQuestionCheckSolid }}
                  isActive={pathname === Paths.BIDDING_GPT}
                  isExpanded={isOpen}
                  onClick={closeMobileSidebar}
                />
              )}
              {!!isDocLibFeature && (
                <NavSidebarLink
                  to={{ pathname: Paths.DOCS_LIB, state: { from: fromPath } }}
                  title={t('Navigation.documentLibrary')}
                  icon={{ defaultIcon: faArchive, activeIcon: faArchiveSolid }}
                  isActive={pathname === Paths.DOCS_LIB}
                  isExpanded={isOpen}
                  onClick={closeMobileSidebar}
                />
              )}
            </NavMenuLinks>
          </Accordion>
        </NavMenu>
      )}
      {isAnalyticsAvailable && (
        <NavMenu>
          <Accordion
            label={<NavMenuTitle isOpen={isOpen}>{t('Navigation.analytics')}</NavMenuTitle>}
            initialValue={analyticsNavView === PageView.expanded}
            onToggleComplete={() => onToggle(PageName.tenderNavAnalytics, analyticsNavView)}
            className={!isOpen ? styles.accordion : undefined}
            labelVisible={isOpen}
          >
            <NavMenuLinks>
              {(isAnalyticsExpiringContractsLight || isExpiringContractsPageAvailable) && (
                <>
                  <NavSidebarLink
                    to={{
                      pathname: `${Paths.ANALYTICS_PAGE}${Paths.EXPIRING_CONTRACTS_ROUTE}`,

                      state: { from: fromPath }
                    }}
                    title={
                      <Tooltip
                        title={
                          !isExpiringContractsPageAvailable && isOpen && language !== Language.fi
                            ? t('ExpiringContracts.newTagTooltip')
                            : null
                        }
                        placement={'right'}
                        className={styles.expiringContractsTooltipWrapper}
                      >
                        <span className={styles.expiringContractsLabel}>{t('Navigation.expiringContracts')}</span>
                        {isOpen &&
                          language !== Language.fi &&
                          isAnalyticsExpiringContractsLight &&
                          !isExpiringContractsPageAvailable && <Tag type={TagType.Light} />}
                      </Tooltip>
                    }
                    icon={{ defaultIcon: faCalendarClock, activeIcon: faCalendarClockSolid }}
                    isActive={pathname === `${Paths.ANALYTICS_PAGE}${Paths.EXPIRING_CONTRACTS_ROUTE}`}
                    isExpanded={isOpen}
                    className={styles.expiringContactsLink}
                    isDisabled={!isExpiringContractsPageAvailable && !isAnalyticsExpiringContractsLight}
                    onClick={closeMobileSidebar}
                  />
                  {isExpiringContractsPageAvailable && !isAnalyticsPagesDemo && (
                    <SavedSearchList sidebarIsOpen={isOpen} />
                  )}
                </>
              )}
              {!!isBuyerPageAvailable && (
                <NavSidebarLink
                  to={{ pathname: `${Paths.ANALYTICS_PAGE}${Paths.BUYERS_PAGE}`, state: { from: fromPath } }}
                  title={
                    <div className={styles.newLinkItem}>
                      {t('Navigation.buyerProfiles')}
                      <Tag type={TagType.New} />
                    </div>
                  }
                  icon={{ defaultIcon: faBuilding, activeIcon: faBuildingSolid }}
                  isActive={pathname === `${Paths.ANALYTICS_PAGE}${Paths.BUYERS_PAGE}`}
                  isExpanded={isOpen}
                  onClick={closeMobileSidebar}
                />
              )}
              {!!isSupplierPageAvailable && (
                <NavSidebarLink
                  to={{ pathname: `${Paths.ANALYTICS_PAGE}${Paths.SUPPLIERS_PAGE}`, state: { from: fromPath } }}
                  title={
                    <div className={styles.newLinkItem}>
                      {t('Navigation.suppliersPage')}
                      <Tag type={TagType.New} />
                    </div>
                  }
                  icon={{ defaultIcon: faParachuteBox, activeIcon: faParachuteBoxSolid }}
                  isActive={pathname === `${Paths.ANALYTICS_PAGE}${Paths.SUPPLIERS_PAGE}`}
                  isExpanded={isOpen}
                  onClick={closeMobileSidebar}
                />
              )}
              {isMarketDashboardPageAvailable && (
                <NavSidebarLink
                  to={{ pathname: `${Paths.ANALYTICS_PAGE}${Paths.MARKET_DASHBOARD}`, state: { from: fromPath } }}
                  title={
                    <div className={styles.newLinkItem}>
                      {t('MarketDashboard.marketInsights.title')}
                      <Tag type={TagType.New} />
                    </div>
                  }
                  icon={{ defaultIcon: faMagnifyingGlassChart, activeIcon: faMagnifyingGlassChartSolid }}
                  isActive={pathname === `${Paths.ANALYTICS_PAGE}${Paths.MARKET_DASHBOARD}`}
                  isExpanded={isOpen}
                  onClick={closeMobileSidebar}
                />
              )}
            </NavMenuLinks>
          </Accordion>
        </NavMenu>
      )}
    </>
  );
};

export default TendersNavContent;
