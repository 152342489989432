import React, { FC } from 'react';
import styles from './index.module.scss';
import { useBodyScrollLock } from 'src/helpers';

interface BackdropOverlayProps {
  onClick?: () => void;
  isVisible: boolean;
}

const BackdropOverlay: FC<BackdropOverlayProps> = ({ onClick, isVisible }) => {
  useBodyScrollLock(isVisible);

  if (!isVisible) return null;

  return <div className={styles.overlay} onClick={onClick} />;
};

export default BackdropOverlay;
