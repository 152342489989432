import { gql } from '@apollo/client';
import { ASSIGNED_TO_FIELDS } from '../users/AssignedTo/queries';

export const LOT_FIELDS = gql`
  fragment lotFields on ProcurementLot {
    name
    description
    id
    lotItem
  }
`;

export const BOX_STRING_FIELD = `
  ... on ProcurementBoxStringField {
    name
    type
    string
  }
`;
const BOX_NUMBER_FIELD = `
  ... on ProcurementBoxNumberField {
    name
    type
    number
    unit
  }
`;
const BOX_BOOLEAN_FIELD = `
  ... on ProcurementBoxBooleanFieldDTO {
    name
    type
    boolean
  }
`;
const BOX_ARRAY_FIELD = `
  ... on ProcurementBoxArrayField {
    name
    type
    array
    unit
  }
`;
export const BOX_DATE_FIELD = `
  ... on ProcurementBoxDateFieldDTO {
    name
    type
    date
    time
  }
`;
export const BOX_MONEY_FIELD = `
  ... on ProcurementBoxMoneyFieldDTO {
    name
    type
    amount
    currency
  }
`;
const BOX_MONEY_RANGE_FIELD = `
  ... on ProcurementBoxMoneyRangeFieldDTO {
    name
    type
    from
    to
    currency
  }
`;
const BOX_RANGE_FIELD = `
  ... on ProcurementBoxRangeFieldDTO {
    name
    type
    from
    to
    unit
  }
`;
const BOX_DATE_RANGE_FIELD = `
  ... on ProcurementBoxDateRangeFieldDTO {
    name
    type
    from
    to
  }
`;
const BOX_URL_FIELD = `
  ... on ProcurementBoxUrlField {
    name
    type
    url
    title
  }
`;

const TENDER_BOX_FIELDS = `
  fields {
    ${BOX_STRING_FIELD}
    ${BOX_NUMBER_FIELD}
    ${BOX_BOOLEAN_FIELD}
    ${BOX_ARRAY_FIELD}
    ${BOX_DATE_FIELD}
    ${BOX_MONEY_FIELD}
    ${BOX_MONEY_RANGE_FIELD}
    ${BOX_RANGE_FIELD}
    ${BOX_DATE_RANGE_FIELD}
    ${BOX_URL_FIELD}
  }
`;

export const UPDATE_TENDER_BOX_FIELDS = gql`
  fragment boxFieldsFieldResult on UpdateTenderBoxResult {
    id
    specificationId
    category
    ${TENDER_BOX_FIELDS}
  }
`;

export const BOX_FIELDS_FIELD = gql`
  fragment boxFieldsField on TenderBox {
    ${TENDER_BOX_FIELDS}
  }
`;

export const BASE_BOX_FIELDS = gql`
  fragment baseBoxFields on TenderBox {
    id
    title
    category
    specificationId
  }
`;

export const MARK_AS_REJECTED = gql`
  mutation rejectProcurements($matchingProfileId: String!, $rejected: Boolean!, $procurementIds: [String!]!) {
    rejectProcurements(
      input: { matchingProfileId: $matchingProfileId, rejected: $rejected, procurementIds: $procurementIds }
    )
  }
`;

export const UPDATE_TENDER = gql`
  mutation updateTender($tenderId: String!, $assignedTo: String, $isRead: Boolean) {
    updateTender(input: { tenderId: $tenderId, assignedTo: $assignedTo, isRead: $isRead }) {
      id
      isRead
      assignedTo {
        ...assignedToFields
      }
    }
  }
  ${ASSIGNED_TO_FIELDS}
`;

export const UPDATE_TENDERS = gql`
  mutation updateTenders($tenderIds: [String!]!, $assignedTo: String, $isRead: Boolean) {
    updateTenders(input: { tenderIds: $tenderIds, assignedTo: $assignedTo, isRead: $isRead }) {
      id
      isRead
      assignedTo {
        ...assignedToFields
      }
    }
  }
  ${ASSIGNED_TO_FIELDS}
`;

export const MARK_TENDER_IS_STARRED = gql`
  mutation addTenderToStarred($tenderId: String!) {
    addTenderToStarred(input: { tenderId: $tenderId }) {
      id
      isStarred
    }
  }
`;

export const MARK_TENDER_IS_UNSTARRED = gql`
  mutation removeTenderFromStarred($tenderId: String!) {
    removeTenderFromStarred(input: { tenderId: $tenderId }) {
      id
      isStarred
    }
  }
`;
