import { MutationUpdaterFn, QueryResult, Reference, useMutation, useQuery } from '@apollo/client';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateReminderInput, GetRemindersInput } from '@tendium/prom-types/schema';
import { CREATE_REMINDER, DELETE_REMINDER, GET_REMINDERS, GET_REMINDERS_COUNT, HANDLE_REMINDER } from './queries';
import { Reminder as ReminderType, ApiRemindersResponse, DEFAULT_REMINDERS_LIMIT, RemindersEventSource } from './types';
import { Reminder } from '.';
import { RemindersContext, RemindersContextValue } from './context';
import { notification } from 'src/common';
import { useLocation } from 'react-router';
import { Paths } from 'src/pages/paths';
import { trackAddReminder, trackDeleteReminder, trackHandledReminder } from 'src/segment/events/reminders';

export function useCreateReminder(): [
  (reminder: CreateReminderInput) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();

  const [createReminder, { loading, error }] = useMutation(CREATE_REMINDER);

  const eventSource = useRemindersNav();

  const createReminderFn = useCallback(
    (reminder: CreateReminderInput) => {
      createReminder({
        variables: { reminder },
        update: getUpdateCacheOnCreateReminder()
      })
        .then(() => {
          trackAddReminder(eventSource, reminder.assignedTo ?? null);
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [createReminder, eventSource, t]
  );
  return [createReminderFn, { loading, error }];
}

export function getUpdateCacheOnCreateReminder(): MutationUpdaterFn<{ createReminder: Reminder }> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const newReminder = data.createReminder;
    const updateFields = [newReminder.relationId, newReminder.assignedTo?.id, newReminder.createdBy?.email].filter(
      id => id !== undefined
    );
    cache.modify({
      fields: {
        getReminders(cached = [], { storeFieldName, toReference }) {
          const newReminderRef = toReference({
            __typename: 'Reminder',
            id: newReminder.id
          });

          const fieldsToUpdate = updateFields.some(id => storeFieldName.includes(id as string));
          if (fieldsToUpdate) {
            return [...cached, newReminderRef];
          }
          return cached;
        },
        getRemindersCount(cached, { storeFieldName }) {
          const fieldsToUpdate = updateFields.some(id => storeFieldName.includes(id as string));
          if (fieldsToUpdate) {
            return {
              ...cached,
              totalCount: cached.totalCount + 1
            };
          } else return cached;
        }
      }
    });
  };
}

export function useReminders(): RemindersContextValue {
  return useContext(RemindersContext);
}

// NOTE: These paths might need to be changed when the new paths are implemented
export function useRemindersNav(): RemindersEventSource {
  const { pathname } = useLocation();

  const isBiddingToolPage = pathname.includes(Paths.TENDER_ROUTE + 'bid/');

  const isBidPreviewPage = pathname.includes(Paths.BIDSPACES);

  const isCustomReportPage = pathname.includes(Paths.TENDER_ROUTE) && !isBiddingToolPage;

  if (isBiddingToolPage) return RemindersEventSource.biddingTool;
  if (isBidPreviewPage) return RemindersEventSource.bidPreview;
  if (isCustomReportPage) return RemindersEventSource.customReportPage;

  // Default
  return RemindersEventSource.notificationList;
}

export interface UseRemindersData extends Omit<QueryResult<ApiRemindersResponse, GetRemindersInput>, 'data'> {
  data?: ReminderType[];
  loading: boolean;
}

export function useRemindersData(relationId?: string, assignedTo?: string, createdBy?: string): UseRemindersData {
  const query = useQuery<ApiRemindersResponse, GetRemindersInput>(GET_REMINDERS, {
    variables: {
      relationId,
      assignedTo,
      createdBy,
      from: 0,
      size: DEFAULT_REMINDERS_LIMIT
    }
  });

  return useMemo(
    () => ({
      ...query,
      data: query.data ? query.data.getReminders.map(reminder => new Reminder(reminder)) : undefined,
      loading: query.loading
    }),
    [query]
  );
}

export function useRemindersCountData(
  input: GetRemindersInput
): QueryResult<{ getRemindersCount: { totalCount: number } }, { input: GetRemindersInput }> {
  return useQuery<{ getRemindersCount: { totalCount: number } }, { input: GetRemindersInput }>(GET_REMINDERS_COUNT, {
    variables: { input }
  });
}

export function useDeleteReminder(): [
  (deleteReminderId: string, fromNotificationList?: boolean) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const eventSource = useRemindersNav();
  const [deleteReminder, { loading, error }] = useMutation(DELETE_REMINDER);

  const deleteReminderFn = useCallback(
    (deleteReminderId: string, fromNotificationList?: boolean) => {
      deleteReminder({
        variables: { deleteReminderId },
        update: getUpdateCacheOnDeleteReminder(deleteReminderId)
      })
        .then(() => {
          fromNotificationList
            ? trackDeleteReminder(RemindersEventSource.notificationList)
            : trackDeleteReminder(eventSource);
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [deleteReminder, eventSource, t]
  );
  return [deleteReminderFn, { loading, error }];
}

export function getUpdateCacheOnDeleteReminder(
  deleteReminderId: string
): MutationUpdaterFn<{ deleteReminder: { id: string } }> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    cache.modify({
      fields: {
        getReminders(existingRemindersRefs = [], { readField }) {
          return existingRemindersRefs.filter(
            (reminderRef: Reference) => deleteReminderId !== readField('id', reminderRef)
          );
        },
        getRemindersCount(existingCountData) {
          return {
            ...existingCountData,
            totalCount: existingCountData.totalCount - 1
          };
        }
      }
    });
  };
}

export function useHandleReminder(): [
  (handleReminderId: string, fromNotification?: boolean) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const eventSource = useRemindersNav();
  const [handleReminder, { loading, error }] = useMutation(HANDLE_REMINDER);

  const handleReminderFn = useCallback(
    (handleReminderId: string, fromNotification?: boolean) => {
      handleReminder({
        variables: { handleReminderId }
      })
        .then(() => {
          fromNotification
            ? trackHandledReminder(RemindersEventSource.notificationList)
            : trackHandledReminder(eventSource);
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [handleReminder, eventSource, t]
  );
  return [handleReminderFn, { loading, error }];
}
