import { EnterpriseBrand, getBaseUrl, UserOwnership as Ownerships } from '@tendium/prom-types';
import { ByggfaktaLogoIcon, CliraLogoIcon, TendiumLogoIcon } from 'src/common/icons';
import { TeliaLogoIcon } from 'src/common/icons/TeliaLogoIcon';
import { Config } from 'src/config/config';

export { Ownerships };

export interface OwnershipLinks {
  POLICY_URL: string;
  LANDING_URL: string;
  CONTACT_URL: string;
  SUPPORT_EMAIL: string;
}

export enum ThemeMode {
  light = 'light',
  dark = 'dark'
}

export interface LogoProps {
  mode?: ThemeMode;
  isShort?: boolean;
  className?: string;
}

export interface Favicon {
  svg: string;
  ico: string;
}

export interface OwnershipConfig {
  domain?: string;
  qrCodeTitle?: string;
  links: OwnershipLinks;
}

export interface BrandConfig {
  logo: React.FC<LogoProps>;
  favicon: Favicon;
  samlConfig?: string;
  enterpriseDomain?: string;
}

export const BRANDS: { [key: string]: BrandConfig } = {
  [Ownerships.Tendium]: {
    logo: TendiumLogoIcon,
    favicon: {
      ico: `${import.meta.env.BASE_URL}favicons/favicon.ico`,
      svg: `${import.meta.env.BASE_URL}favicons/favicon-tendium.svg`
    }
  },
  [Ownerships.Byggfakta]: {
    logo: ByggfaktaLogoIcon,
    favicon: {
      ico: `${import.meta.env.BASE_URL}favicons/byggfakta-icon.ico`,
      svg: `${import.meta.env.BASE_URL}favicons/byggfakta-icon.svg`
    }
  },
  [Ownerships.Clira]: {
    logo: CliraLogoIcon,
    favicon: {
      ico: `${import.meta.env.BASE_URL}favicons/clira-icon.ico`,
      svg: `${import.meta.env.BASE_URL}favicons/clira-icon.svg`
    }
  },
  [EnterpriseBrand.Telia]: {
    logo: TeliaLogoIcon,
    favicon: {
      ico: `${import.meta.env.BASE_URL}favicons/telia-icon.ico`,
      svg: `${import.meta.env.BASE_URL}favicons/telia-icon.svg`
    },
    samlConfig: Config.PrivateApi.includes('prod.') ? 'teliacompany.com' : 'tendium.onmicrosoft.com',
    enterpriseDomain: getBaseUrl(
      Ownerships.Tendium,
      EnterpriseBrand.Telia,
      Config.PrivateApi.includes('prod.') ? 'prod' : 'stag'
    )
  }
};

export const OWNERSHIPS: { [key: string]: OwnershipConfig } = {
  [Ownerships.Tendium]: {
    links: {
      POLICY_URL: 'https://tendium.ai/privacy-policy',
      LANDING_URL: 'https://tendium.ai',
      CONTACT_URL: 'https://tendium.ai/contact',
      SUPPORT_EMAIL: 'support@tendium.com'
    }
  },
  [Ownerships.Byggfakta]: {
    domain: getBaseUrl(Ownerships.Byggfakta, null, Config.PrivateApi.includes('prod.') ? 'prod' : 'stag'),
    qrCodeTitle: 'Byggfakta Tender',
    links: {
      POLICY_URL: '#',
      LANDING_URL: 'https://www.byggfaktatender.com',
      CONTACT_URL: 'https://www.byggfaktatender.com/sv/inkopare',
      SUPPORT_EMAIL: 'tender@byggfakta.se'
    }
  },
  [Ownerships.Clira]: {
    domain: getBaseUrl(Ownerships.Clira, null, Config.PrivateApi.includes('prod.') ? 'prod' : 'stag'),
    links: {
      POLICY_URL: '',
      LANDING_URL: 'https://www.clira.io/',
      CONTACT_URL: 'https://www.clira.io/#komigang',
      SUPPORT_EMAIL: 'info@areachica.se'
    }
  }
};
