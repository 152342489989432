import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import Published from '../TendersList/Published';
import WorkspaceBadge from 'src/common/WorkspaceBadge';
import Assignee from 'src/shared/Assignee';
import { StarToggler } from 'src/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faPlusSquare } from '@fortawesome/pro-light-svg-icons';
import { IBidIdentifier } from 'src/models/bids/Bids/types';
import { useBidWorkspace } from 'src/models/bids/Bids/hooks';
import { TendersTableItem } from 'src/models/procurements/TendersViews';
import { FeatureFlag } from 'src/helpers/featureFlag';
import { useFeatureFlag } from 'src/helpers';

interface Props {
  tender: TendersTableItem;
  originIds?: string[];
  bidIds?: IBidIdentifier[];
}
/** @todo each view - their own dnd-preview? */
export const TendersDndPreview: FC<Props> = ({ tender, originIds, bidIds }) => {
  const { name, isStarred, isRead, published, assignedTo, isCommented } = tender;
  const isStarredTendersFeature = useFeatureFlag(FeatureFlag.StarredProcurements);

  const { workspace: bidWs } = useBidWorkspace((bidIds && bidIds.length && bidIds[0].id) || '');

  const itemCount = useMemo(() => {
    const originCount = originIds ? originIds.length : 0;
    const bidsCount = bidIds ? bidIds.length : 0;
    return originCount + bidsCount;
  }, [bidIds, originIds]);

  return (
    <div
      className={classNames(styles.dragPreview, {
        [styles.isCouple]: itemCount === 2,
        [styles.isMultiple]: itemCount > 2,
        [styles.isRead]: isRead
      })}
    >
      <div className={styles.inner}>
        <span className={styles.colCheck}>
          <span className={styles.check}></span>
        </span>
        <span className={styles.colWs}>
          {bidWs ? (
            <WorkspaceBadge color={bidWs.color} title={bidWs.name} />
          ) : (
            <FontAwesomeIcon icon={faPlusSquare} className={styles.wsButtonIcon} />
          )}
        </span>
        <span className={styles.colPublished}>
          <Published published={published} />
        </span>
        <span className={styles.colTitle}>{name}</span>
        {!!assignedTo && (
          <span className={classNames(styles.colAction, styles.colAssignTo)}>
            <Assignee assignedToId={assignedTo} mode={'onlyAvatar'} />
          </span>
        )}
        {isCommented && (
          <span className={classNames(styles.colAction, styles.colMessage)}>
            <FontAwesomeIcon icon={faCommentDots} />
          </span>
        )}
        {isStarredTendersFeature && (
          <span className={classNames(styles.colAction, styles.colStar)}>
            <StarToggler
              onSetStarred={() => {
                /* empty fn */
              }}
              id={''}
              isStarred={isStarred}
              name={name}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default TendersDndPreview;
