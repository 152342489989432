import { FieldPolicy } from '@apollo/client';
import { mergeUniqueArray } from 'src/lib/API/graphql/helpers';
import { ITendersCached } from '../types';

//[C] Non-paginated read with filter
export function getTendersPolicy(): FieldPolicy {
  return {
    keyArgs: ['query', ['sortOnField', 'matchingProfileId', 'isUnhandled', 'isStarred'], 'advanced'],
    merge(existing: ITendersCached | undefined, incoming: ITendersCached | undefined, options) {
      const fetchedTendersCount = (existing?.fetchedTendersCount ?? 0) + (incoming?.procurementsWithScore?.length ?? 0);
      const uniqueTenders = mergeUniqueArray(
        existing?.procurementsWithScore,
        options.args ? incoming?.procurementsWithScore : undefined,
        options,
        (tender, { readField }) => readField({ fieldName: 'id', from: tender.procurement })
      );

      return {
        ...existing,
        ...incoming,
        procurementsWithScore: uniqueTenders,
        fetchedTendersCount
      };
    }
  };
}
