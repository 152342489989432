import { ApolloError, MutationUpdaterFn, Reference, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  CREATE_CONTENT_LIBRARY_ROOM,
  DELETE_CONTENT_LIBRARY_ROOM,
  GET_CONTENT_LIBRARY_ROOMS,
  UPDATE_CONTENT_LIBRARY_ROOM
} from './queries';
import { useCallback, useContext, useMemo } from 'react';
import { notification } from 'src/common';
import { Room } from '.';
import {
  ApiCreateRoomResponse,
  ApiDeleteRoomResponse,
  ApiRoomResponse,
  ApiUpdateRoomResponse,
  ContentLibraryRoomResponseData
} from './types';
import { DefaultInput } from '../ContentLibrary/types';
import { RoomContext, RoomContextValue } from './ context';
import {
  CreateContentLibraryRoomDTO,
  DeleteContentLibraryRoomDTO,
  GetContentLibraryRoomDTO,
  UpdateContentLibraryRoomDTO
} from '@tendium/prom-types/schema';
import { trackAddRoomCL } from 'src/segment/events';
import { useAllUsersAndTeams } from 'src/models/users/hooks';
import { getLoadingStatesForQuery } from 'src/lib/API/graphql/helpers';

export function useCreateContentLibraryRoom(): [
  (data: CreateContentLibraryRoomDTO, onComplete?: (id: string) => void) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();

  const [createRoom, { loading, error }] = useMutation<
    ApiCreateRoomResponse,
    DefaultInput<CreateContentLibraryRoomDTO>
  >(CREATE_CONTENT_LIBRARY_ROOM);

  const createRoomFn = useCallback(
    (input: CreateContentLibraryRoomDTO, onComplete?: (id: string) => void) => {
      createRoom({
        variables: { input },
        update: updateCacheOnCreateContentLibraryRoom()
      })
        .then(d => {
          if (d.data?.createContentLibraryRoom) {
            onComplete && onComplete(d.data?.createContentLibraryRoom.id);
            trackAddRoomCL({ id: d.data.createContentLibraryRoom.id, name: d.data.createContentLibraryRoom.title });
          }
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [createRoom, t]
  );
  return useMemo(() => [createRoomFn, { loading, error }], [createRoomFn, error, loading]);
}

function updateCacheOnCreateContentLibraryRoom(): MutationUpdaterFn<ApiCreateRoomResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    const newRef = cache.identify({ __typename: 'ContentLibraryRoom', id: data.createContentLibraryRoom.id });

    if (newRef) {
      cache.modify({
        fields: {
          getContentLibraryRooms: (existing: Reference[], { toReference }) => {
            return [...existing, toReference(newRef)];
          }
        }
      });
    }
  };
}

export function useLoadContentLibraryRooms(): ContentLibraryRoomResponseData {
  const query = useQuery<ApiRoomResponse, GetContentLibraryRoomDTO>(GET_CONTENT_LIBRARY_ROOMS, {
    variables: { ids: [] },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });
  const allUsersAndTeams = useAllUsersAndTeams();

  return useMemo(
    () => ({
      ...query,
      ...getLoadingStatesForQuery(query),
      data: query.data?.getContentLibraryRooms.map(room => new Room(room, allUsersAndTeams))
    }),
    [allUsersAndTeams, query]
  );
}

export function useDeleteContentLibraryRoom(): [
  (data: DeleteContentLibraryRoomDTO, onFinish?: () => void) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [deleteContentLibraryRoom, { loading, error }] = useMutation<
    ApiDeleteRoomResponse,
    DefaultInput<DeleteContentLibraryRoomDTO>
  >(DELETE_CONTENT_LIBRARY_ROOM);
  const deleteContentLibraryRoomFn = useCallback(
    (data: DeleteContentLibraryRoomDTO, onFinish?: () => void) => {
      deleteContentLibraryRoom({
        variables: { input: data },
        update: updateCacheOnDeleteContentLibraryRoom(data.id)
      })
        .then(() => {
          onFinish && onFinish();
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [deleteContentLibraryRoom, t]
  );

  return useMemo(() => [deleteContentLibraryRoomFn, { loading, error }], [error, loading, deleteContentLibraryRoomFn]);
}

function updateCacheOnDeleteContentLibraryRoom(id: string): MutationUpdaterFn<ApiDeleteRoomResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    const normalizedId = cache.identify({ id, __typename: 'ContentLibraryRoom' });
    cache.evict({ id: normalizedId });
    cache.gc();
  };
}

export function useUpdateContentLibraryRoom(): [
  (input: UpdateContentLibraryRoomDTO, onFinish?: () => void) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [updateContentLibraryRoom, { loading, error }] = useMutation<
    ApiUpdateRoomResponse,
    DefaultInput<UpdateContentLibraryRoomDTO>
  >(UPDATE_CONTENT_LIBRARY_ROOM);
  const updateContentLibraryRoomFn = useCallback(
    (input: UpdateContentLibraryRoomDTO, onFinish?: () => void) => {
      updateContentLibraryRoom({
        variables: { input }
      })
        .then(() => {
          onFinish && onFinish();
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [t, updateContentLibraryRoom]
  );
  return useMemo(() => [updateContentLibraryRoomFn, { loading, error }], [updateContentLibraryRoomFn, error, loading]);
}

export function useRoom(): RoomContextValue {
  return useContext(RoomContext);
}

export function useRoomsWithAccess(): Room[] {
  const { data: roomData } = useLoadContentLibraryRooms();
  const avaibleRooms = useMemo(() => {
    return roomData?.filter(room => room.accessToEdit);
  }, [roomData]);
  return avaibleRooms ?? [];
}
