import { useEffect } from 'react';
import { useFavicon, useTitle } from 'react-use';
import { groupByCompany, identifyUser } from './segment';
import { useApp, isPaidUser } from './models/auth';
import { ownership } from './models/ownership';

export function useInitHook(): void {
  const { user } = useApp();

  useTitle(ownership.metaTitle);
  useFavicon(ownership.theme.favicon.ico);
  useFavicon(ownership.theme.favicon.svg);

  document.body.setAttribute('data-theme', ownership.brandName);

  useEffect(() => {
    const hasPaidStatus = isPaidUser(user);

    if (user) {
      identifyUser(user.email, {
        name: user.name ?? '',
        lastName: user.lastName ?? '',
        userType: user.permission,
        ownership: hasPaidStatus ? user.company.ownership : undefined
      });
      if (hasPaidStatus) {
        groupByCompany(user.company.id, {
          name: user.company.companyName
        });
      }
    }
  }, [user]);
}
