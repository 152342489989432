import { QueryResult, Reference } from '@apollo/client';
import { Schema } from '@tendium/prom-types';
import { License } from '.';

export interface LicenseAdminData
  extends Omit<
    QueryResult<
      { getLicensesAsAdmin: { licenses: Schema.FeatureAccess.License[]; total: number } },
      Schema.FeatureAccess.GetLicensesAsAdminInput
    >,
    'data'
  > {
  data?: License[];
  fetchingMore: boolean;
  total: number;
}

export interface LicenseData
  extends Omit<
    QueryResult<
      { getLicenses: { licenses: Schema.FeatureAccess.License[]; total: number } },
      Schema.FeatureAccess.GetLicensesInput
    >,
    'data'
  > {
  data?: License[];
  fetchingMore: boolean;
  total: number;
}

export type LicenseCachedResult = {
  value: { licenses: Reference[]; total: number };
  variables?: { search: string };
};

export enum LicenseSearchParams {
  search = 'license-search'
}
