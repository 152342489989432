import { FieldPolicy, Reference } from '@apollo/client';
import { TasksCached, TasksCachedResult, BiddingStatsCached } from './types';
import { offsetLimitPaginationMerge } from 'src/lib/API/graphql/helpers';

//[C] Non-paginated read
export function getTasksPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['groupId', 'type', 'isSubmitted', 'filters']],
    read(existing: TasksCachedResult) {
      return existing?.value ?? undefined;
    },
    merge(existing: TasksCachedResult | undefined, incoming: TasksCached | undefined, options) {
      const { variables } = options;
      const merged = offsetLimitPaginationMerge(existing?.value.questions, incoming?.questions, options);

      return {
        value: {
          ...existing,
          ...incoming,
          questions: merged
        },
        variables
      };
    }
  };
}

export function getBiddingStatsPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['bidId', 'filters', 'isSubmitted']],
    read(existing: BiddingStatsCached | undefined) {
      return existing?.value ?? undefined;
    },
    merge(existing: BiddingStatsCached | undefined, incoming: Reference[] | undefined, { variables }) {
      return {
        value: incoming,
        variables
      };
    }
  };
}
