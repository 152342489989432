import { FeatureFlag, useAnalyticsFeatureFlags } from './featureFlag';
import { useFeatureFlag } from './featureFlag';

interface MonitoringFeatureAccess {
  isMonitoringProfilesFeature: boolean;
  isCallOffFeature: boolean;
  isAllTendersFeature: boolean;
  isStarredTendersFeature: boolean;
  isMonitoringAvailable: boolean;
}

export function useMonitoringFeatureAccess(): MonitoringFeatureAccess {
  const isMonitoringProfilesFeature = useFeatureFlag(FeatureFlag.MonitoringProfiles);
  const isCallOffFeature = useFeatureFlag(FeatureFlag.Mercury);
  const isAllTendersFeature = useFeatureFlag(FeatureFlag.AllTenders);
  const isStarredTendersFeature = useFeatureFlag(FeatureFlag.StarredProcurements);
  const isMonitoringAvailable =
    isMonitoringProfilesFeature || isCallOffFeature || isAllTendersFeature || isStarredTendersFeature;

  return {
    isMonitoringProfilesFeature: !!isMonitoringProfilesFeature,
    isCallOffFeature: !!isCallOffFeature,
    isAllTendersFeature: !!isAllTendersFeature,
    isStarredTendersFeature: !!isStarredTendersFeature,
    isMonitoringAvailable: !!isMonitoringAvailable
  };
}

interface ProjectsAndBidsFeatureAccess {
  isBidspacesFeature: boolean;
  isBiddingGPTContentLibrary: boolean;
  isDocLibFeature: boolean;
  isBiddingGPTCSVFeature: boolean;
  isProjectsAndBidsAvailable: boolean;
}

export function useProjectsAndBidsFeatureAccess(): ProjectsAndBidsFeatureAccess {
  const isBidspacesFeature = useFeatureFlag(FeatureFlag.Bidspaces);
  const isBiddingGPTContentLibrary = useFeatureFlag(FeatureFlag.BiddingTool_BiddingGPT_ContentLibrary);
  const isDocLibFeature = useFeatureFlag(FeatureFlag.Interactions_DocumentLibrary);
  const isBiddingGPTCSVFeature = useFeatureFlag(FeatureFlag.BiddingTool_BiddingGPT_CSV);
  const isProjectsAndBidsAvailable =
    isBidspacesFeature || isDocLibFeature || isBiddingGPTCSVFeature || isBiddingGPTContentLibrary;

  return {
    isBidspacesFeature: !!isBidspacesFeature,
    isBiddingGPTContentLibrary: !!isBiddingGPTContentLibrary,
    isDocLibFeature: !!isDocLibFeature,
    isBiddingGPTCSVFeature: !!isBiddingGPTCSVFeature,
    isProjectsAndBidsAvailable: !!isProjectsAndBidsAvailable
  };
}

interface AnalyticsFeatureAccess {
  isBuyerPageAvailable: boolean;
  isSupplierPageAvailable: boolean;
  isMarketDashboardPageAvailable: boolean;
  isExpiringContractsPageAvailable: boolean;
  isAnalyticsExpiringContractsLight: boolean;
  isAnalyticsPagesDemo: boolean;
  isAnalyticsAvailable: boolean;
}

export function useAnalyticsFeatureAccess(): AnalyticsFeatureAccess {
  const isBuyerPageAvailable = useAnalyticsFeatureFlags(FeatureFlag.Analytics_BuyerProfilesPage);
  const isSupplierPageAvailable = useAnalyticsFeatureFlags(FeatureFlag.Analytics_Supplier_Profiles_Page);
  const isMarketDashboardPageAvailable = useAnalyticsFeatureFlags(FeatureFlag.AnalyticsMarketDashboard);
  const isAnalyticsPagesDemo = useFeatureFlag(FeatureFlag.Analytics_Pages_Demo);
  const isExpiringContractsPageAvailable =
    useFeatureFlag(FeatureFlag.AnalyticsExpiringContracts) || isAnalyticsPagesDemo;
  const isAnalyticsExpiringContractsLight = useFeatureFlag(FeatureFlag.AnalyticsExpiringContractsLight);
  const isAnalyticsAvailable =
    isBuyerPageAvailable ||
    isSupplierPageAvailable ||
    isMarketDashboardPageAvailable ||
    isExpiringContractsPageAvailable ||
    isAnalyticsExpiringContractsLight;

  return {
    isBuyerPageAvailable: !!isBuyerPageAvailable,
    isSupplierPageAvailable: !!isSupplierPageAvailable,
    isMarketDashboardPageAvailable: !!isMarketDashboardPageAvailable,
    isAnalyticsPagesDemo: !!isAnalyticsPagesDemo,
    isExpiringContractsPageAvailable: !!isExpiringContractsPageAvailable,
    isAnalyticsExpiringContractsLight: !!isAnalyticsExpiringContractsLight,
    isAnalyticsAvailable: !!isAnalyticsAvailable
  };
}
