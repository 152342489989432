import { FieldPolicy } from '@apollo/client';
import { mergeUniqueArray } from 'src/lib/API/graphql/helpers';
import { AdminCompaniesCached } from './types';

//[C] Non-paginated read with filter
export function getAdminCompaniesPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['searchOptions', ['id', 'name'], 'sortBy']],
    merge(existing: AdminCompaniesCached | undefined, incoming: AdminCompaniesCached | undefined, options) {
      const uniqueCompanies = mergeUniqueArray(existing?.companies, incoming?.companies, options);

      return {
        ...existing,
        ...incoming,
        companies: uniqueCompanies
      };
    }
  };
}
