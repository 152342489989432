import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import Tooltip from 'src/common/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { useIsMobile } from 'src/reactiveVars';

interface Props {
  desc: ReactNode;
  className?: string;
}
export const InfoIcon: FC<Props> = ({ desc, className }) => {
  const [isMobile] = useIsMobile();
  if (isMobile) return null;

  return (
    <Tooltip title={desc}>
      <FontAwesomeIcon icon={faInfoCircle} className={classNames(className, styles.icon)} />
    </Tooltip>
  );
};

export default InfoIcon;
