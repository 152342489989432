import React, { FC, useMemo } from 'react';
import { Navigate } from 'react-router';
import { useApp, UserType } from 'src/models/auth';
import { Paths } from '../paths';
import { StorageKey } from 'src/types/enums';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';

/** all authorized redirection (aka user logged in) should be handled in `HomePage` */
export const HomePage: FC = () => {
  const { permission } = useApp();
  const fromUrl = sessionStorage.getItem(StorageKey.FromLogin);
  const isPaid = useMemo(() => permission === UserType.Paid, [permission]);
  const isMonitoringProfilesAvailable = useFeatureFlag(FeatureFlag.MonitoringProfiles);
  const isAnalyticsExpContractAvailable = useFeatureFlag(FeatureFlag.AnalyticsExpiringContracts);
  const isBidspacesAvailable = useFeatureFlag(FeatureFlag.Bidspaces);
  const isBiddingGPTAvailable = useFeatureFlag(FeatureFlag.BiddingTool_BiddingGPT);
  const isAllTendersFeature = useFeatureFlag(FeatureFlag.AllTenders);

  const paidRedirect = useMemo(() => {
    if (fromUrl) {
      sessionStorage.removeItem(StorageKey.FromLogin);
      return fromUrl;
    }
    if (isMonitoringProfilesAvailable) {
      return Paths.MONITORING;
    }
    if (isBidspacesAvailable && isBiddingGPTAvailable) {
      return Paths.BIDSPACES;
    }
    if (isAnalyticsExpContractAvailable) {
      return Paths.ANALYTICS_PAGE + Paths.EXPIRING_CONTRACTS_ROUTE;
    }

    if (isAllTendersFeature) {
      return Paths.ALL_TENDERS;
    }

    return Paths.SETTINGS_PAGE;
  }, [
    fromUrl,
    isAllTendersFeature,
    isAnalyticsExpContractAvailable,
    isBiddingGPTAvailable,
    isBidspacesAvailable,
    isMonitoringProfilesAvailable
  ]);
  const freeRedirect = useMemo(() => fromUrl ?? Paths.SETTINGS_PAGE, [fromUrl]);
  const to = useMemo(() => (isPaid ? paidRedirect : freeRedirect), [freeRedirect, isPaid, paidRedirect]);

  sessionStorage.removeItem(StorageKey.FromLogin);

  return <Navigate to={to} replace />;
};

export default HomePage;
