import { Paths } from 'src/pages/paths';
import { ALL_DASHES_REGEX, isString } from 'src/helpers';
import {
  CategorizedOrganisations,
  IApiOrganization,
  ORG_ID_BULK_MODE_REGEX,
  OrganisationTableData,
  OrgType
} from './types';
import { TFunction } from 'i18next';
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter';
import { SearchLogic } from '@tendium/prom-types';
import { SupplierOrg } from '../procurements/Tenders/types';

export function getBuyerLink(orgId: string, orgName?: string): string {
  const buyerUrl = `${Paths.ANALYTICS_PAGE}${Paths.BUYER_PAGE.replace(':orgId', orgId.replace(ALL_DASHES_REGEX, ''))}`;
  return !!orgName ? `${buyerUrl}?orgName=${encodeURIComponent(orgName)}` : buyerUrl;
}

export function getSupplierLink(orgId: string, orgName?: string): string {
  const supplierUrl = `${Paths.ANALYTICS_PAGE}${Paths.SUPPLIER_PAGE.replace(
    ':orgId',
    orgId.replace(ALL_DASHES_REGEX, '')
  )}`;
  return !!orgName ? `${supplierUrl}?orgName=${encodeURIComponent(orgName)}` : supplierUrl;
}

export function isContainOrgId(orgId: string): boolean {
  const value = orgId.trimStart().replace(ALL_DASHES_REGEX, '');

  return ORG_ID_BULK_MODE_REGEX.test(value);
}

export function getSupplierNameByOrgId(supplierData: IApiOrganization[], supplierOrgId: string): string {
  const supplier = supplierData.find(d => d.organisationNumber === supplierOrgId);
  return getFallbackOrgName(supplier?.organisationNumber ?? supplierOrgId, supplier?.organisationName);
}

export const categorizeOrganisations = (
  organisations: IApiOrganization[],
  loading: boolean,
  orgIds: string[],
  lastAddedOrgIds?: string[],
  supplierOrgIds?: SupplierOrg[]
): CategorizedOrganisations => {
  if (loading && organisations.length === 0) {
    return {
      previouslyAdded: [],
      lastAdded: [],
      notFound: []
    };
  }

  const organisationsMap = new Map(organisations.map(org => [org.organisationNumber, org.organisationName]));
  const lastAddedIdsSet = new Set<string>(lastAddedOrgIds);
  const previouslyAddedSet = new Set<OrganisationTableData>();
  const lastAddedSet = new Set<OrganisationTableData>();
  const notFoundSet = new Set<OrganisationTableData>();

  orgIds.forEach(org => {
    const status = supplierOrgIds?.find(supplierOrgId => supplierOrgId.orgId === org)?.status;
    const organisation = organisationsMap.get(org);
    if (organisation) {
      if (lastAddedIdsSet.has(org)) {
        lastAddedSet.add({
          key: org,
          value: organisation,
          status,
          type: SearchLogic.ExactMatch
        });
      } else {
        previouslyAddedSet.add({
          key: org,
          value: organisation,
          status,
          type: SearchLogic.ExactMatch
        });
      }
    } else {
      notFoundSet.add({ key: org, value: null, status: undefined, type: SearchLogic.ExactMatch });
    }
  });

  return {
    previouslyAdded: [...previouslyAddedSet],
    lastAdded: [...lastAddedSet],
    notFound: [...notFoundSet]
  };
};

export function getFallbackOrgName(orgId?: string | null, orgName?: string | null): string {
  return orgName ?? orgId ?? '';
}

export function getFallbackOrgNameFromQP(
  qp: Partial<Record<string, unknown>>,
  orgId?: string | null,
  orgName?: string | null
): string {
  return (isString(qp['orgName']) ? qp['orgName'] : undefined) ?? getFallbackOrgName(orgId, orgName);
}

export function getTranslatedOrgLabel(
  t: TFunction,
  orgType: OrgType,
  options?: { capitalizing?: boolean; plural?: boolean }
): string {
  const label = t(`Common.OrgType.${orgType}`, { count: options?.plural ? 2 : 1 });
  if (options?.capitalizing) {
    return capitalizeFirstLetter(label);
  }
  return label;
}
