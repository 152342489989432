import React, { FC } from 'react';
import NavSidebar from '../NavSidebar';
import TendersDndLayer from 'src/pages/TendersPage/DndLayer';

import TendersNavContent from './TendersNavContent';
import {
  useAnalyticsFeatureAccess,
  useMonitoringFeatureAccess,
  useProjectsAndBidsFeatureAccess
} from 'src/helpers/productFeatures';

interface Props {
  isNarrow?: boolean;
}

export const TendersNav: FC<Props> = ({ isNarrow }) => {
  const { isMonitoringAvailable } = useMonitoringFeatureAccess();
  const { isBidspacesFeature, isProjectsAndBidsAvailable } = useProjectsAndBidsFeatureAccess();
  const { isAnalyticsAvailable } = useAnalyticsFeatureAccess();

  const showSidebar = isMonitoringAvailable || isProjectsAndBidsAvailable || isAnalyticsAvailable;

  if (!showSidebar) {
    return null;
  }

  return (
    <NavSidebar type={isNarrow ? 'min' : undefined}>
      {isBidspacesFeature && <TendersDndLayer />}
      <TendersNavContent />
    </NavSidebar>
  );
};

export default TendersNav;
