import { FieldPolicy } from '@apollo/client';
import { mergeUniqueArray } from 'src/lib/API/graphql/helpers';
import { ContentLibraryCached } from './types';

//[C] Non-paginated read
export function getContentLibraryPolicy(): FieldPolicy {
  return {
    keyArgs: ['query', ['filters', 'question', 'sort']],
    merge(existing: ContentLibraryCached | undefined, incoming: ContentLibraryCached | undefined, options) {
      const merged = mergeUniqueArray(existing?.rows, incoming?.rows, options);

      return {
        ...existing,
        ...incoming,
        rows: merged
      };
    }
  };
}
