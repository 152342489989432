import React, { FC, ReactElement, ReactNode } from 'react';
import { Spinner, FullscreenOverlay } from 'src/common';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import styles from '../index.module.scss';

interface Props {
  label: ReactNode;
  className?: string;
  isDisabled?: boolean;
  dropdownIcon?: boolean;
  loading?: boolean;
  placeholder?: string;
  buttonClassName?: string;
  selectedValueLabel: string | number | true | ReactElement | Iterable<ReactNode> | undefined;
  isMenuVisible: boolean;
  onVisibleChange: () => void;
  overlay: JSX.Element;
}

const PlainDropDownMobile: FC<Props> = ({
  label,
  isDisabled,
  className,
  loading,
  placeholder,
  selectedValueLabel,
  isMenuVisible,
  dropdownIcon = true,
  onVisibleChange,
  overlay
}) => {
  return (
    <div>
      <button className={classNames(styles.button, styles.mobile)} disabled={isDisabled} onClick={onVisibleChange}>
        {selectedValueLabel && <span className={styles.buttonValue}>{selectedValueLabel}</span>}
        {!selectedValueLabel && placeholder && (
          <span className={classNames(styles.placeholder, className)}>{placeholder}</span>
        )}
        {dropdownIcon ? (
          !loading ? (
            <FontAwesomeIcon
              icon={faCaretDown}
              className={classNames(styles.buttonIcon, styles.mobile, {
                [styles.isActive]: isMenuVisible
              })}
            />
          ) : (
            <Spinner className={classNames(styles.buttonIcon, styles.spinner)} />
          )
        ) : null}
      </button>
      <FullscreenOverlay
        visible={isMenuVisible}
        onVisibleChange={onVisibleChange}
        overlayContent={overlay}
        overlayTitle={label}
      />
    </div>
  );
};

export default PlainDropDownMobile;
