import { FieldPolicy } from '@apollo/client';
import { mergeUniqueArray } from 'src/lib/API/graphql/helpers';
import { FeatureSetCached } from './types';

//[C] Non-paginated read
export function getFeatureSetsAsAdminPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['nameLike', 'ids']],
    merge(existing: FeatureSetCached | undefined, incoming: FeatureSetCached | undefined, options) {
      const merged = mergeUniqueArray(existing?.featureSets, incoming?.featureSets, options);

      return {
        ...existing,
        ...incoming,
        featureSets: merged
      };
    }
  };
}
