import React, { createContext, FC, ReactNode, useCallback, useContext, useState } from 'react';
import { CatKey, DEFAULT_INFO_KEY } from 'src/models/procurements/Tender/mappers';

interface CatMobileContextType {
  openCat: CatKey | undefined | null;
  toggleOpenCat: (cat: CatKey) => void;
}

const CatMobileContext = createContext<CatMobileContextType | undefined>(undefined);

interface CatMobileProviderProps {
  children: ReactNode;
}

export const CatMobileProvider: FC<CatMobileProviderProps> = ({ children }) => {
  const [openCat, setOpenCat] = useState<CatKey | null>(DEFAULT_INFO_KEY);

  const toggleOpenCat = useCallback((cat: CatKey): void => {
    setOpenCat(prevCat => (prevCat === cat ? null : cat));
  }, []);

  return <CatMobileContext.Provider value={{ openCat, toggleOpenCat }}>{children}</CatMobileContext.Provider>;
};

export const useCatMobileContext = (): CatMobileContextType => {
  const context = useContext(CatMobileContext);

  if (!context) {
    throw new Error('useCatMobileContext must be used within an CatMobileProvider');
  }
  return context;
};
