import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'src/common';
import styles from './index.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import InviteUsersForm from './InviteUsersForm';

interface IProps {
  onHide: () => void;
}

export const InviteUsersModal: FC<IProps> = props => {
  const { onHide } = props;
  const { t } = useTranslation();

  const onCloseModal = useCallback(() => {
    onHide();
    setIsSuccess(false);
  }, [onHide]);

  const [isSuccess, setIsSuccess] = useState(false);

  const onBackToInvite = useCallback(() => {
    setIsSuccess(false);
  }, []);

  const onSuccess = useCallback(() => {
    setIsSuccess(true);
  }, []);

  return (
    <>
      <Modal
        title={t('Users.addUsers')}
        visible
        className={styles.modal}
        onCancel={onCloseModal}
        footer={null}
        keyboard={false}
        width={690}
      >
        {isSuccess ? (
          <div className={styles.content}>
            <span className={styles.successPic}>
              <FontAwesomeIcon icon={faCheck} className={styles.successIcon} />
            </span>
            <h3 className={classNames(styles.title, styles.successTitle)}>{t('Common.requestSent')}</h3>
            <p className={styles.desc}>{t('Users.checkInviteEmails')}</p>
            <div className={styles.buttons}>
              <Button type={'text'} onClick={onCloseModal}>
                {t('Common.close')}
              </Button>
              <Button type={'primary'} onClick={onBackToInvite}>
                {t('Users.inviteMorePeople')}
              </Button>
            </div>
          </div>
        ) : (
          <InviteUsersForm onClose={onCloseModal} onSuccess={onSuccess} />
        )}
      </Modal>
    </>
  );
};

export default InviteUsersModal;
