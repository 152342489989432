import { safeCallSegment } from '../helpers';

interface ITrackInviteUser {
  email: string;
  name: string | null;
  lastName: string | null;
}

export function trackUserInvite(payload: ITrackInviteUser): void {
  safeCallSegment(() => {
    analytics.track('Company Invite', payload);
  });
}

export function trackMultipleUsersInvite(payload: ITrackInviteUser[]): void {
  safeCallSegment(() => {
    analytics.track('Company Invite Multiple Users:', payload);
  });
}

export function trackUserInviteByAdmin(payload: ITrackInviteUser): void {
  safeCallSegment(() => {
    analytics.track('Admin Invite', payload);
  });
}

export function trackUserInviteByCompanyAdmin(payload: ITrackInviteUser): void {
  safeCallSegment(() => {
    analytics.track('Company Admin Invite', payload);
  });
}
