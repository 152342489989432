import { Schema, UserOwnership } from '@tendium/prom-types';
import { FeatureSet } from '../FeatureSet';
import dayjs from 'dayjs';

export class FeatureRole {
  readonly id: string;
  readonly name: string;
  readonly featureSets: FeatureSet[];
  readonly description: string | undefined;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly type: Schema.FeatureAccess.FeatureRoleType;
  readonly levels: Schema.FeatureAccess.Level[] | undefined;
  readonly ownership: UserOwnership[] | undefined;
  readonly active: boolean;
  readonly primaryProducts: Schema.FeatureAccess.PrimaryProduct[] | undefined;
  readonly hubspotProductId: string | undefined;

  constructor(private readonly data: Schema.FeatureAccess.FeatureRole) {
    this.id = this.data.id;
    this.name = this.data.name;
    this.featureSets = this.toFeatureSets();
    this.description = this.data.description;
    this.createdAt = dayjs(this.data.createdAt).format('YYYY-MM-DD');
    this.updatedAt = dayjs(this.data.updatedAt).format('YYYY-MM-DD');
    this.type = this.data.type;
    this.levels = this.data.levels;
    this.ownership = this.data.ownership;
    this.active = this.data.active;
    this.primaryProducts = this.data.primaryProducts;
    this.hubspotProductId = this.data.hubspotProductId;
  }

  private toFeatureSets(): FeatureSet[] {
    return this.data.featureSets?.map(set => new FeatureSet(set)) ?? [];
  }
}
