import {
  CREATE_LICENSES_AS_ADMIN,
  DELETE_LICENSES_AS_ADMIN,
  GET_LICENSES,
  GET_LICENSES_AS_ADMIN,
  UPDATE_LICENSES_AS_ADMIN
} from './queries';
import { MutationUpdaterFn, useMutation, useQuery } from '@apollo/client';
import { isString, useQueryParams } from 'src/helpers';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { Schema } from '@tendium/prom-types';
import { notification } from 'src/common';
import { LicenseAdminData, LicenseData, LicenseSearchParams } from './types';
import { License } from '.';
import { useParams } from 'react-router';
import { ownership, Ownerships } from 'src/models/ownership';
import { getLoadingStatesForQuery } from 'src/lib/API/graphql/helpers';

export function useLicensesAsAdmin(skip?: boolean): LicenseAdminData {
  const qp = useQueryParams();
  const nameLike = isString(qp[LicenseSearchParams.search]) ? qp[LicenseSearchParams.search] : undefined;
  const { companyId } = useParams<{ companyId?: string }>();

  const query = useQuery<
    { getLicensesAsAdmin: { licenses: Schema.FeatureAccess.License[]; total: number } },
    Schema.FeatureAccess.GetLicensesAsAdminInput
  >(GET_LICENSES_AS_ADMIN, {
    variables: { ids: undefined, nameLike: nameLike, offset: 0, amount: 30, companyIds: [companyId ?? ''] },
    skip
  });

  return useMemo(
    () => ({
      ...query,
      ...getLoadingStatesForQuery(query),
      data: query.data?.getLicensesAsAdmin.licenses.map(license => new License(license)),
      total: query.data?.getLicensesAsAdmin.total ?? 0
    }),
    [query]
  );
}

export function useLicenses(skip?: boolean): LicenseData {
  // Clira and Byggfakta don't have access to licenses atm and request will trigger an error for them
  const skipBasedOnOwnership = ownership.name !== Ownerships.Tendium;

  const query = useQuery<
    { getLicenses: { licenses: Schema.FeatureAccess.License[]; total: number } },
    Schema.FeatureAccess.GetLicensesInput
  >(GET_LICENSES, {
    variables: { ids: undefined, nameLike: undefined, offset: 0, amount: 30 },
    skip: skip || skipBasedOnOwnership
  });

  return useMemo(
    () => ({
      ...query,
      ...getLoadingStatesForQuery(query),
      data: query.data?.getLicenses.licenses.map(license => new License(license)),
      total: query.data?.getLicenses.total ?? 0
    }),
    [query]
  );
}

export function useCreateLicensesAsAdmin(): [
  (input: Schema.FeatureAccess.CreateLicensesAsAdminInput, onComplete?: () => void) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();

  const [createLicensesAsAdmin, { loading, error }] = useMutation(CREATE_LICENSES_AS_ADMIN);
  const createLicensesAsAdminFn = useCallback(
    (input: Schema.FeatureAccess.CreateLicensesAsAdminInput, onComplete?: () => void) => {
      createLicensesAsAdmin({
        variables: { input },
        update: updateCacheOnCreateLicensesAsAdmin()
      })
        .then(() => {
          onComplete?.();
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [createLicensesAsAdmin, t]
  );
  return [createLicensesAsAdminFn, { loading, error }];
}

function updateCacheOnCreateLicensesAsAdmin(): MutationUpdaterFn<{
  createLicensesAsAdmin: Schema.FeatureAccess.License[];
}> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    cache.evict({ fieldName: 'getLicensesAsAdmin' });
    cache.gc();
  };
}

export function useUpdateLicensesAsAdmin(): [
  (input: Schema.FeatureAccess.UpdateLicensesAsAdminInput, onComplete?: () => void) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();

  const [updateLicensesAsAdmin, { loading, error }] = useMutation(UPDATE_LICENSES_AS_ADMIN);

  const updateLicenseAsAdminFn = useCallback(
    (input: Schema.FeatureAccess.UpdateLicensesAsAdminInput, onComplete?: () => void) => {
      updateLicensesAsAdmin({
        variables: { input }
      })
        .then(() => {
          onComplete?.();
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [updateLicensesAsAdmin, t]
  );
  return [updateLicenseAsAdminFn, { loading, error }];
}

export function useDeleteLicensesAsAdmin(): [
  (input: Schema.FeatureAccess.DeleteLicensesAsAdminInput, onComplete?: () => void) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const [deleteLicensesAsAdmin, { loading, error }] = useMutation(DELETE_LICENSES_AS_ADMIN);
  const deleteLicensesAsAdminFn = useCallback(
    (input: Schema.FeatureAccess.DeleteLicensesAsAdminInput, onComplete?: () => void) => {
      deleteLicensesAsAdmin({
        variables: { input },
        update: updateCacheOnDeleteLicensesAsAdmin()
      })
        .then(() => {
          onComplete?.();
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [deleteLicensesAsAdmin, t]
  );
  return useMemo(() => [deleteLicensesAsAdminFn, { loading, error }], [deleteLicensesAsAdminFn, error, loading]);
}

function updateCacheOnDeleteLicensesAsAdmin(): MutationUpdaterFn<{
  deleteLicensesAsAdmin: string[];
}> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    cache.evict({ fieldName: 'getLicensesAsAdmin' });
    cache.gc();
  };
}
