import { FieldPolicy } from '@apollo/client';
import { offsetLimitPaginationMerge } from 'src/lib/API/graphql/helpers';
import { BuyerSupplierTransactionHistoryCached } from './types';

//[C] Paginated read
export function getBuyerSupplierTransactionHistoryPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['buyerId', 'sort', ['direction', 'key'], 'supplierFilter']],
    merge(
      existing: BuyerSupplierTransactionHistoryCached | undefined,
      incoming: BuyerSupplierTransactionHistoryCached | undefined,
      options
    ) {
      const merged = offsetLimitPaginationMerge(existing?.suppliers, incoming?.suppliers, options);

      return {
        ...existing,
        ...incoming,
        suppliers: merged
      };
    }
  };
}
