import { gql } from '@apollo/client';
import { LOT_FIELDS, BOX_FIELDS_FIELD, BASE_BOX_FIELDS } from '../queries';
import { USER_NAME_FIELDS } from 'src/models/users/queries';
import { COMMENT_FIELDS } from 'src/models/comments/queries';
import { BID_BOX_FIELDS } from 'src/models/bids/queries';
import { ASSIGNED_TO_FIELDS } from 'src/models/users/AssignedTo/queries';

export const DATA_SOURCE_FIELDS = gql`
  fragment boxDataSourceFields on ProcurementBoxDataSource {
    filePath
    origin
    text
    headline
  }
`;

const TENDER_FILE_ITEM_FIELDS = gql`
  fragment tenderFileItemFields on ProcurementFileItem {
    fileName
    dateUploaded
    targetPath
    fileType
  }
`;

export const CATEGORY_FILE_FIELDS = gql`
  fragment categoryFileFields on ProcurementFileCategory {
    name
    description
    files {
      ...tenderFileItemFields
    }
  }
  ${TENDER_FILE_ITEM_FIELDS}
`;

export const BOX_FIELDS = gql`
  fragment boxFields on TenderBox {
    ...baseBoxFields
    ...boxFieldsField
    requirementStatus
    lots {
      ...lotFields
    }
    comments {
      ...commentFields
    }
    dataSource {
      ...boxDataSourceFields
    }
  }
  ${BASE_BOX_FIELDS}
  ${BOX_FIELDS_FIELD}
  ${LOT_FIELDS}
  ${COMMENT_FIELDS}
  ${DATA_SOURCE_FIELDS}
`;

const FULL_TENDER_FIELDS = gql`
  fragment fullTenderFields on Tender {
    id
    fileCategories {
      ...categoryFileFields
    }
    lots {
      ...lotFields
    }
    general {
      id
      name {
        ...boxFields
      }
      city {
        ...boxFields
      }
      placeOfPerformanceNutsCode {
        ...boxFields
      }
      buyerNutsCode {
        ...boxFields
      }
      postcode {
        ...boxFields
      }
      postalAddress {
        ...boxFields
      }
      buyerBoxes {
        ...boxFields
      }
      contractDurationBoxes {
        ...boxFields
      }
      locationBoxes {
        ...boxFields
      }
      typeOfProcedureBoxes {
        ...boxFields
      }
      contactPersonBoxes {
        ...boxFields
      }
      emailBoxes {
        ...boxFields
      }
      phoneBoxes {
        ...boxFields
      }
      bidValidityBoxes {
        ...boxFields
      }
      linkToProcurerBoxes {
        ...boxFields
      }
      linkForSubmittingTenderBoxes {
        ...boxFields
      }
      linkToTenderDocumentBoxes {
        ...boxFields
      }
      linkToQA {
        ...boxFields
      }
      mainCpvCode {
        ...boxFields
      }
      additionalCpvCode {
        ...boxFields
      }
      contractValueBoxes {
        ...boxFields
      }
      contractRenewalBoxes {
        ...boxFields
      }
      frameworkAgreement {
        ...boxFields
      }
      introduction {
        ...boxFields
      }
      description {
        ...boxFields
      }
      scopeOfContract {
        ...boxFields
      }
      numberOfSuppliersAssignedContract {
        ...boxFields
      }
      referenceNumberBoxes {
        ...boxFields
      }
    }
    boxes {
      ...boxFields
    }
    timeline {
      id
      availableDate {
        ...boxFields
      }
      minimumTimeFrame {
        ...boxFields
      }
      contractStartAndEndStart {
        ...boxFields
      }
      contractStartAndEndEnd {
        ...boxFields
      }
      deadlineOfClarificationQuestions {
        ...boxFields
      }
      expectedPublicationDate {
        ...boxFields
      }
      deadline {
        ...boxFields
      }
      demonstrationStart {
        ...boxFields
      }
      demonstrationEnd {
        ...boxFields
      }
      presentationStart {
        ...boxFields
      }
      presentationEnd {
        ...boxFields
      }
      showingOfObjectsStart {
        ...boxFields
      }
      showingOfObjectsEnd {
        ...boxFields
      }
      dateOfDispatch {
        ...boxFields
      }
    }
    custom {
      ...bidBoxFields
    }
    procurementStatus
    isRead
    isStarred
    assignedTo {
      ...assignedToFields
    }
    comments {
      ...commentFields
    }
    bids {
      id
      workspace {
        id
      }
      status {
        id
      }
      bidStageId
      itemId
      item {
        ... on Tender {
          id
        }
        ... on UserMail {
          mailId
        }
        ... on ManualBidItem {
          id
        }
      }
    }
  }
  ${CATEGORY_FILE_FIELDS}
  ${BOX_FIELDS}
  ${LOT_FIELDS}
  ${COMMENT_FIELDS}
  ${USER_NAME_FIELDS}
  ${BID_BOX_FIELDS}
  ${ASSIGNED_TO_FIELDS}
`;

export const AI_SUMMARIES_FIELDS = gql`
  fragment aiSummariesFields on Summaries {
    en
    fi
    sv
    no
  }
`;

export const AI_TITLES_FIELDS = gql`
  fragment aiTitlesFields on Titles {
    en
    fi
    sv
    no
  }
`;

export const GET_TENDER_BID = gql`
  query checkTender($id: String!) {
    getTender(query: { id: $id }) {
      id
      bids {
        id
      }
    }
  }
`;

export const GET_FULL_TENDER = gql`
  query getTender($id: String!, $includeSummary: Boolean!) {
    getTender(query: { id: $id }) {
      ...fullTenderFields
      summaries @include(if: $includeSummary) {
        ...aiSummariesFields
      }
      titles @include(if: $includeSummary) {
        ...aiTitlesFields
      }
    }
  }
  ${FULL_TENDER_FIELDS}
  ${AI_SUMMARIES_FIELDS}
  ${AI_TITLES_FIELDS}
`;

export const GET_UPDATED_TENDER = gql`
  query getUpdTender($id: String!, $bidId: String!, $includeSummary: Boolean!) {
    getUpdatedTender(query: { id: $id, bidId: $bidId }) {
      ...fullTenderFields
      summaries @include(if: $includeSummary) {
        ...aiSummariesFields
      }
      titles @include(if: $includeSummary) {
        ...aiTitlesFields
      }
    }
  }
  ${FULL_TENDER_FIELDS}
  ${AI_SUMMARIES_FIELDS}
  ${AI_TITLES_FIELDS}
`;

export const REMOVE_TENDER_BOX = gql`
  mutation removeTenderBox($id: String!, $bidId: String!, $box: TenderBoxToRemoveInput!) {
    removeTenderBox(input: { id: $id, bidId: $bidId, box: $box })
  }
`;

export const ADD_TENDER_BOX = gql`
  mutation addTenderBox($id: String!, $bidId: String!, $box: AddCustomTenderBoxInput!) {
    addTenderBox(input: { id: $id, bidId: $bidId, box: $box }) {
      ...boxFields
    }
  }
  ${BOX_FIELDS}
`;

export const GET_TENDER_FILE = gql`
  query getFile($procurementId: String!, $targetPath: String!, $skipConversion: Boolean!) {
    getFile(query: { procurementId: $procurementId, targetPath: $targetPath, skipConversion: $skipConversion }) {
      url
      conversionResult
    }
  }
`;

export const GET_ZIP = gql`
  query getZip($procurementId: String!, $fileName: String) {
    getZip(procurementId: $procurementId, fileName: $fileName)
  }
`;
