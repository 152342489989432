import React, { FC, useCallback } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from 'src/common';
import { toHSLColor } from 'src/helpers/styles';
import { Paths } from 'src/pages/paths';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangle } from '@fortawesome/pro-solid-svg-icons';
import { MpShort } from 'src/models/matchingProfiles/hooks';
import { trackSwitchSavedSearch } from 'src/segment/events';
import useHandleMobileSidebarState from 'src/helpers/mobile/useHandleMobileSidebar';
import { useIsMobile } from 'src/reactiveVars';

interface Props {
  mp: MpShort;
  sidebarIsOpen?: boolean;
}

export const SavedSearchItem: FC<Props> = ({ mp, sidebarIsOpen }) => {
  const { id, name } = mp;
  // Using location instead of useParams because of nested routes
  const location = useLocation();
  const { toggleMobileSidebarVisible } = useHandleMobileSidebarState();
  const [isMobile] = useIsMobile();

  const onClick = useCallback(() => {
    toggleMobileSidebarVisible(false);
    trackSwitchSavedSearch({ id, name });
  }, [id, name, toggleMobileSidebarVisible]);

  return (
    <Link
      className={classNames(
        styles.menuItem,
        {
          [styles.isActive]: location.pathname.includes(id)
        },
        { [styles.isClosed]: !sidebarIsOpen },
        { [styles.mobile]: isMobile }
      )}
      tabIndex={0}
      to={`${Paths.ANALYTICS_PAGE}${Paths.EXPIRING_CONTRACTS_ROUTE}/${id}`}
      onClick={onClick}
    >
      <Tooltip
        title={mp.name}
        placement={'right'}
        className={classNames(styles.tooltip, { [styles.isIconOnly]: !sidebarIsOpen })}
      >
        <FontAwesomeIcon icon={faTriangle} color={toHSLColor(id)} size="xs" />
        {sidebarIsOpen && (
          <span
            className={classNames(styles.itemLabel, {
              [styles.itemLabelMobile]: isMobile
            })}
          >
            {name}
          </span>
        )}
      </Tooltip>
    </Link>
  );
};

export default SavedSearchItem;
