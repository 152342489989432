import isObject from 'src/helpers/isObject';
import { Assignment } from '../AssignedTo/types';
import { AssignmentType, IApiUser, IUserName } from '../types';

export type Team = Assignment & {
  members: IUserName[];
  createdBy: IUserName;
};
export function isTeam(u: unknown): u is Team {
  return isObject(u) && 'type' in u && u.type === AssignmentType.Team;
}
export interface ApiTeam {
  id: string;
  name: string;
  color: string | null;
  members: ApiTeamMember[];
  createdBy: IApiUser;
}
export interface ApiTeamMember {
  id: string;
  name: string | null;
  lastName: string | null;
  color: string | null;
  enabled?: boolean;
}

export interface CreateTeamInput {
  name: string;
  color?: string;
  members: string[];
}
export interface CreateTeamResponse {
  __typename: 'Mutation';
  createTeam: ApiTeam;
}
export interface UpdateTeamInput {
  teamId: string;
  name?: string;
  color?: string;
  members?: string[];
}
export interface UpdateTeamResponse {
  __typename: 'Mutation';
  updateTeam: ApiTeam;
}
export interface DeleteTeamInput {
  teamId: string;
}
export interface DeleteTeamResponse {
  __typename: 'Mutation';
  deleteTeam: string;
}
export interface LeaveTeamInput {
  teamId: string;
}
export interface LeaveTeamResponse {
  __typename: 'Mutation';
  leaveTeam: ApiTeam;
}
