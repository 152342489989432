import { AwardStatus, SearchLogic } from '@tendium/prom-types';

export interface IApiOrganization {
  organisationName: string | null;
  organisationNumber: string;
}

export interface IApiGetBuyersData {
  findOrganisationsByNameOrId: IApiOrganization[];
}
export interface IApiGetBuyersVars {
  resultOffset?: number;
  resultLimit?: number;
  searchPhrase: string;
}

export interface OrganisationTableData {
  key: string;
  value: string | null;
  status?: AwardStatus;
  type: SearchLogic;
}

export interface CategorizedOrganisations {
  previouslyAdded: OrganisationTableData[];
  lastAdded: OrganisationTableData[];
  notFound: OrganisationTableData[];
}

export enum OrgType {
  buyer = 'buyer',
  supplier = 'supplier',
  company = 'company'
}

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;
/** allow 8- or 10-digits string comma separated, orgId length */
export const ORG_ID_BULK_MODE_REGEX = /^(\d{10}[ ,]|\d{8}[ ,]).*$/;
