import { useEffect } from 'react';

let lockCount = 0; // Tracks the number of active overlays

/**
 * Hook to manage body scroll lock based on visibility.
 * Handles multiple stacked overlays.
 * @param isVisible - Whether the overlay is visible.
 */
const useBodyScrollLock = (isVisible: boolean): void => {
  useEffect(() => {
    if (isVisible) {
      lockCount++;
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (isVisible) {
        lockCount--;

        // Ensure lockCount can't be less than 0
        if (lockCount < 0) lockCount = 0;

        if (lockCount === 0) {
          document.body.style.overflow = ''; // Only remove scroll lock if all overlays have been unmounted
        }
      }
    };
  }, [isVisible]);
};

export default useBodyScrollLock;
