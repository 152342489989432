import { useMemo, useCallback } from 'react';
import { useIsMobile, useMobileSidebarState } from 'src/reactiveVars';

type MobileSidebarControls = {
  mobileSidebarState: boolean;
  toggleMobileSidebarVisible: (flag?: boolean) => void;
};

function useHandleMobileSidebarState(): MobileSidebarControls {
  const [isMobile] = useIsMobile();
  const [mobileSidebarState, setMobileSidebarState] = useMobileSidebarState();

  const toggleMobileSidebarVisible = useCallback(
    (flag?: boolean) => {
      if (isMobile) setMobileSidebarState(flag ?? !mobileSidebarState);
    },
    [isMobile, mobileSidebarState, setMobileSidebarState]
  );

  return useMemo(() => {
    return { mobileSidebarState, toggleMobileSidebarVisible };
  }, [mobileSidebarState, toggleMobileSidebarVisible]);
}

export default useHandleMobileSidebarState;
