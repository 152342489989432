import React, { FC } from 'react';
import styles from '../Box/index.module.scss';
import { BlockLabel, BlockEmpty } from 'src/shared/Blocks';
import LotsSups from 'src/shared/LotsSups';
import BoxActions from '../BoxActions';
import { IBoxCustomProps } from '../types';
import BoxField from '../BoxField';
import { InfoIcon } from 'src/common';
import { useTranslation } from 'react-i18next';
import BoxDeleteAction from '../BoxDeleteAction';
import BoxComments from '../BoxComments';
import { useBoxProps } from '../hooks';
import { Translations } from 'src/lib/I18nService/types';
import { useIsMobile } from 'src/reactiveVars';
import classNames from 'classnames';

export const BoxCustom: FC = () => {
  const {
    box,
    title = true,
    isFirstField,
    isEditable,
    config,
    isInfo,
    actions = true,
    editable,
    field: externalField
  } = useBoxProps() as IBoxCustomProps;
  const { t } = useTranslation();
  const { t: tenderT } = useTranslation(Translations.procurements);
  const { title: defaultTitle, lots, firstField, rawFields } = box;
  const [isMobile] = useIsMobile();

  const currentField = isFirstField ? firstField : externalField;
  return (
    <>
      {title && (
        <div className={styles.header}>
          <div className={styles.title}>
            <BlockLabel label={title === true ? tenderT(defaultTitle) : title} />
            {!!lots?.length && <LotsSups lots={lots} />}
            {isInfo && <InfoIcon desc={t('DetailsPage.additionAvailable')} />}
            {!!actions && !isMobile && (
              <>
                {actions}
                <BoxActions box={box} />
              </>
            )}
          </div>
          {editable && <BoxDeleteAction box={box} />}
        </div>
      )}
      <div
        className={classNames(styles.content, {
          [styles.contentMobile]: isMobile
        })}
      >
        {isFirstField || currentField ? (
          <>
            {currentField ? (
              <BoxField box={box} field={currentField} isEditable={!!isEditable} config={config} />
            ) : (
              <BlockEmpty />
            )}
          </>
        ) : rawFields && rawFields.length ? (
          rawFields.map(field => (
            <BoxField box={box} field={field} isEditable={!!isEditable} key={field.id} config={config} />
          ))
        ) : (
          <BlockEmpty />
        )}
        <BoxComments box={box} />
      </div>
    </>
  );
};

export default BoxCustom;
