import { gql } from '@apollo/client';
import { TEAM_FIELDS } from 'src/models/users/Team/queries';

export const FETCH_EMPLOYEES = gql`
  query getEmployees {
    getCompany {
      id
      personel {
        ...CompanyUserFields
      }
      createdBy {
        ...CompanyUserFields
      }
      teams {
        ...teamFields
      }
    }
  }
  ${TEAM_FIELDS}
`;

export const CHANGE_EMPLOYEE_ACTIVE_STATUS = gql`
  mutation updateUser($email: String!, $enabled: Boolean!) {
    updateUser(input: { email: $email, enabled: $enabled }) {
      email
      enabled
    }
  }
`;

export const ADD_EMPLOYEE = gql`
  mutation createUser(
    $email: String!
    $name: String
    $lastName: String
    $inviteSubUser: Boolean!
    $companyId: String
    $licenseIds: [String!]
    $password: String
  ) {
    createUser(
      input: {
        email: $email
        name: $name
        lastName: $lastName
        inviteSubUser: $inviteSubUser
        companyId: $companyId
        licenseIds: $licenseIds
        password: $password
      }
    ) {
      ...CompanyUserFields
    }
  }
`;

export const SEND_INVITE_TO_COMPANY = gql`
  mutation sendInviteToCompany($email: String!) {
    createInviteCompanyUserRequest(input: { email: $email }) {
      id
      email
      invitationReSent
    }
  }
`;

export const ADD_MULTIPLE_EMPLOYEES = gql`
  mutation createMultipleUsers($users: [CreateUserInput!]!) {
    createMultipleUsers(input: $users) {
      ...CompanyUserFields
    }
  }
`;
