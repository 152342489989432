import { useLocation } from 'react-router';
import { useQueryParams } from '.';
import { Paths } from 'src/pages/paths';
import { isString } from 'lodash';
import { StorageKey } from 'src/types/enums';

export function useIsTenderFreePage(): boolean {
  const location = useLocation();
  const qp = useQueryParams();
  const from = sessionStorage.getItem(StorageKey.FromLogin);

  const tenderRoute = Paths.TENDER_ROUTE.slice(0, -1); // /tender/ --> /tender
  const authSearchParamString = '?auth=';

  // Needed for free user sign in
  if (from) return from.includes(`${tenderRoute}${authSearchParamString}`);

  const isPathTenderRoute = location.pathname === tenderRoute;
  if (!isPathTenderRoute) return false;

  const authQP = qp['auth'];

  return isString(authQP) && location.search.startsWith(authSearchParamString);
}
