import { useMutation, MutationTuple, MutationUpdaterFn } from '@apollo/client';
import { useNavigationState } from 'src/helpers/history';
import { useMemo, useCallback } from 'react';
import { Paths } from 'src/pages/paths';
import { useLocation } from 'react-router-dom';
import { ActivePreviewTab, ITendersCached } from './types';
import { DEFAULT_TENDERS_VIEW, DEFAULT_TENDERS_TAB, DEFAULT_INBOX_TAB, ApiTendersSearchInput } from './Tenders/types';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import {
  MARK_AS_REJECTED,
  MARK_TENDER_IS_STARRED,
  MARK_TENDER_IS_UNSTARRED,
  UPDATE_TENDERS,
  UPDATE_TENDER
} from './queries';
import { useTranslation } from 'react-i18next';
import { notification } from 'src/common';
import { PageView, PreviewDescriptionSetting } from '../users/Preferences/types';
import { usePagePreferences } from '../users/Preferences/hooks';
import { useCloseSidebar, useOpenSidebar } from 'src/shared/InfoSidebar/hooks';
import { IApiUser } from '../users/types';
import { trackStarProcurement, trackUnstarProcurement } from 'src/segment/events';
import { StorageKey } from 'src/types/enums';
import { FeatureFlag, useFeatureFlag } from 'src/helpers';
import { PreviewDescriptionEventSource, trackUpdatePreviewDescription } from 'src/segment/events/preview';

interface IUpdateIsReadTendersResponse {
  __typename: 'Mutation';
  updateTenders: {
    __typename: 'Tender';
    id: string;
    isRead: boolean;
  }[];
}
interface IUpdateIsReadTendersRequest {
  tenderIds: string[];
  isRead: boolean;
}
interface IUpdateIsReadTenderRequestData extends IUpdateIsReadTendersRequest {
  onFinish: () => void;
}
export function useUpdateIsReadTenders(): [
  (data: IUpdateIsReadTenderRequestData) => void,
  { loading: boolean; error: Error | undefined }
] {
  const { t } = useTranslation();
  const [updateTenders, { loading, error }] = useMutation<IUpdateIsReadTendersResponse, IUpdateIsReadTendersRequest>(
    UPDATE_TENDERS
  );

  const updateTendersFn = useCallback(
    (data: IUpdateIsReadTenderRequestData) => {
      const { tenderIds, isRead, onFinish } = data;

      updateTenders({
        variables: { tenderIds, isRead },
        update: getUpdateCacheOnUpdateIsReadTenders(tenderIds, isRead)
      })
        .then(() => onFinish())
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },

    [t, updateTenders]
  );

  return [updateTendersFn, { loading, error }];
}
function getUpdateCacheOnUpdateIsReadTenders(
  tenderIds: readonly string[],
  isRead: boolean
): MutationUpdaterFn<IUpdateIsReadTendersResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    tenderIds.forEach(tenderId => {
      const tenderRef = cache.identify({
        __typename: 'OCDSTender',
        id: tenderId
      });
      cache.modify({
        id: tenderRef,
        fields: {
          isRead() {
            return isRead;
          }
        }
      });
    });
  };
}

interface IUpdateTenderResponse {
  __typename: 'Mutation';
  updateTender: {
    __typename: 'Tender';
    assignedTo: IApiUser | null;
    isRead: boolean;
  };
}
interface IUpdateTenderRequest {
  tenderId: string;
  assignedTo?: string | null;
  isRead?: boolean;
}
export function useUpdateTender(): MutationTuple<IUpdateTenderResponse, IUpdateTenderRequest> {
  return useMutation(UPDATE_TENDER);
}
export function getUpdateCacheOnAssignTender(
  id: string,
  assignedId: string | null
): MutationUpdaterFn<IUpdateTenderResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }

    const tenderRef = cache.identify({
      __typename: 'OCDSTender',
      id
    });

    if (assignedId) {
      const userRef = cache.identify({
        __typename: 'AssignedTo',
        id: assignedId
      });
      cache.modify({
        id: tenderRef,
        fields: {
          assignedTo() {
            return { __ref: userRef };
          }
        }
      });
      cache.modify({
        fields: {
          getSimpleTenders(cached: ITendersCached, { storeFieldName }) {
            if (!storeFieldName.includes('assignedTo')) return cached;
            return {
              ...cached,
              count: cached.count + 1,
              procurementsWithScore: [
                ...cached.procurementsWithScore,
                { __typename: 'ProcurementWithScore', procurement: { __ref: tenderRef } }
              ]
            };
          }
        }
      });
    } else {
      cache.modify({
        id: tenderRef,
        fields: {
          assignedTo() {
            return null;
          }
        }
      });

      cache.modify({
        fields: {
          getSimpleTenders(cached: ITendersCached, { storeFieldName, readField }) {
            if (!storeFieldName.includes('assignedTo')) return cached;
            return {
              ...cached,
              count: cached.count - 1,
              procurementsWithScore: [...cached.procurementsWithScore].filter(
                proc => proc && readField({ fieldName: 'id', from: proc.procurement }) !== id
              )
            };
          }
        }
      });
    }
  };
}

export function getUpdateCacheOnUpdateIsReadTender(
  tenderId: string,
  isRead: boolean
): MutationUpdaterFn<IUpdateTenderResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const tenderRef = cache.identify({
      __typename: 'OCDSTender',
      id: tenderId
    });
    cache.modify({
      id: tenderRef,
      fields: {
        isRead() {
          return isRead;
        }
      }
    });
  };
}

interface IStarUnstarTendersResponse {
  id: string;
  isStarred: boolean;
}
export function useUpdateIsStarredTender(): [
  (tenderId: string, tenderName: string, isStarred: boolean, eventSource: string) => void,
  { loading: boolean; error?: Error }
] {
  const { t } = useTranslation();
  const [setTenderIsStarred, { loading: isStarredUpdating, error: isStarredError }] = useMutation<
    { addTenderToStarred: IStarUnstarTendersResponse },
    { tenderId: string }
  >(MARK_TENDER_IS_STARRED);
  const [setTenderIsUnStarred, { loading: isUnStarredUpdating, error: isUnStarredError }] = useMutation<
    { removeTenderFromStarred: IStarUnstarTendersResponse },
    { tenderId: string }
  >(MARK_TENDER_IS_UNSTARRED);

  const updateStarredFn = useCallback(
    (tenderId: string, tenderName: string, isStarred: boolean, eventSource: string) => {
      if (isStarred) {
        trackUnstarProcurement({ id: tenderId, name: tenderName }, eventSource);
        setTenderIsUnStarred({
          variables: { tenderId },
          update: getUpdateCacheOnMarkTenderIsUnStarred(tenderId)
        }).catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
      } else {
        trackStarProcurement({ id: tenderId, name: tenderName }, eventSource);
        setTenderIsStarred({
          variables: { tenderId },
          update: getUpdateCacheOnUpdateIsStarredTender(tenderId)
        }).catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
      }
    },
    [setTenderIsUnStarred, t, setTenderIsStarred]
  );
  return [
    updateStarredFn,
    { loading: isStarredUpdating || isUnStarredUpdating, error: isStarredError || isUnStarredError }
  ];
}

function getUpdateCacheOnUpdateIsStarredTender(
  tenderId: string
): MutationUpdaterFn<{ addTenderToStarred: IStarUnstarTendersResponse }> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const tenderRef = cache.identify({
      __typename: 'OCDSTender',
      id: tenderId
    });
    cache.modify({
      id: tenderRef,
      fields: {
        isStarred() {
          return true;
        }
      }
    });
    cache.modify({
      fields: {
        getSimpleTenders(cached: ITendersCached, { storeFieldName }) {
          if (!storeFieldName.includes('isStarred')) return cached;
          return {
            ...cached,
            count: cached.count + 1,
            procurementsWithScore: [
              ...cached.procurementsWithScore,
              { __typename: 'ProcurementWithScore', procurement: { __ref: tenderRef } }
            ]
          };
        }
      }
    });
  };
}

function getUpdateCacheOnMarkTenderIsUnStarred(
  tenderId: string
): MutationUpdaterFn<{ removeTenderFromStarred: IStarUnstarTendersResponse }> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const tenderRef = cache.identify({
      __typename: 'OCDSTender',
      id: tenderId
    });
    cache.modify({
      id: tenderRef,
      fields: {
        isStarred() {
          return false;
        }
      }
    });
    cache.modify({
      fields: {
        getSimpleTenders(cached: ITendersCached, { storeFieldName, readField }) {
          if (!storeFieldName.includes('isStarred')) return cached;
          return {
            ...cached,
            count: cached.count - 1,
            procurementsWithScore: [...cached.procurementsWithScore].filter(
              proc => proc && readField({ fieldName: 'id', from: proc.procurement }) !== tenderId
            )
          };
        }
      }
    });
  };
}

interface IRejectTendersResponse {
  __typename: 'Mutation';
  rejectProcurements: boolean;
}
export function useMarkAsRejected(): MutationTuple<
  IRejectTendersResponse,
  { matchingProfileId: string; rejected: boolean; procurementIds: string[] }
> {
  return useMutation(MARK_AS_REJECTED);
}
export function getUpdateCacheOnRejectTenders(
  procIds: readonly string[],
  mpId: string,
  rejected: boolean
): MutationUpdaterFn<IRejectTendersResponse> {
  return (cache, { data }) => {
    // backend makes sure no partial success
    if (!data) {
      return;
    }
    const modifyTenderCache = (procId: string, typename: string): void => {
      cache.modify({
        id: cache.identify({
          __typename: typename,
          id: procId
        }),
        fields: {
          rejected() {
            return rejected;
          }
        }
      });
    };

    procIds.forEach(procId => {
      modifyTenderCache(procId, 'Tender');
      modifyTenderCache(procId, 'OCDSTender');
    });

    cache.modify({
      fields: {
        getSimpleTenders(cached: ITendersCached, { storeFieldName, fieldName, readField }) {
          const input: ApiTendersSearchInput = JSON.parse(storeFieldName.replace(`${fieldName}:`, ''));

          if (!input.query) return cached;

          if (input.query.matchingProfileId !== mpId || !input.query.isUnhandled) return cached;

          if (input.query.matchingProfileId === mpId) {
            const procurementsWithScore = cached.procurementsWithScore ?? [];
            if (!rejected) {
              return {
                ...cached,
                count: cached.count + procIds.length,
                procurementsWithScore: [
                  ...procurementsWithScore,
                  ...[
                    procIds.map(procId => {
                      const tenderRef = cache.identify({
                        __typename: 'OCDSTender',
                        id: procId
                      });
                      return { __typename: 'ProcurementWithScore', procurement: { __ref: tenderRef } };
                    })
                  ]
                ]
              };
            } else {
              const idsInCache = procurementsWithScore.map(proc =>
                readField({ fieldName: 'id', from: proc.procurement })
              );
              // filtered out ids that is not in the cache to avoid subtracting same id multiple times
              const filteredIds = procIds.filter(id => idsInCache.includes(id));
              return {
                ...cached,
                count: Math.max(cached.count - filteredIds.length, 0),
                procurementsWithScore: procurementsWithScore.filter(
                  proc =>
                    proc && !procIds.some(procId => procId === readField({ fieldName: 'id', from: proc.procurement }))
                )
              };
            }
          } else {
            return cached;
          }
        }
      }
    });

    cache.modify({
      fields: {
        getTenders(cached: ITendersCached, { storeFieldName, fieldName, readField }) {
          const input: ApiTendersSearchInput = JSON.parse(storeFieldName.replace(`${fieldName}:`, ''));

          if (!input.query) return cached;

          if (input.query.matchingProfileId !== mpId || !input.query.isUnhandled) return cached;

          if (input.query.matchingProfileId === mpId) {
            const procurementsWithScore = cached.procurementsWithScore ?? [];
            if (!rejected) {
              return {
                ...cached,
                count: cached.count + procIds.length,
                procurementsWithScore: [
                  ...procurementsWithScore,
                  ...[
                    procIds.map(procId => {
                      const tenderRef = cache.identify({
                        __typename: 'Tender',
                        id: procId
                      });
                      return { __typename: 'ProcurementWithScore', procurement: { __ref: tenderRef } };
                    })
                  ]
                ]
              };
            } else {
              const idsInCache = procurementsWithScore.map(proc =>
                readField({ fieldName: 'id', from: proc.procurement })
              );
              // filtered out ids that is not in the cache to avoid subtracting same id multiple times
              const filteredIds = procIds.filter(id => idsInCache.includes(id));
              return {
                ...cached,
                count: Math.max(cached.count - filteredIds.length, 0),
                procurementsWithScore: procurementsWithScore.filter(
                  proc =>
                    proc && !procIds.some(procId => procId === readField({ fieldName: 'id', from: proc.procurement }))
                )
              };
            }
          } else {
            return cached;
          }
        }
      }
    });
  };
}

export function usePreviewTabState(): [ActivePreviewTab, (tab: ActivePreviewTab) => void] {
  const [page, updatePage] = usePagePreferences();
  const { pathname } = useLocation();

  const isInbox = pathname.startsWith(Paths.CALL_OFFS);
  const activeTab = useMemo(() => {
    return page?.tab ? (page.tab as ActivePreviewTab) : isInbox ? DEFAULT_INBOX_TAB : DEFAULT_TENDERS_TAB;
  }, [isInbox, page?.tab]);
  const setActiveTab = useCallback(
    (tab: ActivePreviewTab) => {
      if (activeTab !== tab) {
        updatePage({ tab });
      }
    },
    [activeTab, updatePage]
  );

  return useMemo(() => [activeTab, setActiveTab], [activeTab, setActiveTab]);
}

export function usePreviewDescriptionState(): [
  PreviewDescriptionSetting,
  (descSetting: PreviewDescriptionSetting, eventSource: PreviewDescriptionEventSource) => void
] {
  const [page, updatePage] = usePagePreferences();
  const isGPTSummaryFeature = useFeatureFlag(FeatureFlag.GPT_Summary);

  const defaultSetting = isGPTSummaryFeature
    ? PreviewDescriptionSetting.Summary
    : PreviewDescriptionSetting.ShortDescription;

  const activeSetting = useMemo(() => {
    return page?.description ? (page.description as PreviewDescriptionSetting) : defaultSetting;
  }, [defaultSetting, page?.description]);

  const setDescriptionSetting = useCallback(
    (description: PreviewDescriptionSetting, eventSource: PreviewDescriptionEventSource) => {
      if (activeSetting !== description) {
        trackUpdatePreviewDescription(eventSource, description);
        updatePage({ description });
      }
    },
    [activeSetting, updatePage]
  );

  return useMemo(() => [activeSetting, setDescriptionSetting], [activeSetting, setDescriptionSetting]);
}

export function useOpenTenderPreview(): (id?: string, tab?: ActivePreviewTab) => void {
  const onCloseSidebar = useCloseSidebar();
  const onOpenSideBar = useOpenSidebar();

  return useCallback(
    (id?: string) => {
      if (!!id) {
        onOpenSideBar({
          id,
          mode: SidebarMode.PROCUREMENT_INFO
        });
      } else {
        onCloseSidebar();
      }
    },
    [onCloseSidebar, onOpenSideBar]
  );
}

export function useTenderUrl(id: string): string {
  return useMemo(() => `${Paths.TENDER_ROUTE}${id}`, [id]);
}

export function useBidUrl(id: string): string {
  return useMemo(() => {
    return `${Paths.TENDER_ROUTE}${Paths.BIDDING_PAGE.replace(':id', id)}`;
  }, [id]);
}

export function useBidDraftUrl(id: string): string {
  return useMemo(() => {
    return `${Paths.TENDER_ROUTE}${Paths.BIDDING_PAGE.replace(':id', id)}/create-tasks`;
  }, [id]);
}

export function useBidReportUrl(id: string): string {
  return useMemo(() => {
    return `${Paths.BID_REPORT_PAGE.replace(':id', id)}`;
  }, [id]);
}

export function useOverviewBackLink(): {
  backLink: string;
  set: () => void;
  reset: () => void;
} {
  const savedLink = useMemo(() => sessionStorage.getItem(StorageKey.FromOverview), []);
  const currentUrl = useNavigationState();

  const backLink = useMemo(
    () =>
      savedLink
        ? savedLink
        : currentUrl.startsWith(`${Paths.TENDER_ROUTE}${Paths.BIDDING_PAGE.split('/')[0]}`)
        ? Paths.BIDSPACES
        : Paths.MONITORING,
    [currentUrl, savedLink]
  );

  const set = useCallback(() => {
    if (currentUrl) {
      sessionStorage.setItem(StorageKey.FromOverview, currentUrl);
    }
  }, [currentUrl]);

  const reset = useCallback(() => {
    if (savedLink) {
      sessionStorage.removeItem(StorageKey.FromOverview);
    }
  }, [savedLink]);

  return useMemo(() => ({ backLink, set, reset }), [backLink, reset, set]);
}

export function useTendersViewState(): [PageView, (view: PageView) => void] {
  const [page, updatePage] = usePagePreferences();
  const tendersView = useMemo(() => {
    return page?.view ? page.view : DEFAULT_TENDERS_VIEW;
  }, [page]);
  const setTendersView = useCallback(
    (view: PageView) => {
      updatePage({ view });
    },
    [updatePage]
  );
  return useMemo(() => {
    return [tendersView, setTendersView];
  }, [tendersView, setTendersView]);
}
