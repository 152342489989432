import React, { FC } from 'react';
import HeaderAction from '../../HeaderAction';
import { FullscreenOverlay, UserName } from 'src/common';
import { DELETED_USER } from 'src/models/users/types';
import styles from '../index.module.scss';
import { FreeUser, isPaidUser, PaidUser } from 'src/models/auth';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Paths } from 'src/pages/paths';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faCog, faSignOut } from '@fortawesome/pro-light-svg-icons';

interface Props {
  menuVisible: boolean;
  toggleMenu: () => void;
  user: PaidUser | FreeUser;
  logout: () => Promise<void>;
  backHref: string;
}

const UserMenuMobile: FC<Props> = ({ menuVisible, toggleMenu, user, logout, backHref }) => {
  const isPaid = isPaidUser(user);
  const { t } = useTranslation();

  const menuContent = (
    <>
      <div className={classNames(styles.menuItem, styles.menuUser, styles.mobile)}>
        <UserName mode={'onlyAvatar'} user={user} className={styles.menuAvatar} size={'l'} />
        <div className={styles.userInfo}>
          <span>{user.userName}</span>
        </div>
      </div>
      <div className={classNames(styles.menuItem, styles.mobile)}>
        <span className={styles.menuItemTitle}>{t('Common.account')}</span>
        <Link
          to={{ pathname: `${Paths.SETTINGS_PAGE}${Paths.SETTINGS_PAGE_ACCOUNT}` }}
          state={{ from: backHref }}
          className={styles.action}
          onClick={toggleMenu}
        >
          <FontAwesomeIcon icon={faUserCircle} className={styles.actionIcon} />
          <span className={styles.actionTitle}>{t('Common.profile')}</span>
        </Link>
      </div>
      {isPaid && (
        <div className={classNames(styles.menuItem, styles.mobile)}>
          <span className={styles.menuItemTitle}>{user.company.companyName || t('Company.company')}</span>
          <Link
            to={{ pathname: `${Paths.SETTINGS_PAGE}${Paths.SETTINGS_PAGE_COMPANY_INFO}` }}
            state={{ from: backHref }}
            className={styles.action}
            onClick={toggleMenu}
          >
            <FontAwesomeIcon icon={faCog} className={styles.actionIcon} />
            <span className={styles.actionTitle}>{t('Company.company')}</span>
          </Link>
        </div>
      )}
      <div className={classNames(styles.menuItem, styles.mobile)}>
        <button onClick={logout} className={classNames(styles.action, styles.signOutButton)}>
          <FontAwesomeIcon icon={faSignOut} className={styles.actionIcon} />
          <span className={styles.actionTitle}>{t('Header.signOut')}</span>
        </button>
      </div>
    </>
  );

  return (
    <>
      <HeaderAction onClick={toggleMenu}>
        <UserName mode={'onlyAvatar'} user={user ?? DELETED_USER} bordered className={styles.avatar} />
      </HeaderAction>
      <FullscreenOverlay
        visible={menuVisible}
        onVisibleChange={toggleMenu}
        overlayTitle={t('Common.accountAndSettings')}
        overlayContent={menuContent}
      />
    </>
  );
};

export default UserMenuMobile;
