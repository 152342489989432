import { matchPath, useLocation } from 'react-router-dom';
import { mobileCompatibleRoutes } from './constants';
import { useIsTenderFreePage } from '../useIsTenderFreePage';

export function useIsMobileCompatibleRoute(): boolean {
  const location = useLocation();
  const isTenderFreePage = useIsTenderFreePage();

  const checkIsMobileCompatibleRoute = (path: string): boolean => {
    const isMobileCompatibleRoute = mobileCompatibleRoutes.some(route => {
      const match = matchPath(route, path);
      return !!match;
    });

    return isMobileCompatibleRoute || isTenderFreePage;
  };

  const isMobileCompatibleRoute = checkIsMobileCompatibleRoute(location.pathname);

  return isMobileCompatibleRoute;
}
