import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import Button from '../Button';
import { toHSLColor } from 'src/helpers/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AccessControlPolicy } from 'src/models/acl/types';
import { AssignedTo } from 'src/models/users/types';
import ACLUserList from 'src/shared/ACLUserList';
import { useIsMobile } from 'src/reactiveVars';
import classNames from 'classnames';

interface Props {
  title: string;
  id: string;
  onClick: (id: string) => void;
  cardInfo?: ReactNode;
  icon?: IconProp;
  iconColor?: string;
  wsBadge?: ReactNode;
  owners?: AssignedTo[];
  subscribers?: AssignedTo[];
  subscribersPolicy?: AccessControlPolicy;
}
export const NavCard: FC<Props> = props => {
  const { title, cardInfo, id, onClick, icon, wsBadge, owners, subscribers, subscribersPolicy, iconColor } = props;
  const [isMobile] = useIsMobile();

  return (
    <Button
      key={id}
      className={classNames(styles.button, {
        [styles.mobile]: isMobile
      })}
      onClick={() => onClick(id)}
    >
      <div
        className={classNames(styles.navCard, {
          [styles.mobile]: isMobile
        })}
      >
        <div
          className={classNames(styles.cardHeader, {
            [styles.mobile]: isMobile
          })}
        >
          {!!icon && <FontAwesomeIcon icon={icon} size={'lg'} color={iconColor ?? toHSLColor(id)} />}
          {!!wsBadge && <span className={styles.wsBadge}>{wsBadge}</span>}
          <span className={styles.cardTitle}>{title}</span>
        </div>
        <div className={styles.cardInfo}>{cardInfo}</div>
        <ACLUserList owners={owners} members={subscribers} accessControlPolicy={subscribersPolicy} size={'s'} />
      </div>
    </Button>
  );
};

export default NavCard;
