import { User } from '..';
import { IUserName, AssignmentType } from '../types';
import { ApiTeamMember, isTeam, type ApiTeam, type Team as TeamType } from './types';

export class Team implements TeamType {
  public readonly id: string;
  public readonly name: string;
  public readonly color: string | null;
  public readonly type: AssignmentType;
  public readonly members: IUserName[];
  public readonly createdBy: IUserName;

  constructor(apiTeam: ApiTeam) {
    this.id = apiTeam.id;
    this.name = apiTeam.name;
    this.color = apiTeam.color;
    this.createdBy = new User(apiTeam.createdBy);
    this.type = AssignmentType.Team;
    this.members = apiTeam.members.map((member: ApiTeamMember) => {
      const { id, ...rest } = member;
      return new User({ ...rest, email: id });
    });
  }
}

export { isTeam };
