import { FieldPolicy, Reference } from '@apollo/client';
import { notUndefined } from 'src/helpers/typescript';
import { mergeUniqueArray } from 'src/lib/API/graphql/helpers';

//[C] Non-paginated read
export function getOrgsPolicy(): FieldPolicy {
  return {
    keyArgs: ['searchPhrase'],
    merge: (existing: Reference[] | undefined, incoming: Reference[] | undefined, options) => {
      return mergeUniqueArray(existing, incoming, options, 'organisationNumber');
    }
  };
}

export function getOrgsByIdPolicy(): FieldPolicy {
  return {
    keyArgs: ['organisationIds'],
    read(existedOrgs: Reference[], { args, toReference, canRead }) {
      const cachedOrgs: Reference[] =
        args && Array.isArray(args.organisationIds)
          ? args.organisationIds
              .map((id: string) => toReference({ __typename: 'Organisation', organisationNumber: id }))
              .filter(ref => canRead(ref))
              .filter(notUndefined)
          : existedOrgs;
      return cachedOrgs.length !== args?.organisationIds.length ? existedOrgs : cachedOrgs;
    }
  };
}
