import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './index.module.scss';
import { IIcon } from 'src/types/icon';
import classNames from 'classnames';

interface IProps {
  title: string;
  icon?: IIcon;
  iconWidth?: number;
  className?: string;
}
export const BlockHeading: FC<IProps> = ({ title, icon, className, iconWidth }) => {
  return (
    <h2 className={classNames(styles.title, !!className && className)}>
      {icon && icon.defaultIcon && (
        <FontAwesomeIcon icon={icon.defaultIcon} color={icon.color} className={styles.icon} width={iconWidth} />
      )}
      <span>{title}</span>
    </h2>
  );
};

export default BlockHeading;
