import { EnterpriseBrand } from '@tendium/prom-types';
import { OWNERSHIPS, Ownerships, OwnershipLinks, BRANDS, BrandConfig } from './types';

export class Ownership {
  public readonly name: Ownerships;
  public readonly qrCodeTitle: string;
  public readonly links: OwnershipLinks;
  public readonly isTendium: boolean;
  public readonly metaTitle: string;
  public readonly theme: BrandConfig;
  public readonly brandName: Ownerships | EnterpriseBrand;
  public readonly samlConfig: string | undefined;

  constructor() {
    this.name =
      Object.values(Ownerships).find(val => {
        return OWNERSHIPS[val]?.domain === window.location.origin;
      }) ?? Ownerships.Tendium;

    this.metaTitle = this.name === Ownerships.Byggfakta ? 'Tender Public' : this.name;

    const ownership = OWNERSHIPS[this.name];

    this.qrCodeTitle = ownership.qrCodeTitle ?? this.name;
    this.links = ownership.links;
    this.isTendium = this.name === Ownerships.Tendium;

    this.brandName = this.getBrandName();
    this.theme = BRANDS[this.brandName];
    this.samlConfig = BRANDS[this.brandName].samlConfig;
  }

  // Currently we only support Enterprise branding for Tendium owned domains
  private getBrandName(): Ownerships | EnterpriseBrand {
    if (this.name === Ownerships.Clira) {
      return Ownerships.Clira;
    }
    if (this.name === Ownerships.Byggfakta) {
      return Ownerships.Byggfakta;
    }

    return (
      Object.values(EnterpriseBrand).find(val => {
        return BRANDS[val]?.enterpriseDomain === window.location.origin;
      }) ?? Ownerships.Tendium
    );
  }
}

export * from './types';
export const ownership = new Ownership();
