import { FEATURE_ROLE_FIELDS } from '../FeatureRole/queries';
import { COMPANY_FIELDS } from 'src/models/company/queries';
import { gql } from '@apollo/client';

export const LICENSE_FIELDS = gql`
  fragment licenseFields on License {
    id
    createdAt
    updatedAt
    name
    description
    company {
      ...CompanyFields
    }
    featureRoles {
      ...featureRoleFields
    }
    seats
    price
    currency
    usedSeats
  }
  ${FEATURE_ROLE_FIELDS}
  ${COMPANY_FIELDS}
`;

export const GET_LICENSES_AS_ADMIN = gql`
  query getLicensesAsAdmin($amount: Int, $ids: [String!], $nameLike: String, $offset: Int, $companyIds: [String!]) {
    getLicensesAsAdmin(
      input: { amount: $amount, ids: $ids, nameLike: $nameLike, offset: $offset, companyIds: $companyIds }
    ) {
      licenses {
        ...licenseFields
      }
      total
    }
  }
  ${LICENSE_FIELDS}
`;

export const GET_LICENSES = gql`
  query getLicenses($amount: Int, $ids: [String!], $nameLike: String, $offset: Int) {
    getLicenses(input: { amount: $amount, ids: $ids, nameLike: $nameLike, offset: $offset }) {
      licenses {
        ...licenseFields
      }
      total
    }
  }
  ${LICENSE_FIELDS}
`;

export const UPDATE_LICENSES_AS_ADMIN = gql`
  mutation updateLicensesAsAdmin($input: UpdateLicensesAsAdminInput!) {
    updateLicensesAsAdmin(input: $input) {
      ...licenseFields
    }
  }
  ${LICENSE_FIELDS}
`;

export const CREATE_LICENSES_AS_ADMIN = gql`
  mutation createLicensesAsAdmin($input: CreateLicensesAsAdminInput!) {
    createLicensesAsAdmin(input: $input) {
      ...licenseFields
    }
  }
  ${LICENSE_FIELDS}
`;

export const DELETE_LICENSES_AS_ADMIN = gql`
  mutation deleteLicensesAsAdmin($input: DeleteLicensesAsAdminInput!) {
    deleteLicensesAsAdmin(input: $input) {
      deletedIds
    }
  }
`;
