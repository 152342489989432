import { Schema } from '@tendium/prom-types';
import { FeatureRole } from '../FeatureRole';
import dayjs from 'dayjs';

export class License {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly description: string | undefined;
  readonly company: Schema.Company | undefined;
  readonly featureRoles: FeatureRole[];
  readonly seats: number;
  readonly usedSeats: number;
  readonly price: number | undefined;
  readonly currency: string | undefined;

  constructor(private readonly data: Schema.FeatureAccess.License) {
    this.id = this.data.id;
    this.createdAt = dayjs(this.data.createdAt).format('YYYY-MM-DD');
    this.updatedAt = dayjs(this.data.updatedAt).format('YYYY-MM-DD');
    this.name = this.data.name;
    this.description = this.data.description;
    this.company = this.data.company;
    this.featureRoles = this.toFeatureRoles();
    this.seats = this.data.seats;
    this.usedSeats = this.data.usedSeats;
    this.price = this.data.price;
    this.currency = this.data.currency;
  }

  private toFeatureRoles(): FeatureRole[] {
    return this.data.featureRoles?.map(role => new FeatureRole(role)) ?? [];
  }
}
