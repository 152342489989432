import React, { FC, useMemo } from 'react';
import ReactMarkdown from 'src/common/Markdown';
import styles from './index.module.scss';
import { AITitles, Summaries } from 'src/models/procurements/Preview/types';
import { getAITitleByLanguageSetting, getSummaryByLanguageSetting } from 'src/models/procurements/Preview/helpers';
import { useLanguage } from 'src/common';
import { useIsMobile } from 'src/reactiveVars';

interface Props {
  summaries?: Summaries;
  titles?: AITitles;
  showEnglish?: boolean;
}

export const TenderSummary: FC<Props> = ({ summaries, titles, showEnglish }) => {
  const { language } = useLanguage();
  const summary = useMemo(() => {
    if (showEnglish && summaries?.en) {
      return summaries.en;
    }
    return getSummaryByLanguageSetting(language, summaries);
  }, [language, showEnglish, summaries]);
  const [isMobile] = useIsMobile();

  const title = useMemo(() => {
    if (showEnglish && titles?.en) {
      return titles.en;
    }
    return getAITitleByLanguageSetting(language, titles);
  }, [language, showEnglish, titles]);

  if (!summary) return null;

  return (
    <div className={isMobile ? undefined : styles.summary}>
      {title && <div className={styles.title}>{title}</div>}
      <ReactMarkdown>{summary}</ReactMarkdown>
    </div>
  );
};

export default TenderSummary;
