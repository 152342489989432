import { gql } from '@apollo/client';

export const FEATURE_ROLE_FIELDS = gql`
  fragment featureRoleFields on FeatureRoleEntity {
    id
    name
    description
    createdAt
    updatedAt
    featureSets {
      id
      name
      description
      createdAt
      updatedAt
      featureFlags
      active
    }
    type
    levels
    ownership
    active
    primaryProducts
    hubspotProductId
  }
`;

export const GET_FEATURE_ROLES_AS_ADMIN = gql`
  query getFeatureRolesAsAdmin(
    $amount: Int
    $ids: [String!]
    $nameLike: String
    $offset: Int
    $primaryProducts: [MainProduct!]
    $types: [FeatureRoleType!]
    $levels: [FeatureRoleLevel!]
    $ownerships: [UserOwnership!]
  ) {
    getFeatureRolesAsAdmin(
      input: {
        amount: $amount
        ids: $ids
        nameLike: $nameLike
        offset: $offset
        primaryProducts: $primaryProducts
        types: $types
        levels: $levels
        ownerships: $ownerships
      }
    ) {
      featureRoles {
        ...featureRoleFields
      }
      total
    }
  }
  ${FEATURE_ROLE_FIELDS}
`;

export const UPDATE_FEATURE_ROLES_AS_ADMIN = gql`
  mutation updateFeatureRolesAsAdmin($input: UpdateFeatureRolesAsAdminInput!) {
    updateFeatureRolesAsAdmin(input: $input) {
      ...featureRoleFields
    }
  }
  ${FEATURE_ROLE_FIELDS}
`;

export const CREATE_FEATURE_ROLES_AS_ADMIN = gql`
  mutation createFeatureRolesAsAdmin($input: CreateFeatureRolesAsAdminInput!) {
    createFeatureRolesAsAdmin(input: $input) {
      ...featureRoleFields
    }
  }
  ${FEATURE_ROLE_FIELDS}
`;

export const DELETE_FEATURE_ROLES_AS_ADMIN = gql`
  mutation deleteFeatureRolesAsAdmin($input: DeleteFeatureRolesAsAdminInput!) {
    deleteFeatureRolesAsAdmin(input: $input)
  }
`;
