import {
  BidTasksGroup,
  IBiddingResponse,
  TasksFilterArgs,
  ApiTasksVars,
  ApiTasksResponse,
  GROUP_TASKS_LIMIT,
  TasksResponseData,
  BiddingStatsResponse,
  BiddingStatsCached,
  UseLoadTasks,
  TasksCachedResult,
  BidQuestionType
} from './types';
import {
  MutationUpdaterFn,
  useMutation,
  useQuery,
  ApolloError,
  Reference,
  QueryResult,
  useApolloClient
} from '@apollo/client';
import {
  GET_BIDDING,
  GENERATE_TASKS,
  CREATE_BID_GROUP,
  UPDATE_BID_GROUP,
  DELETE_BID_GROUP,
  GET_GROUP_TASKS,
  GET_BIDDING_STATS
} from './queries';
import { useMemo, useContext, useCallback } from 'react';
import {
  BiddingContext,
  BiddingContextValue,
  GroupTasksContext,
  GroupTasksFilterContext,
  TasksState,
  UpdateGroupTasksFilterContext
} from './context';
import { useTranslation } from 'react-i18next';
import { notification } from 'src/common';
import { TasksData } from '.';
import { scrollToItem } from 'src/helpers/scrollToElement';
import { useCreateBidTasks } from '../BidTask/hooks';
import { CreateBidTaskValues } from '../BidTask/types';
import { TaskTrackCreateData, trackBTDeleteGroup, trackTaskCreate } from 'src/segment/events';
import { isTasksFilterApplied } from './helpers';
import { isNotUndefined, isString, updateQueryParams } from 'src/helpers';
import { useParams } from 'react-router';
import { usePagePreferences } from 'src/models/users/Preferences/hooks';
import { getLoadingStatesForQuery } from 'src/lib/API/graphql/helpers';

interface IBiddingRequest {
  bidId?: string;
  filters?: TasksFilterArgs;
}
export function useApiBidding(bidId?: string): QueryResult<IBiddingResponse, IBiddingRequest> {
  return useQuery<IBiddingResponse, IBiddingRequest>(GET_BIDDING, {
    variables: {
      bidId
    },
    skip: !bidId
  });
}
export function useApiBiddingStats(bidId?: string): QueryResult<BiddingStatsResponse, IBiddingRequest> {
  const { filter: filters } = useTasksFilter();

  return useQuery<BiddingStatsResponse, IBiddingRequest>(GET_BIDDING_STATS, {
    variables: {
      bidId,
      filters: isTasksFilterApplied(filters) ? filters : undefined
    },
    skip: !bidId,
    fetchPolicy: 'network-only'
  });
}
export function useBidding(): BiddingContextValue {
  return useContext(BiddingContext);
}

export function useBiddingGroup(id: string): BidTasksGroup | undefined {
  const { data } = useBidding();

  return useMemo(() => data?.questionGroups.find(group => group.id === id), [data, id]);
}

interface IGenerateBidQuestionsResponse {
  generateBidQuestions: BidTasksGroup[];
}
interface IGenerateBidQuestionsInput {
  bidId: string;
  selectedFiles: string[];
}

interface IGenerateGroupFnInput extends Omit<TaskTrackCreateData, 'bidId'>, IGenerateBidQuestionsInput {
  onComplete?: () => void;
}

export function useGenerateBidGroups(): [
  (data: IGenerateGroupFnInput) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [generateGroups, { loading, error }] = useMutation<IGenerateBidQuestionsResponse, IGenerateBidQuestionsInput>(
    GENERATE_TASKS
  );
  const [, setOpenTaskGroup] = useOpenTaskGroupPref();

  const generateGroupFn = useCallback(
    async ({ bidId, selectedFiles, eventSource, mode, onComplete }: IGenerateGroupFnInput) => {
      return generateGroups({
        variables: { bidId, selectedFiles },
        update: updateCacheOnGenerateBidGroups(bidId)
      })
        .then(({ data }) => {
          if (data) {
            setOpenTaskGroup(data.generateBidQuestions[data.generateBidQuestions.length - 1].id);
            trackTaskCreate({
              bidId,
              eventSource,
              mode
            });
          }
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        })
        .finally(() => {
          onComplete && onComplete();
        });
    },

    [generateGroups, setOpenTaskGroup, t]
  );

  return useMemo(() => [generateGroupFn, { loading, error }], [error, generateGroupFn, loading]);
}
function updateCacheOnGenerateBidGroups(bidId: string): MutationUpdaterFn<IGenerateBidQuestionsResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }
    const bidRef = cache.identify({
      __typename: 'BidV2',
      id: bidId
    });

    cache.modify({
      id: bidRef,
      fields: {
        questionGroups(cachedGroups: Reference[], { readField, toReference }) {
          const groups = [...cachedGroups].length
            ? data.generateBidQuestions.filter(
                group =>
                  ![...cachedGroups].some(cachedGroup => readField({ fieldName: 'id', from: cachedGroup }) === group.id)
              )
            : data.generateBidQuestions;
          return [
            ...groups.reverse().map(group =>
              toReference({
                __typename: 'ProcurementBidQuestionGroup',
                id: group.id
              })
            ),
            ...cachedGroups
          ];
        }
      }
    });
    cache.modify({
      fields: {
        getQuestionGroupsStatistics(cached: BiddingStatsCached, { DELETE }) {
          return cached.variables.bidId === bidId ? DELETE : cached;
        }
      }
    });
    cache.gc();
  };
}

interface CreateBidGroupResponse {
  createBidQuestionGroup: BidTasksGroup;
}
interface CreateBidGroupInput {
  bidId: string;
  title: string;
}
type CreateBidGroupData = CreateBidGroupInput &
  Pick<TaskTrackCreateData, 'eventSource' | 'mode'> & {
    task?: CreateBidTaskValues;
    onComplete?: () => void;
  };
export function useCreateBidGroup(): [(input: CreateBidGroupData) => void, { loading: boolean; error?: ApolloError }] {
  const { t } = useTranslation();
  const [createGroup, { loading, error }] = useMutation<CreateBidGroupResponse, CreateBidGroupInput>(CREATE_BID_GROUP);
  const [createTask] = useCreateBidTasks();
  const [, setOpenTaskGroup] = useOpenTaskGroupPref();

  const createGroupFn = useCallback(
    (input: CreateBidGroupData) => {
      const { bidId, title, onComplete, eventSource, mode } = input;
      createGroup({
        variables: { bidId, title },
        update: updateCacheOnCreateBidGroup(bidId)
      })
        .then(({ data }) => {
          if (data) {
            const questionGroupId = data.createBidQuestionGroup.id;
            createTask({ bidId, questionGroupId, onComplete, eventSource, mode, tasks: [''] });
            setTimeout(() => scrollToItem(questionGroupId), 0);
            setOpenTaskGroup(data.createBidQuestionGroup.id);
          }
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [createGroup, createTask, setOpenTaskGroup, t]
  );
  return useMemo(() => [createGroupFn, { loading, error }], [createGroupFn, error, loading]);
}

export function updateCacheOnCreateBidGroup(bidId: string): MutationUpdaterFn<CreateBidGroupResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }
    const bidRef = cache.identify({
      __typename: 'BidV2',
      id: bidId
    });

    const groupId = data.createBidQuestionGroup.id;

    cache.modify({
      id: bidRef,
      fields: {
        questionGroups(cachedGroups: Reference[], { readField, toReference }) {
          return [...cachedGroups].some(ref => readField('id', ref) === groupId)
            ? cachedGroups
            : [toReference({ __typename: 'ProcurementBidQuestionGroup', id: groupId }), ...cachedGroups];
        }
      }
    });
  };
}

interface IUpdateBidQuestionGroupResponse {
  updateBidQuestionGroup: BidTasksGroup;
}

interface IUpdateBidQuestionGroupInput {
  bidId: string;
  groupId: string;
  title?: string;
}

export function useUpdateBidGroup(): [
  (data: IUpdateBidQuestionGroupInput) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const [updateGroup, { loading, error }] = useMutation<IUpdateBidQuestionGroupResponse, IUpdateBidQuestionGroupInput>(
    UPDATE_BID_GROUP
  );
  const updateGroupFn = useCallback(
    (data: IUpdateBidQuestionGroupInput) => {
      updateGroup({
        variables: data,
        update: updateCacheOnUpdateGroup(data.groupId)
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [t, updateGroup]
  );
  return useMemo(() => [updateGroupFn, { loading, error }], [updateGroupFn, error, loading]);
}

export function updateCacheOnUpdateGroup(groupId: string): MutationUpdaterFn<IUpdateBidQuestionGroupResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    const groupRef = cache.identify({
      __typename: 'ProcurementBidQuestionGroup',
      id: groupId
    });
    cache.modify({
      id: groupRef,
      fields: {
        title() {
          return data.updateBidQuestionGroup.title;
        }
      }
    });
  };
}

type DeleteBidGroupData = {
  deleteBidQuestionGroup: boolean;
};
type DeleteBidGroupVars = {
  groupId: string;
};
type DeleteBidGroupInput = DeleteBidGroupVars & {
  bidId: string;
};
type UseDeleteBidGroup = [(input: DeleteBidGroupInput) => void, { loading: boolean; error?: ApolloError }];
export function useDeleteBidGroup(): UseDeleteBidGroup {
  const { t } = useTranslation();
  const [deleteGroup, { loading, error }] = useMutation<DeleteBidGroupData, DeleteBidGroupVars>(DELETE_BID_GROUP);
  const deleteGroupFn = useCallback(
    (input: DeleteBidGroupInput) => {
      const { bidId, groupId } = input;
      trackBTDeleteGroup(groupId, bidId);
      deleteGroup({
        variables: { groupId },
        update: updateCacheOnDeleteBidGroup({ bidId, groupId })
      }).catch(() => {
        notification.error({
          description: t('Common.unknownErrorDesc'),
          message: t('Common.unknownError')
        });
      });
    },
    [deleteGroup, t]
  );
  return useMemo(() => [deleteGroupFn, { loading, error }], [deleteGroupFn, error, loading]);
}

export function updateCacheOnDeleteBidGroup({
  bidId,
  groupId
}: DeleteBidGroupInput): MutationUpdaterFn<DeleteBidGroupData> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }
    const bidRef = cache.identify({
      __typename: 'BidV2',
      id: bidId
    });
    cache.modify({
      id: bidRef,
      fields: {
        questionGroups(cachedGroups: Reference[], { readField }) {
          return [...cachedGroups].filter(group => readField('id', group) !== groupId);
        }
      }
    });
    let classificationIds: string[] = [];

    cache.modify({
      fields: {
        getQuestionGroupsStatistics(cached: BiddingStatsCached, { readField }) {
          if (cached.variables?.bidId === bidId) {
            return { ...cached, value: cached.value.filter(stat => readField('groupId', stat) !== groupId) };
          } else {
            return cached;
          }
        },
        getGroupQuestions(cached: TasksCachedResult, { readField, DELETE }) {
          if (cached.variables?.groupId === groupId) {
            if (cached.variables.type === BidQuestionType.Manual && cached.variables.isSubmitted === false) {
              classificationIds = [...cached.value.questions]
                .map(taskRef => readField({ fieldName: 'classificationId', from: taskRef }))
                .filter(isString)
                .filter(isNotUndefined);
            }
            return DELETE;
          } else {
            return cached;
          }
        }
      }
    });

    !!classificationIds.length &&
      classificationIds.forEach(classificationId => {
        cache.modify({
          id: cache.identify({ classificationId, __typename: 'DocumentCoordinates' }),
          fields: {
            createdTask() {
              return false;
            }
          }
        });
      });
    cache.evict({ id: cache.identify({ id: groupId, __typename: 'ProcurementBidQuestionGroup' }) });
    cache.evict({ id: cache.identify({ groupId, __typename: 'QuestionGroupStatistics' }) });
    cache.gc();
  };
}

export function useTasksFilter(): {
  filter?: TasksFilterArgs;
  updateFilter?: <T extends keyof TasksFilterArgs>(key: T, value: TasksFilterArgs[T]) => void;
  resetFilter?: () => void;
} {
  const filter = useContext(GroupTasksFilterContext);
  const setFilter = useContext(UpdateGroupTasksFilterContext);
  const { cache } = useApolloClient();
  const { id: bidId } = useParams<{ id: string }>();
  const updateFilter = useCallback(
    <T extends keyof TasksFilterArgs>(key: T, value: TasksFilterArgs[T]) => {
      setFilter?.(prevFilters => {
        const { [key]: updatedProp, ...restProps } = prevFilters || {};
        if (!value?.length) return restProps;
        else {
          cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'getQuestionGroupsStatistics',
            args: { input: { bidId, filters: prevFilters } }
          });
          cache.gc();

          return {
            ...restProps,
            [key]: value.sort()
          };
        }
      });
      updateQueryParams({ [key]: value });
    },
    [bidId, cache, setFilter]
  );

  const resetFilter = useCallback(() => {
    setFilter?.(() => undefined);
    updateQueryParams({
      assignedTo: null,
      status: null,
      fulfillment: null,
      requirementType: null
    });
  }, [setFilter]);

  return useMemo(() => ({ filter, updateFilter, resetFilter }), [filter, updateFilter, resetFilter]);
}

export function useTasks(): TasksState {
  const ctx = useContext(GroupTasksContext);
  if (!ctx) {
    throw new Error('not in GroupTasksContext provider');
  }

  return ctx;
}

export function useOpenTaskGroupPref(): [string | undefined | null, (groupId?: string | null) => void] {
  const [page, updatePage] = usePagePreferences();

  const openTaskGroupId = useMemo(() => {
    return page?.openTaskGroup ? page.openTaskGroup : undefined;
  }, [page?.openTaskGroup]);

  const setOpenTaskGroup = useCallback(
    (groupId?: string | null) => {
      if (!groupId) {
        updatePage({ openTaskGroup: null });
      }
      if (groupId !== openTaskGroupId) {
        updatePage({ openTaskGroup: groupId });
      }
    },
    [openTaskGroupId, updatePage]
  );

  return useMemo(() => [openTaskGroupId, setOpenTaskGroup], [openTaskGroupId, setOpenTaskGroup]);
}

export function useLoadTasks({
  groupId,
  type,
  isSubmitted,
  limit = GROUP_TASKS_LIMIT,
  skip
}: UseLoadTasks): TasksResponseData {
  const { filter: filters } = useTasksFilter();
  const query = useQuery<ApiTasksResponse, ApiTasksVars>(GET_GROUP_TASKS, {
    variables: {
      groupId,
      offset: 0,
      limit,
      filters: isTasksFilterApplied(filters) ? filters : undefined,
      type,
      isSubmitted
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: skip ?? !groupId,
    // FIXME: @remove after backend fix "Cannot read properties of undefined (reading 'classificationId')"
    errorPolicy: 'ignore'
  });

  return useMemo(
    () => ({
      ...query,
      ...getLoadingStatesForQuery(query),
      data: query.data?.getGroupQuestions ? new TasksData(query.data?.getGroupQuestions) : undefined
    }),
    [query]
  );
}
