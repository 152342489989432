import { makeVar } from '@apollo/client';
import { ReactiveVariable, useReactiveVariable } from 'src/helpers/reactiveVariables';

export const isMobileVar = makeVar<boolean>(false);

const updateIsMobile = (): void => {
  isMobileVar(window.innerWidth <= 768);
};

if (typeof window !== 'undefined') {
  window.addEventListener('resize', updateIsMobile);
  updateIsMobile();
}

export function useIsMobile(): ReactiveVariable<boolean> {
  return useReactiveVariable<boolean>(isMobileVar);
}

export default useIsMobile;
