import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '../Alert';
import classNames from 'classnames';
import styles from './index.module.scss';
import { useIsMobile } from 'src/reactiveVars';
import { useIsMobileCompatibleRoute } from 'src/helpers/mobile/useIsMobileCompatibleRoute';

interface Props {
  useTopMargin?: boolean;
}

const BrokenMobilePagesBanner: FC<Props> = ({ useTopMargin }) => {
  const { t } = useTranslation();
  const [isMobile] = useIsMobile();
  const isMobileCompatibleRoute = useIsMobileCompatibleRoute();

  if (!isMobile) return null;
  if (isMobileCompatibleRoute) return null;

  return (
    <Alert
      message={t('Common.mobileBanner')}
      type="info"
      showIcon
      className={classNames(styles.mobileBanner, {
        [styles.useTopMargin]: !!useTopMargin
      })}
    />
  );
};
export default BrokenMobilePagesBanner;
