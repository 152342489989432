import React from 'react';
import { Checkbox } from 'src/common';
import styles from './index.module.scss';
import { User } from 'src/models/users';
import { License } from 'src/models/admin/License';

interface Props {
  users: User[];
  licenses: License[];
  onCheck: (userIndex: number, licenseId: string, checked: boolean) => void;
}

const AddLicensesForm: React.FC<Props> = ({ users, licenses, onCheck }) => {
  return (
    <div className={styles.table}>
      <div className={styles.tableHeader}>
        <div className={styles.tableCell}>Email</div>
        {licenses.map(license => (
          <div key={license.id} className={styles.tableCell}>
            {license.name}
          </div>
        ))}
      </div>
      {users.map((user, userIndex) => (
        <div key={user.email} className={styles.tableRow}>
          <div className={styles.tableCell}>{user.email}</div>
          {licenses.map(license => (
            <div key={license.id} className={styles.tableCell}>
              <Checkbox onChange={e => onCheck(userIndex, license.id, e.target.checked)} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AddLicensesForm;
