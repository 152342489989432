import { JSX, ReactNode } from 'react';
import { ChartTooltipProps } from './hooks';
import { NumberValue, ScaleOrdinal } from 'd3-scale';
import { TickFormatter } from '@visx/axis';
import { LabelFormatter } from '@visx/legend/lib/types';
import { ParentSizeProvidedProps } from '@visx/responsive/lib/components/ParentSize';

export type ChartColorScale = ScaleOrdinal<string, string>;
export type ChartLabelFormatter = LabelFormatter<string>;
export type ChartLabelRenderer = (
  label: { datum: string; index: number; text: string; value?: string | undefined },
  reactNode: ReactNode
) => ReactNode;
export type ChartMargin = { top: number; right: number; bottom: number; left: number };

export type LegendProps = {
  colorScale: ChartColorScale;
  labelFormat?: ChartLabelFormatter;
  renderLabel?: ChartLabelRenderer;
  vertical?: boolean;
  truncate?: boolean;
  tooltip?: boolean;
  setFade?: (key?: string) => boolean;
  onMouseEnter?: (key?: string) => void;
  onMouseLeave?: () => void;
};

export type XYChartProps = {
  width: number;
  height?: number;
  xDomain: string[];
  yDomain: [number, number];
  colorScale: ChartColorScale;
  margin?: ChartMargin;
  formatX?: TickFormatter<string>;
  formatY?: TickFormatter<NumberValue>;
  formatLegend?: ChartLabelFormatter;
  renderLegend?: ChartLabelRenderer;
  children?: ReactNode;
  hideLegend?: boolean;
};

export type BaseChartProps<T> = {
  data: T[];
  getLegendKey: (d: T) => string;
  getXValue: (d: T) => string;
  getYValue: (d: T) => number;
  onMouseEnter?: (d: ChartTooltipProps<T>, i?: number) => void;
  onMouseLeave?: () => void;
  onClick?: (d: T) => void;
};

export type BarStackGroupProps<T> = BaseChartProps<T> & {
  maxBarWidth?: number;
  minBarHeight?: number;
  barStackSorter?: (data: T[]) => T[];
  getSelectedColor?: (key: string) => string | undefined;
  onClick?: (d: T, isSelect: boolean) => void;
};

export type AverageLineProps<T> = Pick<BaseChartProps<T>, 'data' | 'getYValue'> & {
  legendKey: string;
};

export type DateRangeProps<T> = Pick<BaseChartProps<T>, 'data' | 'onMouseEnter' | 'onMouseLeave' | 'onClick'> & {
  getDateRanges: (dt: T) => [string, string];
};

export type DonutProps<T> = Pick<BaseChartProps<T>, 'data' | 'onMouseEnter' | 'onMouseLeave' | 'onClick'> & {
  getKey: (d: T) => string;
  getValue: (d: T) => number;
  outerRadius: number;
  innerRadius: number;
  cornerRadius?: number;
  padAngle?: number;
  innerValue?: (d?: T) => JSX.Element;
  colorScale?: ChartColorScale;
  activeKey?: string;
};

export interface ChartContainerProps {
  title?: ReactNode;
  desc?: string;
  loading?: boolean;
  error?: boolean;
  className?: string;
  emptyStateClassName?: string;
  empty?: boolean;
  children: (args: ParentSizeProvidedProps) => ReactNode;
  emptyStateText?: string;
}

export type PieChartProps<T> = Pick<DonutProps<T>, 'data' | 'getKey' | 'getValue'> & {
  className?: string;
  title: string;
  desc?: string;
  loading?: boolean;
  error?: boolean;
  formatLegend?: ChartLabelFormatter;
  renderLegend?: ChartLabelRenderer;
  innerText?: string;
};

export type PieChartSize = {
  outerRadius: number;
  innerRadius: number;
  fontSize: number;
  lineHeight: number;
  secondaryFontSize: number;
  secondaryLineHeight: number;
  textSpacing: number;
};

export const PIE_CHART_SIZE: PieChartSize = {
  outerRadius: 141,
  innerRadius: 141 - 50,
  fontSize: 16,
  lineHeight: 24,
  secondaryFontSize: 14,
  secondaryLineHeight: 22,
  textSpacing: 6
};

export const CHART_TEXT_COLOR = '#81818F';
export const CHART_DEFAULT_MARGIN_BOTTOM = {
  large: 82,
  medium: 75,
  small: 58
};
export const CHART_DEFAULT_MARGIN: ChartMargin = {
  top: 20,
  left: 60,
  right: 60,
  bottom: CHART_DEFAULT_MARGIN_BOTTOM.medium
};
export const CHART_COLOR_PALETTE = [
  '#85A5FF',
  '#95DE64',
  '#722ED1',
  '#5CDBD3',
  '#FF9C6E',
  '#B37FEB',
  '#FF85C0',
  '#006D75',
  '#AD6800',
  '#237804',
  '#FAAD14',
  '#A8071A'
];

export const BAR_OPACITY_BLUR = '30%';
export const BAR_RADIUS = 4;
export const BAR_MIN_HEIGHT = 6;
export const BAR_MAX_WIDTH = 134;
export const BAR_SELECTED_STROKE_WIDTH = 2;
export const SPACE_BETWEEN_BARS = 2;

export const LINE_STROKE_WIDTH = 3;
export const LINE_STROKE_DASH_ARRAY = '5,5';

export const CIRCLE_RADIUS = 6;
export const CIRCLE_BORDER = 2;
export const SPACE_BETWEEN_CIRCLES = 3;

export const GRID_COLOR = '#DBDBDD';

export const DATE_RANGE_LINE_COLOR = '#FA941C';
export const DATE_RANGE_LINE_CIRCLE_COLOR = '#5357EA';

export const DATE_RANGE_BAR_COLOR = ['#FA8C16', '#FFD591'];
export const DATE_RANGE_BAR_HEIGHT = 20;

export const DONUT_COLOR_PALETTE = ['#FF85C0', '#95DE64', '#722ED1', '#5CDBD3', '#FF9C6E', '#85A5FF'];
export const DONUT_CORNER_RADIUS = 4;
export const DONUT_OPACITY_BLUR = '30%';

export const PIE_TEXT_COLOR = '#0A0A0A';
export const PIE_SECONDARY_TEXT_COLOR = '#737373';

export const XYCHART_DEFAULT_HEIGHT = 340;
