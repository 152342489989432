import { FieldPolicy, Reference } from '@apollo/client';
import { mergeUniqueArray } from 'src/lib/API/graphql/helpers';
import { CommentsCountCached, ParentCommentsCached } from './types';

//[C] Non-paginated read with filter
export function getCommentsCountPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['parentType']],
    read(existing: CommentsCountCached) {
      return existing?.value ?? undefined;
    },
    merge(existing: CommentsCountCached | undefined, incoming: Reference[] | undefined, options) {
      const { variables } = options;
      const merged = mergeUniqueArray(existing?.value, incoming, options, 'parentId');
      return {
        value: merged,
        variables
      };
    }
  };
}

//[C] Non-paginated read with filter
export function getParentCommentsPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['parentId', 'parentType']],
    read(existing: ParentCommentsCached) {
      return existing?.value ?? undefined;
    },
    merge(existing: ParentCommentsCached | undefined, incoming: Reference[] | undefined, options) {
      const { variables } = options;
      const merged = mergeUniqueArray(existing?.value, incoming, options);
      return {
        value: merged,
        variables
      };
    }
  };
}
