import { FieldPolicy } from '@apollo/client';
import { mergeUniqueArray } from 'src/lib/API/graphql/helpers';
import { AdminUsersCached, AdminUsersDataCached } from './types';

//[C] Non-paginated read with filter
export function getAdminUsersPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['searchOptions', ['companyId', 'email'], 'sortBy']],
    read(existing: AdminUsersDataCached | undefined) {
      return existing?.value ?? undefined;
    },
    merge(existing: AdminUsersDataCached | undefined, incoming: AdminUsersCached | undefined, options) {
      const { variables } = options;
      const uniqueUsers = mergeUniqueArray(existing?.value.users, incoming?.users, options, 'email');
      return {
        value: {
          ...existing,
          ...incoming,
          users: uniqueUsers
        },
        variables
      };
    }
  };
}
