import { FieldPolicy } from '@apollo/client';
import { mergeUniqueArray } from 'src/lib/API/graphql/helpers';
import { FeatureRoleCached } from './types';

//[C] Non-paginated read
export function getFeatureRolesAsAdminPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['nameLike', 'primaryProducts', 'types', 'levels']],
    merge(existing: FeatureRoleCached | undefined, incoming: FeatureRoleCached | undefined, options) {
      const merged = mergeUniqueArray(existing?.featureRoles, incoming?.featureRoles, options);

      return {
        ...existing,
        ...incoming,
        featureRoles: merged
      };
    }
  };
}
