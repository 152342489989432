import { gql } from '@apollo/client';
import { SSO_CONFIG_FIELDS } from 'src/models/company/Security/queries';
import { FEATURE_ROLE_FIELDS } from '../FeatureRole/queries';

export const UPDATE_COMPANY_BASE_PLATE = gql`
  mutation updateBasePlate($companyIds: [String!]!, $basePlateId: String!) {
    updateBasePlate(input: { companyIds: $companyIds, basePlateId: $basePlateId }) {
      id
      basePlate {
        ...featureRoleFields
      }
    }
  }
  ${FEATURE_ROLE_FIELDS}
`;

export const UPDATE_COMPANY_FEATURES = gql`
  mutation updateCompanyFeatures($companies: [CompanyWithFeatureIds!]!) {
    updateCompanyFeatures(input: { companies: $companies }) {
      id
      features {
        ...featureRoleFields
      }
    }
  }
  ${FEATURE_ROLE_FIELDS}
`;

export const UPDATE_COMPANY_ENABLED = gql`
  mutation updateCompanyEnabled($companyId: String!, $enabled: Boolean!) {
    updateCompanyEnabled(input: { companyId: $companyId, enabled: $enabled }) {
      id
      enabled
    }
  }
`;

export const UPDATE_COMPANY_ADMIN = gql`
  mutation updateCompanyAdmin($email: String!, $companyId: String!) {
    updateCompanyAdmin(input: { newAdmin: $email, companyId: $companyId }) {
      id
      personel {
        id
        email
        isAdmin
      }
      createdBy {
        id
        email
        isAdmin
      }
    }
  }
`;

export const GET_COMPANY_INFO = gql`
  query getCompany($companyId: String) {
    getCompany(input: $companyId) {
      ...CompanyFields
      createdOn
      createdBy {
        ...CompanyUserFields
      }
      personel {
        ...CompanyUserFields
      }
      ssoConfiguration {
        ...ssoConfigFields
      }
      competitors {
        orgId
      }
      settings {
        filterKeywordsLimit
        matchingProfilesLimit
        workspaceLimit
      }
    }
  }
  ${SSO_CONFIG_FIELDS}
`;

export const LOAD_COMPANY_NUTS = gql`
  query getCompanyNutsFilter($companyId: String!) {
    getCompanyNutsFilter(companyId: $companyId) {
      id
      isActive
      createDateTime
      nutsCodes
      nutsCodesExact
    }
  }
`;

export const UPDATE_COMPANY_NUTS_CODES = gql`
  mutation updateCompanyNutsFilter(
    $companyId: String!
    $isActive: Boolean
    $nutsCodes: [String!]
    $nutsCodesExact: [String!]
  ) {
    updateNutsFilter(
      companyId: $companyId
      updateParams: { isActive: $isActive, nutsCodes: $nutsCodes, nutsCodesExact: $nutsCodesExact }
    ) {
      id
      isActive
      createDateTime
      nutsCodes
      nutsCodesExact
    }
  }
`;

export const SAVE_COMPANY_SETTINGS = gql`
  mutation saveCompanySettings($companyId: String!, $settings: UpdateCompanySettingsInput!) {
    saveCompanySettings(companyId: $companyId, settings: $settings) {
      id
      settings {
        filterKeywordsLimit
        matchingProfilesLimit
        workspaceLimit
      }
    }
  }
`;

export const GET_COMPANY_MP_COUNT = gql`
  query getCompanyMatchingProfilesCount($input: GetCompanyMatchingProfilesCountInput) {
    getCompanyMatchingProfilesCount(input: $input)
  }
`;

export const GET_COMPANY_WS_COUNT = gql`
  query getCompanyWorkspacesCount {
    getCompanyWorkspacesCount
  }
`;
