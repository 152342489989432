import React, { FC, useMemo, useCallback } from 'react';
import styles from './index.module.scss';
import { useDrop } from 'react-dnd';
import { ITendersDndItem, isTendersDndItem } from 'src/models/procurements/Tenders/types';
import { IBaseWorkspace } from 'src/models/workspace/types';
import classNames from 'classnames';
import WorkspaceBadge from 'src/common/WorkspaceBadge';
import { useOpenTenderPreview } from 'src/models/procurements/hooks';
import { trackSwitchWorkspace, trackMoveItemsToWorkspace } from 'src/segment/events';
import { Link, useParams } from 'react-router-dom';
import { DndType } from 'src/types/dnd';
import {
  IBidsBoardDndItem,
  IBidsTableDndItem,
  isBidsBoardDndItem,
  isBidsTableDndItem,
  IBids
} from 'src/models/bids/Bids/types';
import {
  useCreateBids,
  useUpdateBidsWs,
  getUpdateCacheOnCreateBids,
  getUpdateCacheOnUpdateBidsWs,
  useBidCreationContext
} from 'src/models/bids/Bids/hooks';
import { ICallOffsDndItem, isCallOffsDndItem } from 'src/models/callOffs/types';
import useSelectedTenders from 'src/reactiveVars/SelectedTendersVar';
import useSelectedBids from 'src/reactiveVars/SelectedBidsVar';
import useSelectedCallOffs from 'src/reactiveVars/SelectedCallOffsVar';
import groupBy from 'lodash/groupBy';
import { useUnAssignMailsToBid } from 'src/models/bids/BidsSelector/hooks';
import { useCurrentSidebar } from 'src/shared/InfoSidebar/hooks';
import { SidebarMode } from 'src/shared/InfoSidebar/types';
import { useOpenCallOffPreview } from 'src/models/callOffs/hooks';
import useBidsSearchArgs from 'src/reactiveVars/BidsSearchArgsVar';
import { Paths } from 'src/pages/paths';
import { useTenderActionEventSource } from 'src/segment/hooks';
import useHandleMobileSidebarState from 'src/helpers/mobile/useHandleMobileSidebar';
import { useIsMobile } from 'src/reactiveVars';

interface Props {
  workspace: IBaseWorkspace;
  sidebarIsOpen?: boolean;
}

export const WorkspacesItem: FC<Props> = ({ workspace, sidebarIsOpen }) => {
  const { id, color, name } = workspace;
  const [createBids] = useCreateBids();
  const [updateBidsWs] = useUpdateBidsWs();
  const { wsId, mpId } = useParams<{ wsId: string; mpId: string }>();
  const { bidContext, bidContextId } = useBidCreationContext();
  const [isMobile] = useIsMobile();

  const [unassignMailsToBid] = useUnAssignMailsToBid();
  const [{ rejected: isRejectedTab }] = useBidsSearchArgs();

  const [, setSelectedTenders] = useSelectedTenders();
  const [, setSelectedBids] = useSelectedBids();
  const [, setSelectedCallOffs] = useSelectedCallOffs();
  const currentSidebar = useCurrentSidebar();
  const openTenderPreview = useOpenTenderPreview();
  const openCallOffPreview = useOpenCallOffPreview();
  const eventSource = useTenderActionEventSource();

  const { toggleMobileSidebarVisible } = useHandleMobileSidebarState();

  const addToWorkspace = useCallback(
    async (item: ITendersDndItem | IBidsBoardDndItem | IBidsTableDndItem | ICallOffsDndItem) => {
      const { dndItem } = item;
      const originIds = isTendersDndItem(item) || isCallOffsDndItem(item) ? item.originIds : undefined;

      const bidIds =
        isTendersDndItem(item) || isBidsTableDndItem(item) || isCallOffsDndItem(item)
          ? item.bidIds
          : isBidsBoardDndItem(item)
          ? [{ id: dndItem.id, wsId: (dndItem as IBids).workspace.id }]
          : undefined;

      const linkedBids = isCallOffsDndItem(item) ? item.linkedBids : undefined;

      const originType = isTendersDndItem(item) || isCallOffsDndItem(item) ? item.originType : undefined;

      if (originIds || bidIds) {
        const groups = groupBy(linkedBids, 'id');
        const promises = Object.entries(groups).map(([key, val]) => {
          return unassignMailsToBid({
            variables: { bidId: key, mailIds: val.map(bid => bid.originId) }
          });
        });
        await Promise.all(promises);
        if (originIds && !!originIds.length && originType) {
          await createBids({
            variables: {
              workspaceId: id,
              items: originIds.map(id => ({ id })),
              itemType: originType,
              meta: { bidContext, bidContextId }
            },
            update: getUpdateCacheOnCreateBids(originIds, originType, id, mpId)
          });
        }
        if (bidIds && !!bidIds.length) {
          const bidsIds = bidIds.map(bid => bid.id);
          await updateBidsWs({
            variables: {
              workspaceId: id,
              bidsIds
            },
            update: getUpdateCacheOnUpdateBidsWs(id, bidIds, !!isRejectedTab)
          });
        }
        isTendersDndItem(item) && setSelectedTenders([]);
        isBidsTableDndItem(item) && setSelectedBids([]);
        isCallOffsDndItem(item) && setSelectedCallOffs([]);
        originIds &&
          originType &&
          trackMoveItemsToWorkspace(originIds, originType, id, eventSource, 'Sidebar DnD', mpId);
      }
    },
    [
      setSelectedTenders,
      setSelectedBids,
      setSelectedCallOffs,
      id,
      eventSource,
      mpId,
      unassignMailsToBid,
      createBids,
      bidContext,
      bidContextId,
      updateBidsWs,
      isRejectedTab
    ]
  );

  const canDropWS = useMemo(() => id !== wsId, [id, wsId]);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [DndType.TendersItem, DndType.BidsBoardItem, DndType.BidsTableItem, DndType.CallOffsItem],
    canDrop: () => canDropWS,
    drop: (item: ITendersDndItem | IBidsBoardDndItem | IBidsTableDndItem | ICallOffsDndItem) => {
      addToWorkspace(item)
        .then(() => {
          // FIXME: move SidebarCtx global?
          if (currentSidebar?.mode === SidebarMode.PROCUREMENT_INFO && isTendersDndItem(item)) {
            const { nextId } = item.dndItem;
            openTenderPreview(nextId);
          } else if (currentSidebar?.mode === SidebarMode.CALL_OFF_INFO && isCallOffsDndItem(item)) {
            const { nextCallOff } = item.dndItem;

            openCallOffPreview(nextCallOff);
          }
        })
        .catch(err => console.error(err));
      return;
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      item: monitor.getItem(),
      canDrop: monitor.canDrop()
    })
  });

  const isDropActive = useMemo(() => canDrop && isOver, [canDrop, isOver]);

  const onBidspaceItemClick = useCallback(() => {
    toggleMobileSidebarVisible(false);
    trackSwitchWorkspace({ id });
  }, [id, toggleMobileSidebarVisible]);

  return (
    <Link
      className={classNames(
        styles.menuItem,
        {
          [styles.isActive]: id === wsId,
          [styles.isDropActive]: isDropActive,
          [styles.isDroppable]: canDrop,
          [styles.isNotDroppable]: !canDrop && isOver
        },
        { [styles.isClosed]: !sidebarIsOpen },
        { [styles.mobile]: isMobile }
      )}
      to={`${Paths.BIDSPACES}/${id}`}
      onClick={onBidspaceItemClick}
      ref={drop}
    >
      <WorkspaceBadge
        key={id}
        title={name}
        color={color}
        mode={sidebarIsOpen ? 'full' : 'short'}
        tooltip={true}
        size={'xs'}
        className={styles.wsBadge}
        isHeadingFontSize // larger font size in nav sidebar
      />
    </Link>
  );
};

export default WorkspacesItem;
