import React, { FC, Suspense, useLayoutEffect, useRef } from 'react';
import { Subscription } from 'rxjs';
import getScrollToItem$ from 'src/helpers/scrollToElement';
import styles from './index.module.scss';
import { Header, SidebarContext, TendersNav } from 'src/shared';
import { Outlet } from 'react-router-dom';
import MainSkeleton from '../MainSkeleton';
import { BrokenMobilePagesBanner, Skeleton } from 'src/common';
import { useIsMobile } from 'src/reactiveVars';

interface Props {
  scrollDelay?: number;
}
const MainLayoutComponent: FC<Props> = ({ scrollDelay }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isMobile] = useIsMobile();
  useLayoutEffect(() => {
    let subscription: Subscription;
    if (ref.current) {
      subscription = getScrollToItem$(ref.current, scrollDelay).subscribe();
    } else {
      console.warn('ref is not set');
    }

    return () => {
      subscription.unsubscribe();
    };
  }, [scrollDelay]);

  if (isMobile)
    return (
      <div className={styles.layoutMobile} ref={ref}>
        <Header />
        <div className={styles.containerMobile}>
          <SidebarContext>
            <TendersNav />
            <BrokenMobilePagesBanner />
            <Suspense fallback={<Skeleton />}>
              <Outlet />
            </Suspense>
          </SidebarContext>
        </div>
      </div>
    );

  return (
    <div className={styles.layout} ref={ref}>
      <div className={styles.container}>
        <Header />
        <SidebarContext>
          <TendersNav />
          <main className={styles.main}>
            <Suspense fallback={<Skeleton />}>
              <Outlet />
            </Suspense>
          </main>
        </SidebarContext>
      </div>
    </div>
  );
};
export const MainLayout: FC<Props> = ({ scrollDelay }) => (
  <Suspense fallback={<MainSkeleton />}>
    <MainLayoutComponent scrollDelay={scrollDelay} />
  </Suspense>
);

export default MainLayout;
