import React, { FC } from 'react';
import { Tooltip as BaseTooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';
import classNames from 'classnames';
import styles from './index.module.scss';
import { useIsMobile } from 'src/reactiveVars';

const Tooltip: FC<TooltipProps> = ({ overlayClassName, title, ...restProps }) => {
  const [isMobile] = useIsMobile();

  return (
    <BaseTooltip
      trigger={'hover'}
      mouseLeaveDelay={0}
      mouseEnterDelay={0.4}
      color={'white'}
      title={isMobile ? '' : title} // Disable all tooltips on mobile
      {...restProps}
      overlayClassName={classNames(styles.tooltip, !!overlayClassName && overlayClassName)}
    />
  );
};
export default Tooltip;
