import { FieldPolicy } from '@apollo/client';
import { mergeUniqueArray } from 'src/lib/API/graphql/helpers';
import { CallOffsCached } from './types';

//[C] Non-paginated read with filter
export function getCallOffsPolicy(): FieldPolicy {
  return {
    keyArgs: ['emails', 'keywords', 'statuses'],
    merge(existing: CallOffsCached | undefined, incoming: CallOffsCached | undefined, options) {
      const merged = mergeUniqueArray(existing?.userMails, incoming?.userMails, options, 'mailId');

      return { ...existing, ...incoming, userMails: merged };
    }
  };
}
