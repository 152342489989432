import { Config } from 'src/config/config';
import { ApolloCache, ApolloClient, ApolloLink, from, gql, HttpLink, Operation, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { EntityStore } from '@apollo/client/cache/inmemory/entityStore';
import * as Sentry from '@sentry/react';
import { getAnonymousUserId } from 'src/segment';
import { cache } from './cache';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createRestartableWsClient } from './wsClient';
import { clientVersionUpdateVar } from 'src/reactiveVars/clientVersionUpdateVar';
import { RetryLink } from '@apollo/client/link/retry';
import { type AuthSession, fetchAuthSession } from 'aws-amplify/auth';
import { UserType } from 'src/models/auth';
import { StorageKey } from 'src/types/enums';
import { ApiType } from './types';
import { Kind, OperationTypeNode } from 'graphql';

const getAuthSession = async (): Promise<AuthSession | null> => {
  try {
    return await fetchAuthSession();
  } catch (e: unknown) {
    if (e === 'No current user') {
      // Amazon provides perfect as sh.. API. The only way to know if user is authenticated is to handle exception
      // which is just a string, there is no custom Error or code error.
    } else if (e instanceof Error) {
      console.warn({ message: 'Cognito error', description: e.message || String(e) });
    } else {
      console.error(e);
    }
    return null;
  }
};

const isWsOperation = (operation: Operation): boolean => {
  const definition = getMainDefinition(operation.query);
  const { userType } = operation.getContext();
  // TODO: probably could be useful not use subscriptions for mobile devices (battery energy consuming)
  // so, for this we need to just add smtg like `isBrowser` check here
  return (
    definition.kind === Kind.OPERATION_DEFINITION &&
    definition.operation === OperationTypeNode.SUBSCRIPTION &&
    userType !== UserType.Free
  );
};
const isPublicOperation = (operation: Operation): boolean => {
  return operation.getContext().api === ApiType.Public;
};
const getAuthToken = async (): Promise<string> => {
  const session = await getAuthSession();
  return session?.tokens ? session.tokens?.accessToken.toString() : '';
};

const wsLink = new GraphQLWsLink(
  createRestartableWsClient({
    url: Config.SubscriptionsApi || '',
    connectionParams: async () => {
      return { Authorization: `Bearer ${await getAuthToken()}` };
    }
  })
);

const privateApiLink = new HttpLink({
  uri: Config.PrivateApi
});
const publicApiLink = new HttpLink({
  uri: Config.PublicApi
});

const dataLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(res => {
    if (!res.data && operation.operationName === 'meInfo') res.data = {};
    return res;
  });
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      Sentry.captureMessage(message);
      return console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });

    return forward(operation);
  }
  if (networkError) {
    Sentry.captureException(networkError);
    console.error(`[Network error]: ${networkError}`);
    if (networkError.name === 'ServerError' && 'statusCode' in networkError && networkError.statusCode === 401) {
      operation.setContext({ token: null });
      // operation.setContext({ Authorization: null });
    }
  }
});

const contextLink = setContext(async () => {
  const token = await getAuthToken();
  const anonymousUserId = await getAnonymousUserId();
  const session = await getAuthSession();
  const attributes = session?.tokens?.idToken?.payload;
  const isFreeUser = attributes instanceof Error ? false : attributes?.['custom:userType'] === UserType.Free;

  return {
    token,
    anonymousUserId,
    userType: isFreeUser ? UserType.Free : UserType.Paid
  };
});

const authLink = new ApolloLink((operation, forward) => {
  const ctx = operation.getContext();
  const { token, anonymousUserId, headers: currentHeaders } = ctx;
  const headers = {
    ...currentHeaders
  };

  operation.setContext(() => {
    if (anonymousUserId) {
      headers['anonymous-user-id'] = anonymousUserId;
    }
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    return {
      headers
    };
  });
  return forward(operation);
});

const clientVersionLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();
    const {
      response: { headers }
    } = context;

    if (headers) {
      const xClientVersion = headers.get(StorageKey.Version);
      const prevXClientVersion = sessionStorage.getItem(StorageKey.Version);
      if (xClientVersion) {
        sessionStorage.setItem(StorageKey.Version, xClientVersion);
        if (prevXClientVersion && xClientVersion !== prevXClientVersion) {
          clientVersionUpdateVar(true);
        }
      }
    }

    return response;
  });
});

const retryCount = 2;

const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    const isQueryOperation = operation.query.definitions.find(
      item => item.kind === Kind.OPERATION_DEFINITION && item.operation === OperationTypeNode.QUERY
    );

    return count <= retryCount && !!error && !!isQueryOperation;
  }
});
const publicLink = ApolloLink.from([authLink, publicApiLink]);
const privateLink = split(isWsOperation, wsLink, from([dataLink, clientVersionLink, authLink, privateApiLink]));

const link = ApolloLink.from([errorLink, retryLink, contextLink, split(isPublicOperation, publicLink, privateLink)]);

// it is not used directly but prevents WebStorm from claiming on unknown directive
gql`
  directive @client(always: Boolean = false) on FIELD
`;

// FIXME: remove wrapper-class
class GraphQlClient {
  public readonly client: ApolloClient<unknown>;

  constructor(cache: ApolloCache<unknown>, link: ApolloLink | undefined, headers: Record<string, string> = {}) {
    this.client = new ApolloClient({
      cache,
      link,
      headers,
      connectToDevTools: import.meta.env.MODE !== 'production',
      resolvers: {
        Mutation: {}
      }
    });
  }

  /**
   * Removes all cached elements stored in memory which starts with given query
   * @param query - name of query to delete from the cache
   * @note: please read this thread
   *   https://spectrum.chat/apollo/apollo-client/apollo-client-3-evict-api~cd289ed2-92e7-4281-8f73-ddfebb8ad09d?m=MTU4MTExNTYwMDc2Mw==
   *   and pull-request mentioned in the comment before changing the clearQueryCache method.
   *   It could help you to get a picture of recommended by library authors approaches.
   */
  // FIXME: remove 'clearQueryCache' and switch to default apollo functionality like evict(), seems it's fixed in latest versions of apollo client
  public clearQueryCache(query: string): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - entityStore is private object :/
    const entityStore: EntityStore = cache.data;
    const ROOT_QUERY = 'ROOT_QUERY' as const;
    const rootQueryRecord = entityStore.toObject()[ROOT_QUERY];
    if (!rootQueryRecord) {
      // console.warn(`${ROOT_QUERY} not found. Is it a bug?`);
      return;
    }
    /**
     *  query's key includes variables passed to the query as part of the key.
     *  We want to clear all of cached requests with any passed variables
     */
    const queryKeys = Object.keys(rootQueryRecord).filter(key => key.startsWith(query));
    const stateToMerge = Object.fromEntries(queryKeys.map(key => [key, undefined]));
    entityStore.merge(ROOT_QUERY, stateToMerge);

    // maybe we should call this as well to notify watchers. But we do not have watchers yet :)
    // cache.broadcastWatches();
    // if anything was cleared from a cache we should call `gc` to free a memory from newly unreachable elements
    cache.gc();
  }
}

export const gqlClient = new GraphQlClient(cache, link);
