import React, { FC, useState, useCallback, useMemo } from 'react';
import styles from './index.module.scss';
import { Dropdown, Tooltip, Skeleton, ConditionalWrapper } from 'src/common';
import { useWorkspaces } from 'src/models/workspace/hooks';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import WorkspacePickerItem from './WorkspacePickerItem';
import WorkspaceBadge from 'src/common/WorkspaceBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { useBidWorkspace, useMoveToWorkspace } from 'src/models/bids/Bids/hooks';
import { BidItemType } from '@tendium/prom-types/tender';
import FullModeLabel from './components/FullModeLabel';
import ShortModeLabel from './components/ShortModeLabel';
import MediumModeLabel from './components/MediumModeLabel';
import { IBidIdentifier } from 'src/models/bids/Bids/types';
import CreateWorkspaceModal from '../CreateWorkspaceModal';
import { FeatureFlag, useFeatureFlag } from 'src/helpers';
import { useCreateWsDisabled } from 'src/models/admin/CompanyUsers/hooks';
import WorkspacePickerMobile from './WorkspacePickerMobile';
import { useIsMobile } from 'src/reactiveVars';

export type WorkspacePickerMode = 'short' | 'full' | 'medium';
export type WorkspacePickerTheme = 'default' | 'select';

export interface WorkspacePickerProps {
  eventSource: string;
  eventType: string;
  placement?: (typeof Placements)[number];
  originIds?: string[];
  bidIds?: IBidIdentifier[];
  originType?: BidItemType;
  onChange?: () => void;
  mode?: WorkspacePickerMode;
  size?: (typeof Sizes)[number];
  isSelected?: boolean;
  theme?: WorkspacePickerTheme;
  disabled?: boolean;
  relevanceScores?: (number | null | undefined)[];
}

export const WorkspacePickerComponent: FC<WorkspacePickerProps> = ({
  placement = 'bottomRight',
  mode = 'short',
  size = 's',
  ...restProps
}) => {
  const {
    originIds,
    bidIds,
    onChange,
    eventSource,
    eventType,
    originType,
    isSelected,
    theme,
    disabled,
    relevanceScores
  } = restProps;
  const { t } = useTranslation();
  const { workspace: currentWorkspace, inNoAccessWorkspace } = useBidWorkspace(
    (bidIds && !!bidIds.length && bidIds[0].id) || undefined
  );
  const isCreateWsDisabled = useCreateWsDisabled();

  const [wsMenuVisible, setWsMenuVisible] = useState(false);
  const [wsIsUpdating, setWsIsUpdating] = useState(false);
  const [wsModalVisible, setWsModalVisible] = useState(false);
  const onClose = useCallback(() => {
    setWsModalVisible(false);
    onChange && onChange();
  }, [onChange]);

  const { data, loading } = useWorkspaces();
  const workspaces = data && data.filter(ws => ws.isActive).sort((a, b) => a.name.localeCompare(b.name, 'sv'));

  const onCreateWorkspace = useCallback(() => {
    setWsMenuVisible(false);
    setWsModalVisible(true);
  }, []);

  const onOpenMenu = useCallback(() => {
    setWsMenuVisible(!wsMenuVisible);
  }, [wsMenuVisible]);

  const showTooltip = !currentWorkspace && mode !== 'full';

  const workspaceBadgeMode = useMemo(() => {
    return mode === 'full' || mode === 'medium' ? 'full' : 'short';
  }, [mode]);

  const workSpaceBadge =
    !!currentWorkspace && !isSelected ? (
      <WorkspaceBadge
        title={currentWorkspace.name}
        color={currentWorkspace.color}
        mode={workspaceBadgeMode}
        className={classNames({
          [styles.wsButtonBadge]: mode === 'full',
          [styles.wsButtonBadgeMedium]: mode === 'medium'
        })}
        loading={wsIsUpdating}
        size={size}
      />
    ) : null;

  const [moveToWorkspace, { loading: isLoading }] = useMoveToWorkspace();

  const onMoveToWorkspace = useCallback(
    async (workspaceId: string) => {
      if (isLoading) {
        return;
      }
      if (workspaceId && (originIds || bidIds)) {
        setWsIsUpdating(true);
        await moveToWorkspace({
          workspaceId,
          eventSource,
          eventType,
          originType,
          originIds,
          bids: bidIds,
          relevanceScores
        });
        setWsIsUpdating(false);
        onClose?.();
      }
    },
    [isLoading, originIds, bidIds, moveToWorkspace, eventSource, eventType, originType, relevanceScores, onClose]
  );

  const onCreatingWSFinish = useCallback(
    (workspaceId: string | null) => {
      workspaceId && onMoveToWorkspace(workspaceId);
    },
    [onMoveToWorkspace]
  );

  return (
    <>
      <Dropdown
        trigger={['click']}
        placement={placement}
        visible={wsMenuVisible}
        onVisibleChange={onOpenMenu}
        className={styles.wsPicker}
        overlayClassName={styles.wsPickerMenu}
        overlay={
          <div className={styles.wsMenu}>
            {workspaces && !loading ? (
              <ul className={classNames(styles.wsList, { [styles.isUpdating]: wsIsUpdating })}>
                {workspaces.map(ws => (
                  <WorkspacePickerItem
                    key={ws.id}
                    ws={ws}
                    loading={!!isLoading}
                    onMoveToWorkspace={onMoveToWorkspace}
                  />
                ))}
              </ul>
            ) : (
              <Skeleton loading active />
            )}
            <div className={classNames(styles.wsMenuFooter, { [styles.noWs]: !workspaces || !workspaces.length })}>
              <ConditionalWrapper
                condition={isCreateWsDisabled}
                wrapper={children => (
                  <Tooltip title={t('BidSpaces.wsRestrictionTooltip')}>
                    {children}
                    {''}
                  </Tooltip>
                )}
              >
                <button onClick={onCreateWorkspace} className={styles.wsMenuAction} disabled={isCreateWsDisabled}>
                  <span className={styles.wsMenuActionTitle}>{t('BidSpaces.addNewWS')}</span>
                  <FontAwesomeIcon icon={faAngleRight} className={styles.wsMenuActionIcon} />
                </button>
              </ConditionalWrapper>
            </div>
          </div>
        }
      >
        <Tooltip title={showTooltip ? t('BidSpaces.addToBidSpace') : undefined}>
          <>
            {mode === 'full' && (
              <FullModeLabel
                isUpdating={wsIsUpdating}
                wsBadge={workSpaceBadge}
                onOpenMenu={onOpenMenu}
                theme={theme}
                inNoAccessWorkspace={inNoAccessWorkspace}
                disabled={disabled}
              />
            )}
            {mode === 'medium' && (
              <MediumModeLabel
                isUpdating={wsIsUpdating}
                wsBadge={workSpaceBadge}
                onOpenMenu={onOpenMenu}
                inNoAccessWorkspace={inNoAccessWorkspace}
                disabled={disabled}
              />
            )}
            {mode === 'short' && (
              <ShortModeLabel
                isUpdating={wsIsUpdating}
                wsBadge={workSpaceBadge}
                onOpenMenu={onOpenMenu}
                menuVisible={wsMenuVisible}
                inNoAccessWorkspace={inNoAccessWorkspace}
                disabled={disabled}
              />
            )}
          </>
        </Tooltip>
      </Dropdown>
      {wsModalVisible && (
        <CreateWorkspaceModal
          visible={wsModalVisible}
          onCancel={() => setWsModalVisible(false)}
          onFinish={onCreatingWSFinish}
          loading={!!isLoading}
        />
      )}
    </>
  );
};

export const WorkspacePicker: FC<WorkspacePickerProps> = props => {
  const isBidspacesFeature = useFeatureFlag(FeatureFlag.Bidspaces);
  const [isMobile] = useIsMobile();

  if (!isBidspacesFeature) return null;

  if (isMobile) return <WorkspacePickerMobile {...props} />;

  return <WorkspacePickerComponent {...props} />;
};

export default WorkspacePicker;
