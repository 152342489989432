import { FieldPolicy } from '@apollo/client';
import { mergeUniqueArray } from 'src/lib/API/graphql/helpers';

//[C] Non-paginated read
export function getRemindersPolicy(): FieldPolicy {
  return {
    keyArgs: ['input', ['relationId', 'assignedTo', 'createdBy']],
    merge: mergeUniqueArray
  };
}
