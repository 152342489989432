import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { TenderBlockSize, IGenericSubCat } from 'src/models/procurements/Tender/types';
import styles from './index.module.scss';
import { BlockTitle } from 'src/shared/Blocks/BlockTitle';
import { useExistingTranslation } from 'src/common';
import { Translations } from 'src/lib/I18nService/types';
import { useTenderData } from 'src/models/procurements/Tender/hooks';
import { useIsMobile } from 'src/reactiveVars';

interface Props {
  subCat: IGenericSubCat;
  children: ReactNode;
  actions?: ReactNode;
  className?: string;
  boxTheme?: boolean;
  containerClassName?: string;
}
export const TenderSubCat: FC<Props> = ({ subCat, children, actions, className, boxTheme, containerClassName }) => {
  const { title, icon, size = TenderBlockSize.Full, order = 0, badge } = subCat;
  const { existingT: tenderT } = useExistingTranslation(Translations.procurements);
  const { data } = useTenderData();
  const editable = boxTheme !== undefined ? boxTheme : !!data?.isEditable;
  const [isMobile] = useIsMobile();
  const content = (
    <>
      <BlockTitle title={tenderT(title)} icon={icon} actions={actions} badge={badge} />
      <div
        className={classNames(styles.subCatContent, className, {
          [styles.subCatContentMobile]: isMobile
        })}
      >
        {children}
      </div>
    </>
  );

  return (
    <section
      className={classNames(styles.subCat, styles[isMobile ? TenderBlockSize.Full : size], containerClassName, {
        [styles.editable]: editable,
        [styles.subCatMobile]: isMobile
      })}
      style={{ order }}
    >
      {editable ? <div className={styles.readOnlyBox}>{content}</div> : content}
    </section>
  );
};

export default TenderSubCat;
