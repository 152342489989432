import React, { FC, useCallback, useState } from 'react';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import { useBackHref } from 'src/helpers/history';
import { Navigate, useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useLoadAllMps } from 'src/models/matchingProfiles/hooks';
import { trackSwitchMatchingProfile } from 'src/segment/events';
import { MatchingProfileCreate } from 'src/shared';
import styles from './index.module.scss';
import { Button } from 'src/common/Button';
import { Skeleton } from 'src/common/Skeleton';
import { Paths } from 'src/pages/paths';
import NavCard from 'src/common/NavCard';
import { ConditionalWrapper, PageHeading, Tooltip } from 'src/common';
import { faSquare } from '@fortawesome/pro-solid-svg-icons';
import { useCreateMpDisabled } from 'src/models/admin/CompanyUsers/hooks';
import { useIsMobile } from 'src/reactiveVars';
import classNames from 'classnames';

const MonitoringProfilesNavPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMobile] = useIsMobile();

  const backHref = useBackHref();
  const isMonitoringFeature = useFeatureFlag(FeatureFlag.MonitoringProfiles);

  const { data: allMps, loading } = useLoadAllMps();

  const isCreateMpDisabled = useCreateMpDisabled();

  const [modalVisible, setModalVisible] = useState(false);
  const hideModal = useCallback(() => {
    setModalVisible(false);
  }, []);
  const showModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onGoToMp = useCallback(
    (mpId: string) => {
      const mpName = (allMps && allMps.find(mp => mp.id === mpId)?.name) || '';
      trackSwitchMatchingProfile({
        id: mpId,
        name: mpName
      });
      navigate({ pathname: `${Paths.MONITORING}/${mpId}` });
    },
    [allMps, navigate]
  );

  return isMonitoringFeature ? (
    loading ? (
      <Skeleton active={loading} />
    ) : (
      <>
        <PageHeading title={t('Tenders.monitoringProfiles')} />
        <div
          className={classNames(styles.scrollContainer, {
            [styles.mobile]: isMobile
          })}
        >
          <div
            className={classNames(styles.container, {
              [styles.mobile]: isMobile
            })}
          >
            {!!allMps?.length &&
              allMps.map(mp => (
                <NavCard
                  key={mp.id}
                  title={mp.name}
                  id={mp.id}
                  onClick={onGoToMp}
                  icon={faSquare}
                  owners={mp.owners}
                  subscribers={mp.subscribers}
                  subscribersPolicy={mp.accessControl.subscribePolicy}
                />
              ))}
            <ConditionalWrapper
              condition={isCreateMpDisabled}
              wrapper={children => <Tooltip title={t('Tenders.mpRestrictionTooltip')}>{children}</Tooltip>}
            >
              {!isMobile && (
                <Button className={styles.addNewCard} onClick={showModal} disabled={isCreateMpDisabled}>
                  <FontAwesomeIcon icon={faPlus} />
                  <span className={styles.addNew}> {t('Tenders.addNewMatchingProfile')}</span>
                </Button>
              )}
            </ConditionalWrapper>
            {modalVisible && <MatchingProfileCreate onHide={hideModal} eventSource={'MP view navigation'} />}
          </div>
        </div>
      </>
    )
  ) : (
    <Navigate to={backHref} />
  );
};

export default MonitoringProfilesNavPage;
