import { FeatureFlag, Schema } from '@tendium/prom-types';
import dayjs from 'dayjs';

export class FeatureSet {
  readonly id: string;
  readonly name: string;
  readonly description: string | undefined;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly featureFlags: FeatureFlag[];
  readonly active: boolean;

  constructor(private readonly data: Schema.FeatureAccess.FeatureSet) {
    this.id = this.data.id;
    this.name = this.data.name;
    this.description = this.data.description;
    this.createdAt = dayjs(this.data.createdAt).format('YYYY-MM-DD');
    this.updatedAt = dayjs(this.data.updatedAt).format('YYYY-MM-DD');
    this.featureFlags = this.data.featureFlags ?? [];
    this.active = this.data.active;
  }
}
