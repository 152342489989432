import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { useBodyScrollLock } from 'src/helpers';
import { useTranslation } from 'react-i18next';
interface Props {
  visible: boolean;
  onVisibleChange: () => void;
  overlayTitle: ReactNode;
  overlayContent: ReactNode;
  overlayContentClassname?: string;
}

const FullscreenOverlay: FC<Props> = ({
  visible,
  onVisibleChange,
  overlayTitle,
  overlayContent,
  overlayContentClassname
}) => {
  useBodyScrollLock(visible);
  const { t } = useTranslation();

  if (!visible) return null;

  return (
    <main className={styles.fullscreenOverlay}>
      <header className={styles.header}>
        <h1 className={styles.title}>{overlayTitle}</h1>
        <button onClick={onVisibleChange} className={styles.closeButton}>
          {t('Common.done')}
        </button>
      </header>
      <div className={classNames(styles.overlayContent, overlayContentClassname)}>{overlayContent}</div>
    </main>
  );
};

export default FullscreenOverlay;
