import dayjs from 'src/helpers/dayjs';
import { ChosenYearFilter, SupplierAnalyticsWithBilling, SupplierTransactions } from './types';
import { AnalyticsDateFilter, AnalyticsFilters, SupplierAnalytics } from '@tendium/prom-types/schema';
import { ApolloCache } from '@apollo/client';
import { ProfileType } from '@tendium/prom-types';
import { MpShort } from '../matchingProfiles/hooks';
import { formatDateWithTimezoneOffset } from 'src/helpers/dates';

const currentYear = dayjs().year();

export const yearFilters = [...Array.from({ length: currentYear - 2020 }, (_, i) => 2021 + i)] as const;

export const mergeSupplierData = (
  supplierAnalytics: SupplierAnalytics[],
  transactions: SupplierTransactions[]
): SupplierAnalyticsWithBilling[] => {
  const transactionMap = transactions.reduce((acc, transaction) => {
    acc[transaction.supplierOrgId] = transaction;
    return acc;
  }, {} as Record<string, SupplierTransactions>);

  return supplierAnalytics.map(supplier => {
    const transaction = transactionMap[supplier.orgId];
    return {
      ...supplier,
      billing: transaction ? { amount: transaction.amount, currency: transaction.currency } : null
    };
  });
};

export const convertYearFilterToAnalyticsDateFilter = (year: number | null): AnalyticsDateFilter => {
  if (!year) {
    // Range from 2021 to the current year
    return {
      start: formatDateWithTimezoneOffset(new Date(2021, 0, 1)),
      end: formatDateWithTimezoneOffset(new Date(currentYear + 1, 0, 1))
    };
  }

  return {
    start: formatDateWithTimezoneOffset(new Date(year, 0, 1)),
    end: formatDateWithTimezoneOffset(new Date(year + 1, 0, 1))
  };
};

export const filterProfilesByType = (profiles: MpShort[], profileType: ProfileType): MpShort[] =>
  profiles?.filter(p => p.profileType === profileType);

export const evictAnalyticsCache = <T = unknown>(cache: ApolloCache<T>): void => {
  // Market dashboard
  cache.evict({ fieldName: 'getMarketAnalytics' });
  cache.evict({ fieldName: 'getAggregatedSupplierTransactions' }); // Billing data in table
  // Buyer profiles
  cache.evict({ fieldName: 'getBuyerProfile' });
  // Supplier profiles
  cache.evict({ fieldName: 'getSupplierProfile' });
  cache.evict({ fieldName: 'getSupplierBuyerTransactions' });
  cache.gc();
};

export const getYearFilterFromDateFilter = (dateFilter: AnalyticsDateFilter | undefined): ChosenYearFilter => {
  if (!dateFilter) {
    return null;
  }
  const yearOfStart = new Date(dateFilter.start).getFullYear();
  const yearOfEnd = new Date(dateFilter.end).getFullYear();

  if (yearOfEnd - yearOfStart > 1) {
    // case when all years are selected, (2025 - 2021) for example
    return null;
  }

  return yearOfStart;
};

export const resetIdPreference = (
  idToBeDeleted: string,
  savedFilters: AnalyticsFilters,
  saveFilters: (filters: AnalyticsFilters) => void
): void => {
  const savedProfileId = savedFilters.profileId;
  if (!savedProfileId) return;
  if (savedFilters.profileId !== idToBeDeleted) return;

  saveFilters({
    profileId: '',
    tenderStatus: savedFilters.tenderStatus,
    dateFilter:
      savedFilters.dateFilter?.start && savedFilters.dateFilter.end
        ? {
            start: savedFilters.dateFilter.start,
            end: savedFilters.dateFilter.end
          }
        : undefined
  });
};
