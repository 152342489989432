import React, { useCallback, useState, ReactNode, useMemo } from 'react';
import UserIcon from 'src/common/UserIcon';
import Dropdown from 'src/common/Dropdown';
import Tooltip from 'src/common/Tooltip';
import classNames from 'classnames';
import styles from './index.module.scss';
import DropdownButton from '../DropdownButton';
import UserPickerOverlay from './UserPickerOverlay';
import { DropdownType } from '../PlainDropDown/types';
import MultipleUserPickerOverlay from './MultipleUserPickerOverlay';
import SearchInput from './SearchInput';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { UpdateUserSearchContext, UserSearch, UserSearchContext } from './context';
import { AssignedTo } from 'src/models/users/types';
import Assignee from 'src/shared/Assignee';
import { useIsMobile } from 'src/reactiveVars';
import { UserPickerMobile } from './UserPickerMobile/UserPickerMobile';

export type RenderMode = 'label' | 'button' | 'input';
export interface UserPickerProps<DT extends DropdownType> {
  onSelect: (user: AssignedTo, checked?: boolean) => void;
  users: AssignedTo[];
  label: string;
  usersLoading?: boolean;
  onUnSelect?: () => void;
  size?: (typeof Sizes)[number];
  tooltip?: string;
  isUpdating?: boolean;
  selected?: DT extends 'multiple' ? string[] : string | null;
  mode?: 'full' | 'short';
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
  defaultPopupContainer?: boolean;
  overlayClassName?: string;
  userPickerType: DT;
  renderMode?: RenderMode;
  inputIcon?: IconDefinition;
}

export function UserPicker(props: UserPickerProps<'multiple'> | UserPickerProps<'single'>): JSX.Element {
  const {
    size = 'm',
    mode = 'short',
    disabled = false,
    selected,
    onSelect: onSelectFn,
    onUnSelect: onUnSelectFn,
    label,
    tooltip,
    className,
    isUpdating,
    users,
    usersLoading,
    children,
    renderMode = 'label',
    defaultPopupContainer,
    userPickerType,
    overlayClassName,
    inputIcon
  } = props;
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isMobile] = useIsMobile();

  const toggleMenu = useCallback(() => {
    setIsMenuVisible(!isMenuVisible);
  }, [isMenuVisible]);

  const onSelect = useCallback(
    (user: AssignedTo, checked?: boolean) => {
      onSelectFn(user, checked);

      if (userPickerType === 'single') {
        toggleMenu();
      }
    },
    [userPickerType, onSelectFn, toggleMenu]
  );

  const onUnSelect = useCallback(() => {
    onUnSelectFn && onUnSelectFn();

    if (userPickerType === 'single') {
      toggleMenu();
    }
  }, [userPickerType, onUnSelectFn, toggleMenu]);

  const selectedUser = useMemo(() => {
    if (userPickerType === 'multiple') {
      return null;
    }
    return selected ? users.find(user => user.id === selected) : null;
  }, [selected, userPickerType, users]);

  const initialUsers = useMemo(() => {
    if (userPickerType === 'multiple') {
      return users;
    }
    return (selectedUser && users.filter(user => user.id !== selectedUser.id)) || users;
  }, [selectedUser, userPickerType, users]);

  const [userSearch, updateUserSearch] = useState<UserSearch>({
    searchString: '',
    users: [...initialUsers]
  });

  if (isMobile) {
    return (
      <UserPickerMobile
        toggleMenu={toggleMenu}
        isMenuVisible={isMenuVisible}
        onSelect={onSelect}
        users={users}
        usersLoading={usersLoading}
        isUpdating={isUpdating}
        disabled={disabled}
        selectedUser={selectedUser}
        tooltip={tooltip}
        label={label}
        onUnSelect={onUnSelect}
      />
    );
  }

  return (
    <UpdateUserSearchContext.Provider value={updateUserSearch}>
      <UserSearchContext.Provider value={userSearch}>
        <Dropdown
          trigger={['click']}
          visible={isMenuVisible}
          onVisibleChange={toggleMenu}
          overlayClassName={classNames(styles.assignMenu, overlayClassName)}
          destroyPopupOnHide
          getPopupContainer={defaultPopupContainer ? undefined : triggerNode => triggerNode.parentNode as HTMLElement}
          disabled={disabled}
          overlay={
            userPickerType === 'single' ? (
              <UserPickerOverlay
                users={initialUsers}
                usersLoading={usersLoading}
                selected={selectedUser}
                onSelect={onSelect}
                onUnSelect={onUnSelect}
                searchable={renderMode !== 'input'}
              >
                {!!children && children}
              </UserPickerOverlay>
            ) : (
              <MultipleUserPickerOverlay
                users={initialUsers}
                usersLoading={usersLoading}
                selected={selected}
                onSelect={onSelect}
                searchable={renderMode !== 'input'}
              />
            )
          }
        >
          {renderMode === 'label' && userPickerType !== 'multiple' ? (
            <button
              className={classNames(styles.button, styles.assignButton, className, { [styles.isDisabled]: disabled })}
            >
              {selectedUser ? (
                <Assignee
                  assignedToData={selectedUser}
                  size={size}
                  loading={isUpdating}
                  {...(mode === 'short'
                    ? {
                        mode: 'onlyAvatar',
                        className: styles.avatar,
                        showTooltip: true
                      }
                    : {})}
                />
              ) : (
                <Tooltip title={tooltip ?? label}>
                  <UserIcon isActive={isMenuVisible} size={size} loading={isUpdating} />
                  {mode === 'full' && <span className={styles.assignButtonLabel}>{label}</span>}
                </Tooltip>
              )}
            </button>
          ) : renderMode === 'input' ? (
            <SearchInput
              users={initialUsers}
              className={className}
              placeholder={label}
              icon={inputIcon}
              onFocus={() => setIsMenuVisible(true)}
              bordered
              disabled={disabled}
            />
          ) : (
            <DropdownButton
              isActive={userPickerType === 'multiple' ? !!selected?.length : !!selected}
              isOpen={isMenuVisible}
              label={label}
              className={className}
            />
          )}
        </Dropdown>
      </UserSearchContext.Provider>
    </UpdateUserSearchContext.Provider>
  );
}
export default UserPicker;
