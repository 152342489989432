import { MutationTuple, useMutation, MutationUpdaterFn } from '@apollo/client';
import { CHANGE_LANGUAGE, UPDATE_USER } from './queries';
import { Language } from '@tendium/prom-types';
import { IApiUser } from '../types';
import { ApiMeUser } from 'src/models/auth';

interface IUpdateLanguageResponse {
  __typename: 'Mutation';
  updateLanguage: ApiMeUser & {
    __typename: 'User';
  };
}
interface IUpdateLanguageInput {
  id: string;
  language: Language;
}
export function useUpdateLanguage(): MutationTuple<IUpdateLanguageResponse, IUpdateLanguageInput> {
  return useMutation<IUpdateLanguageResponse, IUpdateLanguageInput>(CHANGE_LANGUAGE);
}

export function getUpdateCacheOnUpdateLanguage(email: string): MutationUpdaterFn<IUpdateLanguageResponse> {
  return (cache, { data }) => {
    if (!data) {
      return;
    }
    if (!email) {
      return;
    }
    const userRef = cache.identify({
      __typename: 'User',
      email
    });
    cache.modify({
      id: userRef,
      fields: {
        notificationSettingGroups() {
          return data.updateLanguage.language;
        }
      }
    });
  };
}

interface IUpdateUserResponse {
  __typename: 'Mutation';
  updateUserName: IApiUser & {
    __typename: 'User';
  };
}
interface IUpdateUserInput {
  email: string;
  name?: string;
  lastName?: string;
  color?: string;
  licenseIds?: string[];
  enabled?: boolean;
}
export function useUpdateUser(): MutationTuple<IUpdateUserResponse, IUpdateUserInput> {
  return useMutation<IUpdateUserResponse, IUpdateUserInput>(UPDATE_USER, {
    update(cache, { data }) {
      if (!data) {
        return null;
      }

      cache.evict({ fieldName: 'getLicensesAsAdmin' });
      cache.evict({ fieldName: 'getLicenses' });
      cache.gc();
    }
  });
}
