import {
  ApolloError,
  LazyQueryResultTuple,
  MutationUpdaterFn,
  OperationVariables,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription
} from '@apollo/client';
import { useCallback, useContext, useMemo } from 'react';
import { ContentLibrary } from '.';
import { ContentLibraryContext, ContentLibraryContextValue } from './context';
import {
  BULK_UPDATE_CONTENT_LIBRARY_CONTENTS,
  CREATE_CONTENT_LIBRARY_CONTENT,
  DELETE_CONTENT_LIBRARY_CONTENTS,
  GET_CONTENT_LIBRARY,
  UPDATE_CONTENT_LIBRARY_CONTENT,
  GET_URL_FILE_UPLOAD,
  ON_DOCUMENT_UPLOAD,
  GET_SINGLE_CONTENT_LIBRARY_CONTENT,
  GET_SIGNED_URL_DOWNLOAD_TEMPLATE_CONTENT_LIBRARY,
  GET_SIGNED_URL_BULK_IMPORT,
  CONTENT_LIBRARY_IMPORT
} from './queries';
import {
  ApiContentLibraryAllResponse,
  ApiContentLibraryBulkUpdateResponse,
  ApiContentLibraryCountResponse,
  ApiContentLibraryDeleteResponse,
  ApiContentLibraryUpdateResponse,
  ApiContentLibraryVars,
  ApiCreateRowResponse,
  ContentLibraryAllResponseData,
  ContentLibraryCountResponseData,
  ContentLibraryEventSource,
  ContentLibraryQpVars,
  ContentLibraryQueryParams,
  DEFAULT_CONTENT_LIBRARY_LIMIT,
  DefaultInput,
  NO_FILTER_STORE_FIELD_NAME,
  isContentLibrarySort,
  GetSignedUrlOutput,
  GetSignedUrlDownloadOutput,
  GetSingleContentOutput,
  GetSingleContentInput,
  ApiUploadContentDocument,
  GetSignedUrlBulkOutput,
  ApiContentLibraryUploadRequest,
  ApiImportCsvContent,
  ContentLibraryCached
} from './types';
import { useTranslation } from 'react-i18next';
import { notification } from 'src/common';
import { isString, isStrings, updateQueryParams, useQueryParams } from 'src/helpers';
import { useLocation, useParams } from 'react-router';
import { Paths } from 'src/pages/paths';
import { useTask } from 'src/models/bids/BidTask/hooks';
import {
  ContentLibraryDocumentStatus,
  ContentLibraryType,
  CreateContentLibraryContentDTO,
  DeleteContentLibraryContentDTO,
  GetContentLibraryDTO,
  UpdateBulkContentLibraryContentsDTO,
  UpdateContentLibraryContentDTO
} from '@tendium/prom-types/schema';
import { trackAddContentCardCL, trackDeleteContentCardCL, trackEditContentCardCL } from 'src/segment/events';
import { cache } from 'src/lib/API/graphql/cache';
import { User } from 'src/models/users';
import { ROOM_FIELDS } from '../ContentLibraryRoom/queries';
import { USER_NAME_FIELDS } from 'src/models/users/queries';
import { useAllUsersAndTeams } from 'src/models/users/hooks';
import { ContentLibraryBulkImportSub } from '@tendium/prom-types/subscriptions';
import { getLoadingStatesForQuery } from 'src/lib/API/graphql/helpers';

export function useContentLibraryQpVars(): ContentLibraryQpVars {
  const qp = useQueryParams();
  const searchQueryParams = qp[ContentLibraryQueryParams.search];
  const roomIdsQueryParams = qp[ContentLibraryQueryParams.roomIds];
  const tagIdsQueryParams = qp[ContentLibraryQueryParams.tagIds];
  const createdByQueryParams = qp[ContentLibraryQueryParams.createdBy];
  const assignedToQueryParams = qp[ContentLibraryQueryParams.assignedTo];
  const typeQueryParams = qp[ContentLibraryQueryParams.types];
  const sortQueryParams = qp[ContentLibraryQueryParams.sort];

  const all = useMemo(
    () => ({
      sort: isContentLibrarySort(sortQueryParams) ? sortQueryParams : undefined,
      filters: {
        search: isString(searchQueryParams) ? searchQueryParams : undefined,
        roomIds: isStrings(roomIdsQueryParams) ? roomIdsQueryParams.sort() : undefined,
        tagIds: isStrings(tagIdsQueryParams) ? tagIdsQueryParams.sort() : undefined,
        createdBy: isStrings(createdByQueryParams) ? createdByQueryParams.sort() : undefined,
        assignedTo: isStrings(assignedToQueryParams) ? assignedToQueryParams.sort() : undefined,
        types: isStrings(typeQueryParams) ? typeQueryParams.sort() : undefined
      }
    }),
    [
      assignedToQueryParams,
      createdByQueryParams,
      roomIdsQueryParams,
      searchQueryParams,
      sortQueryParams,
      tagIdsQueryParams,
      typeQueryParams
    ]
  );

  return useMemo(() => all, [all]);
}

export function useContentLibrary(): ContentLibraryContextValue {
  const state = useContext(ContentLibraryContext);
  if (!state) {
    throw new Error('not in ContentLibraryContext provider');
  }

  return state;
}

export function useContentLibraryNav(): { isRoomPage: boolean; isTaskPage: boolean } {
  const { pathname } = useLocation();
  const [, routeSlug] = pathname.split('/');
  const [, taskPath] = Paths.TASK_ROUTE.split('/');

  const isRoomPage = Paths.CONTENT_LIBRARY_ROOM.includes(routeSlug);
  const isTaskPage = routeSlug === taskPath;
  return { isRoomPage, isTaskPage };
}

export function useContentLibraryCount(roomId: string): ContentLibraryCountResponseData {
  const query = useQuery<ApiContentLibraryCountResponse, GetContentLibraryDTO>(GET_CONTENT_LIBRARY, {
    variables: {
      offset: 0,
      limit: DEFAULT_CONTENT_LIBRARY_LIMIT,
      filters: { roomIds: [roomId] }
    },
    skip: !roomId
  });

  return useMemo(
    () => ({
      ...query,
      ...getLoadingStatesForQuery(query),
      data: query.data?.getContentLibraryContents.totalCount
    }),
    [query]
  );
}

export function useContentLibraryCtx(): ContentLibraryAllResponseData {
  const qpFilters = useContentLibraryQpVars();
  const { id: roomId } = useParams<{ id?: string }>();
  const { isRoomPage } = useContentLibraryNav();
  const { data: task } = useTask();
  const allUsersAndTeams = useAllUsersAndTeams();

  const updateVars = useCallback((vars: Partial<ApiContentLibraryVars>) => {
    updateQueryParams(vars);
  }, []);

  const clearVars = useCallback(() => {
    updateQueryParams({
      search: undefined,
      roomIds: undefined,
      tagIds: undefined,
      createdBy: undefined,
      assignedTo: undefined,
      sort: undefined,
      offset: undefined,
      limit: undefined,
      types: undefined
    });
  }, []);

  const question = useMemo(() => task?.content, [task]);

  const query = useQuery<ApiContentLibraryAllResponse, GetContentLibraryDTO>(GET_CONTENT_LIBRARY, {
    variables: {
      offset: 0,
      limit: DEFAULT_CONTENT_LIBRARY_LIMIT,
      question: question ?? undefined,
      ...qpFilters,
      ...(roomId && isRoomPage && { filters: { ...qpFilters.filters, roomIds: [roomId] } })
    },
    errorPolicy: 'ignore'
  });

  const isFiltersApplied = useMemo(() => {
    return Object.values(qpFilters.filters).some(value => value !== undefined);
  }, [qpFilters]);

  return useMemo(
    () => ({
      ...query,
      ...getLoadingStatesForQuery(query),
      data: query.data?.getContentLibraryContents
        ? new ContentLibrary(query.data?.getContentLibraryContents, allUsersAndTeams)
        : undefined,
      updateVars,
      currentVars: qpFilters,
      clearVars,
      isFiltersApplied
    }),
    [query, allUsersAndTeams, updateVars, qpFilters, clearVars, isFiltersApplied]
  );
}

export function useDeleteContentLibrary(): [
  (data: DeleteContentLibraryContentDTO, onFinish?: () => void) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const { isRoomPage } = useContentLibraryNav();
  const eventSource = useMemo(() => {
    return isRoomPage ? ContentLibraryEventSource.room : ContentLibraryEventSource.contentLibrary;
  }, [isRoomPage]);

  const [deleteContentLibraryContent, { loading, error }] = useMutation<
    ApiContentLibraryDeleteResponse,
    DefaultInput<DeleteContentLibraryContentDTO>
  >(DELETE_CONTENT_LIBRARY_CONTENTS);
  const deleteContentLibraryContentFn = useCallback(
    (data: DeleteContentLibraryContentDTO, onFinish?: () => void) => {
      deleteContentLibraryContent({
        variables: { input: data },
        update: updateCacheOnDeleteContentLibrary()
      })
        .then(() => {
          const mode = data.ids.length > 1 ? 'Single' : 'Bulk';
          onFinish && onFinish();
          trackDeleteContentCardCL(eventSource, mode);
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [deleteContentLibraryContent, eventSource, t]
  );

  return useMemo(
    () => [deleteContentLibraryContentFn, { loading, error }],
    [error, loading, deleteContentLibraryContentFn]
  );
}

function updateCacheOnDeleteContentLibrary(): MutationUpdaterFn<ApiContentLibraryDeleteResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    cache.evict({ id: 'ROOT_QUERY', fieldName: 'getContentLibraryContents' });
    cache.gc();
  };
}

export function useBulkUpdateContentLibrary(): [
  (input: UpdateBulkContentLibraryContentsDTO) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const { isRoomPage } = useContentLibraryNav();
  const eventSource = useMemo(() => {
    return isRoomPage ? ContentLibraryEventSource.room : ContentLibraryEventSource.contentLibrary;
  }, [isRoomPage]);

  const [updateContentLibraryContents, { loading, error }] = useMutation<
    ApiContentLibraryBulkUpdateResponse,
    DefaultInput<UpdateBulkContentLibraryContentsDTO>
  >(BULK_UPDATE_CONTENT_LIBRARY_CONTENTS);
  const updateContentLibraryContentsFn = useCallback(
    (input: UpdateBulkContentLibraryContentsDTO) => {
      updateContentLibraryContents({
        variables: {
          input
        },
        update: updateCacheOnBulkUpdateContentLibrary()
      })
        .then(() => {
          const fields = [];
          if (input.assignedTo) fields.push('Assigned to');
          if (input.roomId) fields.push('Room');
          if (input.tagIds) fields.push('Tag');

          trackEditContentCardCL(eventSource, 'Bulk', fields);
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [eventSource, t, updateContentLibraryContents]
  );
  return useMemo(
    () => [updateContentLibraryContentsFn, { loading, error }],
    [updateContentLibraryContentsFn, error, loading]
  );
}

function updateCacheOnBulkUpdateContentLibrary(): MutationUpdaterFn<ApiContentLibraryBulkUpdateResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    cache.evict({ id: 'ROOT_QUERY', fieldName: 'getContentLibraryContents' });
    cache.gc();
  };
}

export function useUpdateContentLibrary(): [
  (input: UpdateContentLibraryContentDTO, contentType: ContentLibraryType) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const { isRoomPage } = useContentLibraryNav();
  const eventSource = useMemo(() => {
    return isRoomPage ? ContentLibraryEventSource.room : ContentLibraryEventSource.contentLibrary;
  }, [isRoomPage]);

  const [updateContentLibraryContents, { loading, error }] = useMutation<
    ApiContentLibraryUpdateResponse,
    DefaultInput<UpdateContentLibraryContentDTO>
  >(UPDATE_CONTENT_LIBRARY_CONTENT);
  const updateContentLibraryContentsFn = useCallback(
    (input: UpdateContentLibraryContentDTO, contentType: ContentLibraryType) => {
      updateContentLibraryContents({
        variables: {
          input
        },
        update: updateCacheOnUpdateContentLibrary()
      })
        .then(() => {
          const fields = [];
          if (input.assignedTo) fields.push('Assigned to');
          if (input.roomId) fields.push('Room');
          if (input.tagIds) fields.push('Tag');

          trackEditContentCardCL(eventSource, 'Single', fields, contentType);
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [eventSource, t, updateContentLibraryContents]
  );
  return useMemo(
    () => [updateContentLibraryContentsFn, { loading, error }],
    [updateContentLibraryContentsFn, error, loading]
  );
}

function updateCacheOnUpdateContentLibrary(): MutationUpdaterFn<ApiContentLibraryUpdateResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    cache.evict({ id: 'ROOT_QUERY', fieldName: 'getContentLibraryContents' });
    cache.gc();
  };
}

export function useCreateContentLibraryContent(): [
  (data: CreateContentLibraryContentDTO) => void,
  { loading: boolean; error?: ApolloError }
] {
  const { t } = useTranslation();
  const { isRoomPage, isTaskPage } = useContentLibraryNav();
  const eventSource = useMemo(() => {
    return isRoomPage
      ? ContentLibraryEventSource.room
      : isTaskPage
      ? ContentLibraryEventSource.taskPage
      : ContentLibraryEventSource.contentLibrary;
  }, [isRoomPage, isTaskPage]);

  const [createRow, { loading, error }] = useMutation<
    ApiCreateRowResponse,
    DefaultInput<CreateContentLibraryContentDTO>
  >(CREATE_CONTENT_LIBRARY_CONTENT);

  const createRowFn = useCallback(
    (data: CreateContentLibraryContentDTO) => {
      createRow({
        variables: { input: data },
        update: updateCacheOnCreateContentLibraryContent()
      })
        .then(() => {
          const fields = [];
          if (data.assignedTo) fields.push('Assigned to');
          if (data.roomId) fields.push('Room');
          if (data.tagIds) fields.push('Tag');
          trackAddContentCardCL(eventSource, fields);
        })
        .catch(() => {
          notification.error({
            description: t('Common.unknownErrorDesc'),
            message: t('Common.unknownError')
          });
        });
    },
    [createRow, eventSource, t]
  );
  return useMemo(() => [createRowFn, { loading, error }], [createRowFn, error, loading]);
}

function updateCacheOnCreateContentLibraryContent(): MutationUpdaterFn<ApiCreateRowResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    const newRef = cache.identify({ __typename: 'ContentLibraryContent', id: data.createContentLibraryContent.id });

    if (newRef) {
      cache.modify({
        fields: {
          getContentLibraryContents: (existing: ContentLibraryCached | undefined, { storeFieldName, toReference }) => {
            const assignedTo = data.createContentLibraryContent.assignedTo?.email;
            const createdBy = data.createContentLibraryContent.createdBy.email;
            const roomId = data.createContentLibraryContent.room.id;
            const tagIds = data.createContentLibraryContent.tags.map(tag => tag.id);

            if (
              (assignedTo && storeFieldName.includes(assignedTo)) ||
              storeFieldName.includes(createdBy) ||
              storeFieldName.includes(roomId) ||
              tagIds.some(id => storeFieldName.includes(id)) ||
              storeFieldName === NO_FILTER_STORE_FIELD_NAME
            ) {
              return {
                rows: [toReference(newRef), ...(existing?.rows ?? [])],
                totalCount: (existing?.totalCount ?? 0) + 1
              };
            }

            return existing;
          }
        }
      });
    }
  };
}

export function useGetSignedUrlUpload(): LazyQueryResultTuple<GetSignedUrlOutput, ApiContentLibraryUploadRequest> {
  return useLazyQuery<GetSignedUrlOutput, ApiContentLibraryUploadRequest>(GET_URL_FILE_UPLOAD, {
    fetchPolicy: 'network-only'
  });
}

export function useUploadDocumentSub(skip: boolean): void {
  const [getContent] = useSingleContentLibraryContent();
  const { t } = useTranslation();

  useSubscription<{ contentLibraryDocumentUpload: ApiUploadContentDocument }, OperationVariables>(ON_DOCUMENT_UPLOAD, {
    onData: async ({ data: response }) => {
      if (!response.data) {
        return;
      }

      const rowId = response.data.contentLibraryDocumentUpload.message?.contentLibraryRowId;
      if (response.data.contentLibraryDocumentUpload.message?.documentStatus === ContentLibraryDocumentStatus.DONE) {
        notification.success({
          description: t('ContentLibrary.contentUploadModals.importSuccessMessage'),
          message: t('ContentLibrary.contentUploadModals.importSuccessTitle'),
          key: 'contentUploadSuccess'
        });
      }

      if (response.data.contentLibraryDocumentUpload.message?.documentStatus === ContentLibraryDocumentStatus.ERROR) {
        notification.warning({
          message: t('ErrorBoundary.title'),
          description: t('ContentLibrary.contentUploadModals.importFailedMessage'),
          key: 'contentUploadError'
        });
      }

      if (rowId) {
        try {
          const { data } = await getContent({ variables: { id: rowId }, fetchPolicy: 'network-only' });
          if (data) {
            // FIXME: Updating cache explicitly like this should not have to be done, cache update should be done automatically through GraphQL
            // This is a workaround for the cache not updating correctly when multiple documents are uploaded
            // These fields are specifically updated because they are the ones being displayed in the content card
            cache.modify({
              id: cache.identify({ __typename: 'ContentLibraryContent', id: rowId }),
              fields: {
                document: () => data.getContentLibraryContent.document,
                createdAt: () => data.getContentLibraryContent.createdAt,
                modifiedAt: () => data.getContentLibraryContent.modifiedAt
              }
            });
          }
        } catch (error) {
          console.error('Error fetching content:', error);
        }
      }
    },
    onError: error => {
      console.error('Subscription error:', error);
    },
    skip: skip
  });
}

export function updateCreateCacheOnFileUploadContentLibrary(
  cardId: string,
  roomID: string,
  createdBy: User,
  fileName: string
): void {
  const newRef = cache.identify({ __typename: 'ContentLibraryContent', id: cardId });
  const roomRef = cache.identify({ __typename: 'ContentLibraryRoom', id: roomID });
  const userRef = cache.identify({ __typename: 'User', email: createdBy.email });
  const documentType = ContentLibraryType.DOCUMENT;
  const user = cache.readFragment({ id: userRef, fragment: USER_NAME_FIELDS, fragmentName: 'userNameFields' });
  const room = cache.readFragment({ id: roomRef, fragment: ROOM_FIELDS, fragmentName: 'roomFields' });

  cache.writeQuery({
    query: GET_SINGLE_CONTENT_LIBRARY_CONTENT,
    data: {
      getContentLibraryContent: {
        __typename: 'ContentLibraryContent',
        id: cardId,
        room: room,
        createdBy: user,
        modifiedBy: user,
        answer: '',
        createdAt: '',
        modifiedAt: '',
        assignedTo: null,
        tags: [],
        type: documentType,
        document: [],
        question: fileName
      }
    },
    variables: { id: cardId }
  });

  if (newRef) {
    cache.modify({
      fields: {
        getContentLibraryContents: (existing: ContentLibraryCached | undefined, { storeFieldName, toReference }) => {
          const roomId = roomID;
          const creator = createdBy.id;
          const documentType = ContentLibraryType.DOCUMENT;
          if (
            storeFieldName.includes(roomId) ||
            storeFieldName.includes(documentType) ||
            storeFieldName.includes(creator) ||
            storeFieldName === NO_FILTER_STORE_FIELD_NAME
          ) {
            return {
              rows: [toReference(newRef), ...(existing?.rows ?? [])],
              totalCount: (existing?.totalCount ?? 0) + 1
            };
          }

          return existing;
        }
      }
    });
  }
}

export function useSingleContentLibraryContent(): LazyQueryResultTuple<GetSingleContentOutput, GetSingleContentInput> {
  return useLazyQuery<GetSingleContentOutput, GetSingleContentInput>(GET_SINGLE_CONTENT_LIBRARY_CONTENT);
}

export function useDownloadCardsCsvTemplate(): LazyQueryResultTuple<GetSignedUrlDownloadOutput, Record<string, never>> {
  return useLazyQuery<GetSignedUrlDownloadOutput, Record<string, never>>(
    GET_SIGNED_URL_DOWNLOAD_TEMPLATE_CONTENT_LIBRARY,
    {
      fetchPolicy: 'network-only'
    }
  );
}

export function useBulkImportContentLibraryUrl(): LazyQueryResultTuple<
  GetSignedUrlBulkOutput,
  ApiContentLibraryUploadRequest
> {
  return useLazyQuery<GetSignedUrlBulkOutput, ApiContentLibraryUploadRequest>(GET_SIGNED_URL_BULK_IMPORT, {
    fetchPolicy: 'network-only'
  });
}

export function useImportFromCsvSubscription(skip: boolean): void {
  const { t } = useTranslation();

  useSubscription<{ contentLibraryImport: ApiImportCsvContent }, OperationVariables>(CONTENT_LIBRARY_IMPORT, {
    onData: async ({ data: response }) => {
      if (!response.data) {
        return;
      }
      if (response.data.contentLibraryImport.messageOrder === ContentLibraryBulkImportSub.Order.Last) {
        notification.success({
          description: t('ContentLibrary.contentUploadModals.importSuccessMessage'),
          message: t('ContentLibrary.contentUploadModals.importSuccessTitle'),
          key: 'CSVContentUploadSuccess'
        });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'getContentLibraryContents' });
        cache.gc();
      }
      if (!!response.data.contentLibraryImport.error) {
        notification.warning({
          message: t('ErrorBoundary.title'),
          description: t('ContentLibrary.contentUploadModals.importFailedMessage'),
          key: 'CSVContentUploadError'
        });
      }
    },
    onError: error => {
      console.error('Subscription error:', error);
    },
    skip: skip
  });
}
