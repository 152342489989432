import { useEffect } from 'react';
import { FeatureFlag, useFeatureFlag } from './featureFlag';
import { Config } from '../config/config';
import { useIsMobile } from 'src/reactiveVars';

let isSupportWidgetMounted = false;
export const useSupportWidget = (): void => {
  const isSupportWidgetFeature = useFeatureFlag(FeatureFlag.SupportWidget_Enable);
  const [isMobile] = useIsMobile();

  useEffect(() => {
    if (isMobile) return; // Don't mount widget on mobile

    if (isSupportWidgetFeature) {
      if (!isSupportWidgetMounted) {
        const body = document.getElementsByTagName('body')[0];
        const createWidgetScript = (callback: () => void): void => {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.setAttribute('id', 'hs-script-loader');
          script.defer = true;
          script.async = true;

          script.src = `//js.hs-scripts.com/${Config.SupportWidgetCode}.js`;

          script.onload = () => {
            callback();
          };
          body.appendChild(script);
        };

        createWidgetScript(() => {
          const eventDomContentLoaded = document.createEvent('Event');

          eventDomContentLoaded.initEvent('DOMContentLoaded', true, true);
          window.document.dispatchEvent(eventDomContentLoaded);
          isSupportWidgetMounted = true;
        });
      } else {
        console.warn('Support widget already mounted. Should not do it twice');
      }
    }
  }, [isMobile, isSupportWidgetFeature]);
};

export default useSupportWidget;
