import React, { FC, KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInviteUsers, IInvitedUser } from 'src/models/company/Users/hooks';
import { Form, Input, Select, Alert, UserName, Button } from 'src/common';
import { IUserName, toUserName } from 'src/models/users/types';
import styles from './index.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTimes } from '@fortawesome/pro-light-svg-icons';
import InfoLabel from 'src/shared/InfoLabel';
import { toHSLColor } from 'src/helpers/styles';
import AddLicensesForm from 'src/pages/AdminPage/CompanyMembersList/CreateUsers/AddLicensesForm';
import { useLicenses } from 'src/models/admin/License/hooks';

interface Props {
  onSuccess: () => void;
  onClose: () => void;
}

interface UserFormState extends Pick<IUserName, 'email' | 'name' | 'lastName'> {
  licenseIds?: string[];
}

interface IFormState {
  usersEmail: string[];
  users: UserFormState[];
}

export const InviteUsersForm: FC<Props> = props => {
  const { onSuccess, onClose: onCloseModal } = props;
  const { t } = useTranslation();

  const [addCompanyEmployees, { loading: isLoading }] = useInviteUsers();
  const { data: currentLicenses } = useLicenses();

  const [isDraft, setIsDraft] = useState(false);
  const [createUsersFirstStep, setCreateUsersFirstStep] = useState(true);

  const hasLicenses = currentLicenses && currentLicenses.length > 0;

  const [form] = Form.useForm();
  const initialValues = useMemo((): IFormState => {
    return {
      usersEmail: [],
      users: []
    };
  }, []);

  const onInvite = useCallback(() => {
    onSuccess();
    form.resetFields();
  }, [form, onSuccess]);

  const onError = useCallback(
    (users: IInvitedUser[]) => {
      form.resetFields();
      form.setFields([
        { name: 'usersEmail', value: users.map(user => user.email), errors: [t('Auth.unInvitedUsersError')] },
        {
          name: 'users',
          value: users.map(user => ({ email: user.email, name: user.name, lastName: user.lastName }))
        }
      ]);
    },
    [form, t]
  );

  const onFinish = useCallback(
    (values: IFormState) => {
      const { users } = values;
      const invitedUsers = users
        .filter(user => !!user.email)
        .map(user => ({
          email: user.email,
          name: user.name,
          lastName: user.lastName,
          inviteSubUser: true,
          licenseIds: user.licenseIds
        }));
      !!invitedUsers.length && addCompanyEmployees({ users: invitedUsers, onInvite, onError });
    },
    [addCompanyEmployees, onInvite, onError]
  );

  const onValuesChange = useCallback(
    (changedValues: Partial<IFormState>) => {
      form.setFields([
        {
          name: 'usersEmail',
          errors: []
        }
      ]);
      if (changedValues.usersEmail) {
        const emails = changedValues.usersEmail.map(email => email.trim());
        const processedUsers = emails
          .map(email => {
            if (email.includes('@')) {
              const [userName] = email.trim().split('@');
              if (userName.includes('.')) {
                const [name, lastName] = userName.split('.');
                return {
                  email,
                  name,
                  lastName
                };
              } else {
                return {
                  email,
                  name: userName,
                  lastName: null
                };
              }
            } else {
              form.setFields([
                {
                  name: 'usersEmail',
                  errors: [t('Common.incorrectEmailError')]
                }
              ]);
              return null;
            }
          })
          .filter(email => email !== null);
        form.setFieldsValue({ users: processedUsers });
      } else if (changedValues.users) {
        const usersFromForm: IUserName[] = form.getFieldValue('users');
        const processedUsersEmail = usersFromForm
          .map(user => {
            return user && user.email ? user.email : null;
          })
          .filter(user => user !== null);
        form.setFieldsValue({ usersEmail: processedUsersEmail });
      } else {
        return;
      }
    },
    [form, t]
  );

  const onKeyPress = useCallback((e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }, []);

  const onClose = useCallback(() => {
    form.resetFields();
    onCloseModal();
  }, [form, onCloseModal]);

  const onCheck = useCallback(
    (userIndex: number, licenseId: string, checked: boolean): void => {
      const users = form.getFieldValue('users');
      const user = users[userIndex];

      const updatedLicenses = checked
        ? [...(user.licenseIds || []), licenseId]
        : user.licenseIds?.filter((id: string) => id !== licenseId);

      users[userIndex] = { ...user, licenseIds: updatedLicenses };
      form.setFieldsValue({ users });
    },
    [form]
  );

  return (
    <>
      <Alert message={t('Users.costChange')} type="info" />

      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        className={styles.form}
        layout={'vertical'}
        initialValues={initialValues}
        onKeyPress={onKeyPress}
      >
        {!!createUsersFirstStep && (
          <Form.Item
            name={'usersEmail'}
            label={<InfoLabel title={t('Users.emailAddresses')} desc={t('Users.emailAddressesDesc')} />}
            rules={[{ required: true, message: t('Users.emailAddressesRequiredError') }]}
          >
            <Select mode={'tags'} style={{ width: '100%' }} tokenSeparators={[',', ' ']} open={false} />
          </Form.Item>
        )}
        <Form.List name={'users'}>
          {(fields, { add, remove }) => {
            const isUsers = fields.length > 0;
            if (!!isUsers) {
              setIsDraft(true);
            } else {
              setIsDraft(false);
            }
            return (
              <div className={styles.formContainer}>
                {!!createUsersFirstStep && (
                  <>
                    {isUsers && <h3 className={classNames(styles.title)}>{t('Users.invitedUsers')}</h3>}
                    <div className={styles.fieldsContainer}>
                      {fields.map((field, index) => {
                        const userField: IUserName = form.getFieldValue('users')[index];
                        const withEmail = userField && userField.email;
                        return (
                          <div key={field.key} className={styles.fields}>
                            <div className={styles.fieldsInner}>
                              <Form.Item
                                {...field}
                                fieldKey={[field.key, 'users']}
                                key={`${field.key}_email`}
                                className={classNames(styles.field, styles.emailField)}
                                name={[field.name, 'email']}
                                rules={[
                                  {
                                    required: true,
                                    message: t('Common.inputRequired', { inputName: t('Auth.emailAddress') })
                                  },
                                  { type: 'email', message: t('Common.incorrectEmailError'), validateTrigger: 'onBlur' }
                                ]}
                              >
                                <Input
                                  data-type={'email'}
                                  type={'email'}
                                  placeholder={t('Auth.emailAddress')}
                                  prefix={
                                    withEmail ? (
                                      <UserName
                                        mode={'onlyAvatar'}
                                        user={{
                                          ...userField,
                                          userName: toUserName(userField),
                                          color: toHSLColor(userField.email)
                                        }}
                                      />
                                    ) : (
                                      <FontAwesomeIcon icon={faUser} />
                                    )
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                className={styles.field}
                                key={`${field.key}_name`}
                                fieldKey={[field.key, 'users']}
                                name={[field.name, 'name']}
                                label={t('Account.firstName')}
                                rules={[
                                  {
                                    required: true,
                                    message: t('Common.inputRequired', { inputName: t('Account.firstName') })
                                  }
                                ]}
                              >
                                <Input data-type="name" type="text" placeholder={t('Account.firstName')} />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                className={styles.field}
                                key={`${field.key}_lastName`}
                                name={[field.name, 'lastName']}
                                label={t('Account.lastName')}
                                rules={[
                                  {
                                    required: true,
                                    message: t('Common.inputRequired', { inputName: t('Account.lastName') })
                                  }
                                ]}
                              >
                                <Input data-type="lastName" type="text" placeholder={t('Account.lastName')} />
                              </Form.Item>
                            </div>
                            <button onClick={() => remove(field.name)} className={styles.deleteButton}>
                              <FontAwesomeIcon icon={faTimes} className={styles.deleteIcon} />
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
                {isUsers && !!createUsersFirstStep && (
                  <Form.Item>
                    <Button type={'primary'} onClick={add} className={styles.ctaButton}>
                      {t('Users.addPerson')}
                    </Button>
                  </Form.Item>
                )}
                {hasLicenses && !createUsersFirstStep && !!isDraft && (
                  <AddLicensesForm users={form.getFieldValue('users')} licenses={currentLicenses} onCheck={onCheck} />
                )}
              </div>
            );
          }}
        </Form.List>
        <div className={styles.formFooter}>
          <Button type={'text'} onClick={onClose} key={'Close'}>
            {t('Common.close')}
          </Button>
          {!!createUsersFirstStep && !!isDraft && hasLicenses && (
            <Button
              type={'primary'}
              disabled={isLoading}
              loading={isLoading}
              onClick={() => {
                const validatedFields: (string | number)[][] = [];

                form.getFieldValue('users').forEach((_: unknown, index: number) => {
                  validatedFields.push(['users', index, 'email']);
                  validatedFields.push(['users', index, 'name']);
                  validatedFields.push(['users', index, 'lastName']);
                });

                form.validateFields(validatedFields).then(() => {
                  setCreateUsersFirstStep(false);
                });
              }}
            >
              {t('Common.next')}
            </Button>
          )}
          {(!createUsersFirstStep || !hasLicenses) && !!isDraft && (
            <Button type={'primary'} htmlType={'submit'} disabled={isLoading} loading={isLoading} key={'invite'}>
              {t('Users.invite')}
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default InviteUsersForm;
