import { Language } from '@tendium/prom-types';
import LanguageDetector from 'i18next-browser-languagedetector';
/**
 * In order to support "eng" and "swe" codes we are using not documented feature:
 * overriding "detect" function.
 */
export class MyLanguageDetector extends LanguageDetector {
  public detect(detectionOrder?: string[]): string | string[] | undefined {
    if (import.meta.env.MODE === 'test') {
      return 'cimode'; // This will set i18next to always return the key on calling i18next.t
    } else {
      const detectedLanguage = super.detect(detectionOrder);
      switch (detectedLanguage) {
        case 'eng': {
          return Language.en;
        }
        case 'swe': {
          return Language.sv;
        }
        case 'fin': {
          return Language.fi;
        }
        case 'no': {
          return Language.no;
        }
        case 'dk': {
          return Language.dk;
        }
        default: {
          return detectedLanguage;
        }
      }
    }
  }
}
