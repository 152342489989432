import React, { FC } from 'react';
import { ThemeMode, LogoProps } from 'src/models/ownership';

export const TeliaLogoIcon: FC<LogoProps> = ({ mode = ThemeMode.light, isShort, className }) => {
  if (isShort) {
    return (
      <svg
        version="1.1"
        id="Telia_Lock-up"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 285.52 300"
        style={{ enableBackground: 'new 0 0 285.52 300' } as React.CSSProperties}
        xmlSpace="preserve"
        className={className}
      >
        <g>
          <g>
            <path
              style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#990AE3' }}
              d="M196.36,210.78c38.9-22.56,73.94-60.82,84.99-88.1c3.14-22.58,3.78-25.43,4.16-50.27
			c0.05-3.44-0.16-6.74-0.48-9.9c-2.27,36.48-49.42,82.35-111.8,112.32c-75.25,36.15-131.7,45.88-156.89,18.31
			c8.75,17.28,14.49,25.67,24.33,38.49C69.73,257.38,130.89,248.76,196.36,210.78z"
            />
            <path
              style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#990AE3' }}
              d="M164.46,148.99c70.98-34.24,120.42-82.95,111.8-115.24C262.11,9.3,233.47-0.01,202.76-0.01
			C111.73-0.01,0,59.99,0,136.54c0,9.84,2.17,20.84,6.06,32.37C26.82,196.46,90.3,184.75,164.46,148.99z"
            />
            <path
              style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#990AE3' }}
              d="M158.47,257.48L158.47,257.48c-49.92,20.24-78.49,13.79-94.28,1.17l-0.23-0.18
			c24.44,24.38,52.3,41.53,76.73,41.53c42.78,0,101.35-51.9,129.07-132.38C254.14,196.67,220.62,232.36,158.47,257.48z"
            />
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg
      version="1.1"
      id="Telia_Lock-up"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 760.71 300"
      style={{ enableBackground: 'new 0 0 760.71 300' } as React.CSSProperties}
      xmlSpace="preserve"
      className={className}
    >
      <g>
        <g>
          <path
            style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#990AE3' }}
            d="M455.19,105.91H408.9v130.4h-29.37v-130.4H332.8V80.54h122.39V105.91z"
          />
          <path
            style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#990AE3' }}
            d="M543.31,188.47H461.2c2.89,16.47,14.69,27.37,29.82,27.37c11.35,0,20.47-5.12,26.04-13.57l21.81,13.13
              c-11.13,14.91-29.37,23.81-47.84,23.81c-30.48,0-56.96-24.25-56.96-58.97s26.26-58.74,55.63-58.74
              C528.18,121.49,547.09,152.2,543.31,188.47z M462.09,167.77h54.74c-3.34-14.46-12.68-23.59-27.15-23.59
              C476.11,144.19,465.87,153.53,462.09,167.77z"
          />
          <path
            style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#990AE3' }}
            d="M601.61,236.75c-4.23,0.89-8.68,1.11-12.68,1.11c-19.58,0-28.26-9.35-28.26-28.26V80.54h27.37v125.28
              c0,9.57,5.79,10.9,13.57,10.01V236.75z"
          />
          <path
            style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#990AE3' }}
            d="M615.85,93.45c0-8.68,7.57-15.8,16.24-15.8c8.46,0,16.02,7.12,16.02,15.8c0,8.68-7.57,15.8-16.02,15.8
              C623.42,109.25,615.85,102.13,615.85,93.45z M618.3,236.31V125.05h27.37v111.26H618.3z"
          />
          <path
            style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#990AE3' }}
            d="M760.71,157.54v78.77h-25.59V223.4c-8.68,11.35-22.48,15.58-34.05,15.58c-24.03,0-38.27-15.13-38.27-34.05
              c0-21.58,24.26-39.16,53.85-39.16c5.79,0,12.02,0.45,17.58,2.23v-7.34c0-11.13-6.45-16.91-19.58-16.91
              c-11.79,0-22.25,6.01-28.7,13.8l-19.36-14.24c10.9-12.68,29.6-21.58,48.51-21.58C744.69,121.71,760.71,134.62,760.71,157.54z
               M734.23,193.36v-4.9c-4.45-1.56-9.12-2-14.91-2c-20.03,0-30.26,8.01-30.26,17.8c0,8.01,6.9,13.13,17.36,13.13
              C720.21,217.39,734.23,209.16,734.23,193.36z"
          />
        </g>
        <g>
          <path
            style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#990AE3' }}
            d="M196.36,210.79c38.9-22.56,73.94-60.82,84.99-88.1c3.14-22.58,3.78-25.43,4.16-50.27
              c0.05-3.44-0.16-6.74-0.48-9.9c-2.27,36.48-49.42,82.35-111.8,112.32c-75.25,36.15-131.7,45.88-156.89,18.31
              c8.75,17.28,14.49,25.67,24.33,38.49C69.73,257.38,130.89,248.77,196.36,210.79z"
          />
          <path
            style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#990AE3' }}
            d="M164.46,148.99c70.98-34.24,120.42-82.95,111.8-115.24C262.11,9.31,233.47,0,202.76,0C111.73,0,0,60,0,136.55
              c0,9.84,2.17,20.84,6.06,32.37C26.82,196.47,90.3,184.76,164.46,148.99z"
          />
          <path
            style={{ fill: mode === ThemeMode.dark ? '#FFFFFF' : '#990AE3' }}
            d="M158.47,257.49L158.47,257.49c-49.92,20.24-78.49,13.79-94.28,1.17l-0.23-0.18
              c24.44,24.38,52.3,41.53,76.73,41.53c42.78,0,101.35-51.9,129.07-132.38C254.14,196.68,220.62,232.37,158.47,257.49z"
          />
        </g>
      </g>
    </svg>
  );
};

export default TeliaLogoIcon;
