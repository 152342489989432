import React, { FC, MouseEventHandler, ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, LinkProps } from 'react-router-dom';
import { IIcon } from 'src/types/icon';
import { ConditionalWrapper, Tooltip } from 'src/common';
import { useIsMobile } from 'src/reactiveVars';

interface LocationDescriptorObject {
  pathname?: string;
  search?: string;
  state?: Record<string, string>;
  hash?: string;
  key?: string;
}

interface Props {
  to: string | LocationDescriptorObject;
  icon?: IIcon;
  title: ReactNode | string;
  isActive: boolean;
  isExpanded?: boolean;
  className?: string;
  isDisabled?: boolean;
  tooltip?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  action?: ReactNode;
  relative?: LinkProps['relative'];
}

export const NavSidebarLink: FC<Props> = ({
  to,
  icon,
  title,
  isActive,
  isExpanded,
  onClick,
  className,
  isDisabled,
  tooltip,
  action,
  relative
}) => {
  const [isMobile] = useIsMobile();

  return (
    <ConditionalWrapper condition={!!isDisabled} wrapper={children => <Tooltip title={tooltip}>{children}</Tooltip>}>
      <Link
        className={classNames(styles.menuItem, className && className, {
          [styles.isActive]: isActive && !isDisabled,
          [styles.isExpanded]: isExpanded,
          [styles.isDisabled]: isDisabled,
          [styles.mobile]: isMobile
        })}
        tabIndex={0}
        to={isDisabled ? '' : to}
        onClick={e => {
          isDisabled ? e.preventDefault() : onClick?.(e);
        }}
        relative={relative}
      >
        {icon ? (
          <>
            <Tooltip title={isExpanded ? '' : title} placement={'right'}>
              <FontAwesomeIcon
                icon={isActive && !isDisabled && icon.activeIcon ? icon.activeIcon : icon.defaultIcon}
                className={styles.itemIcon}
                fixedWidth
              />
            </Tooltip>{' '}
            {isExpanded && <span className={styles.itemLabel}>{title}</span>}
            {action}
          </>
        ) : (
          <Tooltip title={title} placement={'right'}>
            <span className={styles.itemLabel}>{title}</span>
            {action}
          </Tooltip>
        )}
      </Link>
    </ConditionalWrapper>
  );
};

export default NavSidebarLink;
