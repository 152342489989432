import { FieldPolicy } from '@apollo/client';
import { mergeUniqueArray, offsetLimitPaginationMerge } from 'src/lib/API/graphql/helpers';
import { IBidsCached } from '../Bids/types';

//[C] Paginated read and Non-paginated read
export function getAllBidsPolicy(): FieldPolicy {
  return {
    keyArgs: [
      '@connection',
      'input',
      [
        'search',
        'stageIds',
        'rejected',
        'wsId',
        'sort',
        'assignedToIds',
        'bidItemTypes',
        'statusCategories',
        'workspaceIds',
        'textSearch',
        'contractStart'
      ]
    ],
    merge(existing: IBidsCached, incoming: IBidsCached, options) {
      const { storeFieldName } = options;
      const merged = storeFieldName.includes('pages')
        ? offsetLimitPaginationMerge(existing?.bids, incoming?.bids, options)
        : mergeUniqueArray(existing?.bids, incoming?.bids, options);

      return {
        ...existing,
        ...incoming,
        bids: merged
      };
    }
  };
}
