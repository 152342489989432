import React, { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { useNavSidebarCtx } from './hooks';
import { NavSidebarContext } from './context';
import { useIsMobile, useMobileSidebarState } from 'src/reactiveVars';
import BackdropOverlay from '../../common/BackdropOverlay';
import useHandleMobileSidebarState from 'src/helpers/mobile/useHandleMobileSidebar';

export type NavSidebarType = 'min' | 'mid' | 'max';
interface Props {
  children: ReactNode;
  type?: NavSidebarType;
}

export const NavSidebar: FC<Props> = ({ children, type }) => {
  const value = useNavSidebarCtx(type);
  const [isMobile] = useIsMobile();
  const [sidebarIsOpen, updateSidebar] = value;
  const [mobileSidebarState] = useMobileSidebarState();
  const { toggleMobileSidebarVisible } = useHandleMobileSidebarState();

  return (
    <>
      {isMobile && <BackdropOverlay isVisible={mobileSidebarState} onClick={() => toggleMobileSidebarVisible(false)} />}
      <NavSidebarContext.Provider value={value}>
        <aside
          className={classNames(styles.aside, {
            [styles.isOpen]: sidebarIsOpen && !isMobile,
            [styles.isClosed]: !sidebarIsOpen && !isMobile,
            [styles.isNarrow]: type === 'min' && !isMobile,
            [styles.isClosedMobile]: isMobile && !mobileSidebarState,
            [styles.isOpenedMobile]: isMobile && mobileSidebarState
          })}
        >
          <div className={styles.asideInner}>
            {!type && !isMobile && (
              <button className={styles.closeButton} onClick={updateSidebar}>
                <FontAwesomeIcon icon={faAngleRight} className={styles.closeButtonIcon} />
              </button>
            )}
            <div className={styles.container}>{children}</div>
          </div>
        </aside>
      </NavSidebarContext.Provider>
    </>
  );
};

export default NavSidebar;
export { useNavSidebar } from './hooks';
