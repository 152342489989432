import { safeCallSegment } from './helpers';

export function groupByCompany(
  companyId: string,
  companyMeta: {
    name: string;
  }
): void {
  safeCallSegment(() => {
    analytics.group(companyId, {
      group_type: 'Organization',
      name: companyMeta.name
    });
  });
}

export function handleSignOut(): void {
  safeCallSegment(() => {
    analytics.reset();
  });
}
