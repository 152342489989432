import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Paths } from 'src/pages/paths';
import styles from './index.module.scss';
import HeaderActions from './HeaderActions';
import VersionUpdateAlert from '../VersionUpdateAlert';
import { ThemeMode, ownership } from 'src/models/ownership';
import { useIsMobile, useMobileSidebarState } from 'src/reactiveVars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX } from '@fortawesome/pro-regular-svg-icons';
import useHandleMobileSidebarState from 'src/helpers/mobile/useHandleMobileSidebar';
import { isPaidUser, useApp } from 'src/models/auth';
import classNames from 'classnames';

export const Header: FC = () => {
  const [isMobile] = useIsMobile();
  const [mobileSidebarState] = useMobileSidebarState();
  const { user } = useApp();
  const { toggleMobileSidebarVisible } = useHandleMobileSidebarState();
  const isUserPaid = useMemo(() => {
    return isPaidUser(user);
  }, [user]);

  return (
    <header className={styles.topbar}>
      <div className={styles.topbarContainer}>
        {isMobile && isUserPaid && (
          <button onClick={() => toggleMobileSidebarVisible()} className={styles.mobileSidebarStateButton}>
            {mobileSidebarState ? (
              <FontAwesomeIcon icon={faX} className={styles.mobileSidebarStateButtonIcon} />
            ) : (
              <FontAwesomeIcon icon={faBars} className={styles.mobileSidebarStateButtonIcon} />
            )}
          </button>
        )}

        <div className={styles.logo}>
          <Link to={Paths.ROOT} className={classNames(styles.logoLink, styles[ownership.brandName])}>
            <ownership.theme.logo mode={ThemeMode.dark} />
          </Link>
        </div>
        <HeaderActions />
      </div>
      <VersionUpdateAlert />
    </header>
  );
};

export default Header;
