import { gql } from '@apollo/client';
import { SSO_CONFIG_FIELDS } from './Security/queries';
import { TEAM_FIELDS } from '../users/Team/queries';
import { FEATURE_ROLE_FIELDS } from '../admin/FeatureRole/queries';

export const GET_MY_COMPANY = gql`
  query getMyCompany {
    getCompany {
      id
      createdBy {
        ...userNameFields
      }
      personel {
        ...userNameFields
      }
      teams {
        ...teamFields
      }
      ssoConfiguration {
        ...ssoConfigFields
      }
      competitors {
        orgId
      }
    }
  }
  ${SSO_CONFIG_FIELDS}
  ${TEAM_FIELDS}
`;

export const INIT_COMPANY_INFO = gql`
  mutation updateMyCompany($orgNumber: String, $companyName: String) {
    updateUser(input: { company: { companyName: $companyName, orgNumber: $orgNumber } }) {
      email
      company {
        id
        companyName
        orgNumber
      }
    }
  }
`;

export const UPDATE_COMPANY_INFO = gql`
  mutation updateCompanyInfo(
    $id: String
    $orgNumber: String
    $turnOver: Float
    $address: String
    $companyName: String
    $type: String
    $country: Country
  ) {
    updateCompany(
      input: {
        id: $id
        orgNumber: $orgNumber
        turnOver: $turnOver
        address: $address
        companyName: $companyName
        type: $type
        country: $country
      }
    ) {
      id
      companyName
      orgNumber
      country
    }
  }
`;

export const COMPANY_FIELDS = gql`
  fragment CompanyFields on Company {
    id
    companyName
    orgNumber
    featureRoles
    enabled
    country
    settings {
      filterKeywordsLimit
      matchingProfilesLimit
      workspaceLimit
    }
    basePlate {
      ...featureRoleFields
    }
    features {
      ...featureRoleFields
    }
    ownership
  }
  ${FEATURE_ROLE_FIELDS}
`;

export const COMPANY_USER_FIELDS = gql`
  fragment CompanyUserFields on User {
    ...userNameFields
    companyRole
    isAdmin
    mfaMode
    invitationReSent
    createdOn
    licenses {
      id
      name
    }
  }
`;
