import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import Avatar from '../Avatar';
import classNames from 'classnames';
import Tooltip from '../Tooltip';
import { IUserName } from 'src/models/users/types';
import { useIsMobile } from 'src/reactiveVars';

export interface UserNameProps {
  size?: (typeof Sizes)[number];
  user: IUserName;
  className?: string;
  mode?: 'full' | 'onlyName' | 'onlyFullName' | 'onlyAvatar';
  showTooltip?: boolean;
  loading?: boolean;
  desc?: string;
}

interface Props extends UserNameProps {
  bordered?: true;
}

export const UserName: FC<Props> = ({ size = 'm', mode = 'onlyFullName', showTooltip = false, ...restProps }) => {
  const { user, className, loading, bordered } = restProps;
  const { userName, name } = user;
  const [isMobile] = useIsMobile();

  const title = useMemo(() => (mode === 'onlyName' ? name : userName), [mode, name, userName]);

  const desc = useMemo(() => {
    return restProps.desc ?? (user.email !== user.userName ? user.email : null) ?? '';
  }, [restProps.desc, user]);

  return mode === 'onlyAvatar' ? (
    <Avatar
      user={user}
      size={size}
      loading={loading}
      showTooltip={showTooltip}
      className={className}
      bordered={bordered}
    />
  ) : (
    <Tooltip title={showTooltip ? userName : undefined}>
      <div className={classNames(styles.user, className)}>
        <Avatar user={user} size={size} loading={loading} bordered={bordered} />
        <div className={styles.userContent}>
          <span
            className={classNames(styles.name, {
              [styles.nameMobile]: isMobile
            })}
            data-value={title}
          >
            {title}
          </span>
          {mode === 'full' && desc && <span className={styles.desc}>{desc}</span>}
        </div>
      </div>
    </Tooltip>
  );
};

export default UserName;
