import { Language, notEmpty, SearchLogic } from '@tendium/prom-types';
import { AITitles, IApiTenderHighlight, ITenderHighlight, ITenderKeywordHighlight, Summaries } from './types';
import { capitalizeFirstLetter } from 'src/helpers/capitalizeFirstLetter';
import { HighlightColor, MpKeywordAndGroup, MpNewKeywordGroup } from 'src/models/matchingProfiles/types';

const MATCH_SIGN_REG = /<em>((.|\s)*?)<\/em>/g; // blank characters can be included inside <em>...</em> in the freya result
const REPLACE_SIGN_REG = /<em>|<\/em>/g;
const SPECIAL_CHAR = '[^\\wåäöÅÄÖÆØæøšŠžŽ]';
const HIGHLIGHT_KEYWORDS_SORT_ORDER = [HighlightColor.Green, HighlightColor.Yellow, HighlightColor.Red];

// Only reason we need this is:
// - special characters will be dropped in the freya result when it is at the beginning or in the end
function parseKeyword(keyword: string): string {
  return keyword.replace(new RegExp('^' + SPECIAL_CHAR + '+'), '').replace(new RegExp(SPECIAL_CHAR + '+$'), '');
}

export function processSingleHighlightText(
  highlightText: string,
  keywordValues: string[],
  searchLogic: SearchLogic
): MdHighlightResult {
  const emSplit = highlightText.split('<em>');

  const allMatchingKeywords = new Set<string>();

  const processedSection = emSplit.reduce((acc, subText) => {
    const splitSubText = subText.split('</em>');
    const emphText = splitSubText.at(0);
    if (!emphText || splitSubText.length === 1) return `${acc}${subText}`.replace('</em>', '');
    const matchingKeywords = getMatchingKeywords(keywordValues, emphText, searchLogic);
    if (matchingKeywords.length) {
      matchingKeywords.forEach(keyword => allMatchingKeywords.add(keyword));
      return `${acc}**${subText}`.replace('</em>', '**');
    } else {
      return `${acc}${subText}`.replace('</em>', '');
    }
  }, '');
  return {
    matchedKeywords: [...allMatchingKeywords],
    processedSection,
    initialSection: highlightText
  };
}

function matchWordsMatchTextWords(matchWords: string[], textWords: string[], searchLogic: SearchLogic): boolean {
  if (matchWords.length !== textWords.length) return false;

  const wordPairs = matchWords.map(function (matchWord, i) {
    return [matchWord, textWords.at(i) ?? ''];
  });
  for (const [matchWord, textWord] of wordPairs) {
    if (
      (searchLogic === SearchLogic.PrefixMatch && !textWord?.toLowerCase().includes(matchWord.toLowerCase())) ||
      (searchLogic === SearchLogic.ExactMatch && textWord.toLowerCase() !== matchWord.toLowerCase())
    ) {
      return false;
    }
  }

  return true;
}

function keywordMatchesEmphText(keyword: string, text: string, searchLogic: SearchLogic): boolean {
  const parsedKeyword = parseKeyword(keyword);
  const matchWords = parsedKeyword.split(/[\s<]+/);
  const textWords = text.split(/[\s<]+/);
  return matchWordsMatchTextWords(matchWords, textWords, searchLogic);
}

function getMatchingKeywords(keywords: string[], text: string, searchLogic: SearchLogic): string[] {
  return keywords.reduce((acc, keyword) => {
    if (keywordMatchesEmphText(keyword, text, searchLogic)) {
      return [...acc, keyword];
    } else {
      return acc;
    }
  }, [] as string[]);
}

function parseHighlightValues(values: string[]): string {
  return (values.join(' ').match(MATCH_SIGN_REG) || [])
    .map(w => w.replace(REPLACE_SIGN_REG, '').replace(/\s+/g, ' '))
    .join(' ');
}

interface KeywordGroupHighlightResults {
  matchedKeywords: string[];
  initialSection: string;
  tenderKeywordHighlight: ITenderKeywordHighlight;
}

interface MdHighlightResult {
  processedSection: string;
  matchedKeywords: string[];
  initialSection: string;
}

function sortKeywords(keywords: MpNewKeywordGroup[]): MpNewKeywordGroup[] {
  return [...keywords].sort((a, b) => {
    for (let i = 0; i < Math.min(a.values.length, b.values.length); ++i) {
      const aKeyword = a.values[i].toLowerCase();
      const bKeyword = b.values[i].toLowerCase();

      if (aKeyword !== bKeyword) {
        return aKeyword > bKeyword ? -1 : 1;
      }
    }

    return a.values.length - b.values.length;
  });
}

function normalizeHighlightWhitespace(highlights: IApiTenderHighlight[]): IApiTenderHighlight[] {
  return highlights.map(highlight => {
    return { ...highlight, values: highlight.values.map(value => value.replace(/\s+/g, ' ')) };
  });
}

export function groupByKeywords(
  keywordsRaw: MpNewKeywordGroup[],
  highlightsRaw: IApiTenderHighlight[]
): ITenderHighlight[] {
  if (!keywordsRaw.length) {
    return [];
  }
  const keywordsHighlights: ITenderHighlight[] = [];
  const sortedKeywords = sortKeywords(keywordsRaw);
  const recognisedHighlights = new Set<string>();
  const groupKeywordsToExcludeFromUnrecognised = new Set<string>();
  const normalizedHighlights = normalizeHighlightWhitespace(highlightsRaw);
  // compute sections
  sortedKeywords.forEach(({ values: keywordValues, highlightColor, searchLogic }) => {
    const highlights: ITenderKeywordHighlight[] = [];
    const matchedKeywords = new Set<string>();

    const keywordGroupHighlightResults = normalizedHighlights.map(
      ({ context, values, targetPath }): KeywordGroupHighlightResults[] | null => {
        if (!context) return null;
        const mdHighlightResults = values.map(value =>
          processSingleHighlightText(value, keywordValues, searchLogic ?? SearchLogic.ExactMatch)
        );
        mdHighlightResults.forEach(result => {
          result.matchedKeywords.forEach(keyword => matchedKeywords.add(keyword));
        });

        return mdHighlightResults.map(result => {
          return {
            matchedKeywords: result.matchedKeywords,
            initialSection: result.initialSection,
            tenderKeywordHighlight: {
              color: highlightColor,
              context,
              targetPath,
              value: result.processedSection
            }
          };
        });
      }
    );

    // push matches if all keywords in group matched
    if (matchedKeywords.size === keywordValues.length) {
      keywordGroupHighlightResults.filter(notEmpty).forEach(results =>
        results.forEach(result => {
          if (result.matchedKeywords.length) {
            highlights.push(result.tenderKeywordHighlight);
            recognisedHighlights.add(result.initialSection);
          }
        })
      );
    } else {
      // otherwise and-keyword may need to be excluded in unrecognised section due to Frey weirdness
      if (keywordValues.length > 1) keywordValues.forEach(value => groupKeywordsToExcludeFromUnrecognised.add(value));
    }

    if (!!highlights.length) {
      keywordsHighlights.push({
        keywords: keywordValues.map((value, idx) => (idx === 0 ? capitalizeFirstLetter(value) : value)),
        highlights: [...new Set(highlights)]
      });
    }
  });

  // compute unrecognised section
  const unrecognisedHighlights = [] as ITenderKeywordHighlight[];

  normalizedHighlights.forEach(({ context, values, targetPath }) => {
    context &&
      values.forEach(value => {
        const { matchedKeywords } = processSingleHighlightText(
          value,
          [...groupKeywordsToExcludeFromUnrecognised],
          SearchLogic.PrefixMatch
        );
        if (!recognisedHighlights.has(value) && !matchedKeywords.length) {
          unrecognisedHighlights.push({
            color: HighlightColor.Yellow,
            context,
            targetPath,
            value: value.replace(REPLACE_SIGN_REG, '**').replace(/\s+/g, ' ')
          });
        }
      });
  });

  if (!!unrecognisedHighlights.length) {
    keywordsHighlights.push({ keywords: [], highlights: [...new Set(unrecognisedHighlights)] });
  }

  return keywordsHighlights || [];
}

export function findMatchedKeywords(
  highlights?: IApiTenderHighlight[],
  keywordsGroup?: MpKeywordAndGroup[]
): MpKeywordAndGroup[] | undefined {
  if (!highlights?.length || !keywordsGroup?.length) return [];

  const flattenHighlights = highlights.flatMap(highlight => parseHighlightValues(highlight.values)) ?? [];

  const matchedKeywords = keywordsGroup.filter(group => {
    return group.values.every(keyword => {
      const parsedKeyword = parseKeyword(keyword.value);
      const wordStrReg = group.searchLogic === SearchLogic.PrefixMatch ? parsedKeyword + '.*' : parsedKeyword;
      try {
        // (?:^|\\s)${wordStrReg}(?:$|\\s) - set a word boundary that is either start/end of word or a whitespace
        // this ensures that we don't capture substrings and we can handle non-ascii chars
        const regexPattern =
          group.searchLogic === SearchLogic.ExactMatch
            ? new RegExp(`(?:^|\\s)${wordStrReg}(?:$|\\s)`, 'i')
            : new RegExp(`^${wordStrReg}`, 'i');
        return flattenHighlights.some(highlightValue => regexPattern.test(highlightValue));
      } catch (e) {
        return false;
      }
    });
  });

  return matchedKeywords.sort((a, b) => {
    const keywordA = a.highlightColor ? HIGHLIGHT_KEYWORDS_SORT_ORDER.indexOf(a.highlightColor) : -1;
    const keywordB = b.highlightColor ? HIGHLIGHT_KEYWORDS_SORT_ORDER.indexOf(b.highlightColor) : -1;

    return keywordA - keywordB;
  });
}

/** Get translated summary from AI summaries based on user language setting
 * if language setting = SE, Show SE if SE otherwise EN
 * if language setting = FI, Show FI if FI otherwise EN
 * if language setting = NO, Show NO if NO otherwise EN
 * if language setting = DK, Show DK if DK otherwise EN
 * Otherwise always show EN
 */
export function getSummaryByLanguageSetting(languageSetting: Language, summaries?: Summaries): string | undefined {
  if (languageSetting === Language.sv && summaries?.sv) {
    return summaries.sv;
  }

  if (languageSetting === Language.fi && summaries?.fi) {
    return summaries.fi;
  }

  if (languageSetting === Language.no && summaries?.no) {
    return summaries.no;
  }

  if (languageSetting === Language.dk && summaries?.dk) {
    return summaries.dk;
  }

  return summaries?.en;
}

/** Get translated title from AI titles based on user language setting
 * if language setting = SE, Show SE if SE otherwise EN
 * if language setting = FI, Show FI if FI otherwise EN
 * if language setting = NO, Show NO if NO otherwise EN
 * if language setting = DK, Show DK if DK otherwise EN
 * Otherwise always show EN
 */
export function getAITitleByLanguageSetting(languageSetting: Language, titles?: AITitles): string | undefined {
  if (languageSetting === Language.sv && titles?.sv) {
    return titles.sv;
  }

  if (languageSetting === Language.fi && titles?.fi) {
    return titles.fi;
  }

  if (languageSetting === Language.no && titles?.no) {
    return titles.no;
  }

  if (languageSetting === Language.dk && titles?.dk) {
    return titles.dk;
  }

  return titles?.en;
}
