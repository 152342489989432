import { faChevronRight, faCloudDownload } from '@fortawesome/pro-light-svg-icons';
import { faFolder, faFolderOpen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { DataNode, EventDataNode } from 'rc-tree/lib/interface';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DirectoryTree, Tooltip } from 'src/common';
import { getIconByFileType } from 'src/helpers/fileTypeIcon';
import { DateFormatTranslation } from 'src/lib/I18nService/types';
import { useDownloadFile, usePreviewFile } from 'src/models/procurements/Tender/hooks';
import { IProcurementFile, IProcurementFilesCategory } from 'src/models/procurements/Tender/types';
import { getFileNameFromPath } from 'src/models/procurements/helpers';
import { DocumentViewer, TextSelectOptions } from 'src/shared';
import styles from './index.module.scss';
import { trackViewDocumentPreview } from 'src/segment/events';
import { useIsMobile } from 'src/reactiveVars';

type ToChildrenFileFunction = (
  file: IProcurementFile,
  fileIndex: number,
  indexConcat: string,
  getFile: (path: string) => void
) => ILeafDataNode;

const useChildrenFile = (): ToChildrenFileFunction => {
  const { t } = useTranslation();
  const [isMobile] = useIsMobile();

  const toChildrenFile: ToChildrenFileFunction = (file, fileIndex, indexConcat, getFile) => {
    const isFile = !file.children || file.children.length === 0;
    const title = file.directoryName || file.fileName;
    const icon = getIconByFileType(file.fileType);

    return {
      title: isFile ? (
        <>
          <span className={styles.titleDownload}>
            <span
              className={classNames(styles.docTitle, {
                [styles.docTitleMobile]: isMobile
              })}
            >
              {title}
            </span>
            {!isMobile && (
              <Button
                type={'text'}
                className={classNames(styles.docAction, styles.fileAction)}
                onClick={e => {
                  e.stopPropagation();
                  file.targetPath && getFile(file.targetPath);
                }}
                icon={<FontAwesomeIcon icon={faCloudDownload} />}
              />
            )}
          </span>
          <span className={styles.docDate}>{t(DateFormatTranslation.full, { date: file.dateUploaded })}</span>
        </>
      ) : (
        <span
          className={classNames(styles.folderTitle, {
            [styles.folderTitleMobile]: isMobile
          })}
        >{`${title} (${file.children?.length})`}</span>
      ),
      isLeaf: isFile,
      path: file.targetPath,
      fileType: file.fileType,
      key: `${indexConcat}-${fileIndex}`,
      switcherIcon: ({ expanded }) =>
        !isFile && (
          <FontAwesomeIcon
            icon={faChevronRight}
            className={classNames(styles.expandIcon, { [styles.isExpanded]: expanded })}
          />
        ),
      icon: ({ expanded }) => (isFile ? <>{icon}</> : <FontAwesomeIcon icon={expanded ? faFolderOpen : faFolder} />),
      children: file.children?.map((fileNode, index) =>
        toChildrenFile(fileNode, index, `${indexConcat}-${fileIndex}`, getFile)
      )
    } as ILeafDataNode;
  };

  return useCallback(toChildrenFile, [t, toChildrenFile]);
};

interface ILeafDataNode extends DataNode {
  path: string;
  fileType: string;
}

interface ICategoryEventDataNode extends EventDataNode {
  path: string;
  fileType: string;
}

interface Props {
  procurementFiles: IProcurementFilesCategory;
  procurementId: string;
  eventSource: string;
  onSelectTextInDoc?: (data: TextSelectOptions, filePath: string) => JSX.Element;
}

export const FilesContent: FC<Props> = props => {
  const { procurementFiles, procurementId, eventSource, onSelectTextInDoc } = props;
  const { fileCategories } = procurementFiles;
  const { t } = useTranslation();
  const [isMobile] = useIsMobile();
  const { previewFile, closePreview, url } = usePreviewFile(procurementId);
  const { getFile, loading } = useDownloadFile(procurementId);
  const toChildrenFile = useChildrenFile();

  const [filePath, setFilePath] = useState<string | undefined>(undefined);
  const onShowDocument = useCallback(
    (val: string) => {
      setFilePath(val);
      trackViewDocumentPreview(eventSource, [procurementId]);
    },
    [eventSource, procurementId]
  );
  const onCloseDocument = useCallback(() => {
    closePreview();
    setFilePath(undefined);
  }, [closePreview]);

  const fileName = useMemo(() => {
    if (!filePath) {
      return '';
    }
    return getFileNameFromPath(filePath);
  }, [filePath]);

  const onClick = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>, element: EventDataNode | ICategoryEventDataNode) => {
      // leaf is always typeof ICategoryEventDataNode
      if (element.isLeaf && 'path' in element && procurementId && !loading) {
        const targetPath = element.path.toString();
        const isPreviewable = previewFile(targetPath);
        isPreviewable && onShowDocument(targetPath);
      }
    },
    [loading, onShowDocument, previewFile, procurementId]
  );

  const treeData: DataNode[] = useMemo(
    () =>
      fileCategories.map((fileCategory, index) => {
        return {
          key: `${index}`,
          title: (
            <Tooltip title={t(`Tenders.FileCategory.${fileCategory.description}`)}>
              <span
                className={classNames(styles.folderTitle, {
                  [styles.folderTitleMobile]: isMobile
                })}
              >{`${t(`Tenders.FileCategory.${fileCategory.name}`)} (${fileCategory.children.length})`}</span>
            </Tooltip>
          ),
          icon: ({ expanded }) => <FontAwesomeIcon icon={expanded ? faFolderOpen : faFolder} />,
          switcherIcon: ({ expanded }) => (
            <FontAwesomeIcon
              icon={faChevronRight}
              className={classNames(styles.expandIcon, { [styles.isExpanded]: expanded })}
            />
          ),
          children: fileCategory.children.map((file, fileIndex) =>
            toChildrenFile(file, fileIndex, `${index}-${fileIndex}`, getFile)
          )
        };
      }),
    [fileCategories, getFile, isMobile, t, toChildrenFile]
  );

  const onSelect = useCallback(
    (data: TextSelectOptions) => {
      return (!!filePath && onSelectTextInDoc?.(data, getFileNameFromPath(filePath))) || null;
    },
    [filePath, onSelectTextInDoc]
  );

  return (
    <>
      {!!treeData.length && (
        <div className={styles.container}>
          <DirectoryTree
            defaultExpandedKeys={Object.keys(treeData)}
            className={classNames(styles.directoryTree, loading ? styles.loading : null, {
              [styles.directoryTreeMobile]: isMobile
            })}
            selectable={false}
            onClick={onClick}
            treeData={treeData}
          />

          {!!url && (
            <DocumentViewer
              loading={loading}
              url={url}
              title={fileName}
              onClose={onCloseDocument}
              onSelect={onSelect}
            />
          )}
        </div>
      )}
    </>
  );
};

export default FilesContent;
